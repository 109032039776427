<template>
    <div @click="close()" class="modal-wrapper colored-modal-wrapper user-identity" style="top: 0;">
        <div @click.stop class="modal" data-aos="zoom-in"> 
            <div class="order-animation">
                <Vue3Lottie :animationData="doneAnimation"/>
            </div>

            <div class="modal-title modal-title-sm">
                <p>{{successSignatureTitle }}</p>
            </div>
 
            <div class="modal-msg">
               <p>{{successSignatureText}}</p>
            </div>

            
            <button @click="close()" class="modal-btn modal-btn-sm">
                <p>Nochmal versuchen</p>
            </button>  
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import doneAnimation from '../../assets/animations/done-animation.json'
export default { 
    data(){
        return{
            doneAnimation
        }
    },
    computed: {
        ...mapGetters('auth', ['currentUser']),
        ...mapGetters('application', ['successSignatureModal', 'successSignatureTitle', 'successSignatureText']),
    },
    methods:{
        close(){
            this.$store.commit("application/setSuccessSignatureModal", false); 
        }
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/modals.scss";

.user-identity{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
.order-animation{
    width: 236px;
    height: 179px;
    margin: 0 auto; 
} 

 /* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1470 */
@media (min-width: 2304px) {
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {  
    .order-animation {
        width: 250px;
        height: 200px;
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .order-animation {
        width: 270px;
        height: 220px;
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .order-animation {
        width: 300px;
        height: 250px;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {

}

/*1688-1080*/
@media(max-width: 1688px) {
    .order-animation{
        width: 230px;
        height: 170px; 
    } 

    .modal-btn-sm {
        width: 240px;
    }
}

/*1680-1080*/
@media(max-width: 1680px) {
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .order-animation{
        width: 220px;
        height: 160px; 
    } 
    .modal-btn-sm {
        width: 200px;
    }
}

/*1536-864*/
@media(max-width:1536px) {
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .order-animation{
        width: 210px;
        height: 150px; 
    } 
}

/*1366-638*/
@media (max-width:1366px) {
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
}

/* 1200-1920 */
@media (max-width:1200px) {
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
    .order-animation{
        width: 200px;
        height: 140px; 
    } 
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
    .order-animation{
        width: 177px;
        height: 134px; 
    } 
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) { 
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
    .modal { 
        padding: 30px 20px;
    }

    .modal-btn-sm {
        width: 180px;
    }

    .modal-msg{
        margin-bottom: 30px;
    }
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
    .modal-btn-sm {
        width: 170px;
    }
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .modal { 
        padding: 30px 15px;
    }

    .order-animation{
        width: 160px;
        height: 130px; 
    } 
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {
 
    .order-animation{
        width: 150px;
        height: 120px; 
    } 
}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
    .order-animation{
        width: 130px;
        height: 100px; 
    } 
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>