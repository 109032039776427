<template>
<div data-aos="fade-left" class="modal-wrapper">
    <div class="modal modal-sm">
        <div class="modal-title modal-title-lg">
            <p>Erstelle ein neues Konto</p>
        </div>

        <div class="modal-msg modal-msg-blue">
            <p>Gib deine Daten ein, um dein Profil zu vervollständigen.</p>
        </div>

        <div class="input-wrapper">
            <input type="text" placeholder="Adresse" v-model="registerAdress" :class="{'input-err': registerAdressErr}">
            <div class="err-icon" v-if="registerAdressErr">
                <img src="@/assets/icons/incorrect_icon.svg" />
            </div>
            <div class="err-msg" v-if="registerAdressErr">
                <p v-for="error in v$.registerAdress.$errors" :key="error.$uid">
                    {{error.$message}}
                </p>
            </div>
        </div>

        <div class="inputs-row">
            <div class="input-wrapper m-input">
                <input type="text" placeholder="PLZ" v-model="registerPlz" :class="{'input-err': registerPlzErr}">
                <div class="err-icon" v-if="registerPlzErr">
                    <img src="@/assets/icons/incorrect_icon.svg" />
                </div>
                <div class="err-msg" v-if="registerPlzErr">
                    <p v-for="error in v$.registerPlz.$errors" :key="error.$uid">
                        {{error.$message}}
                    </p>
                </div>
            </div>

            <div class="input-wrapper m-input">
                <input type="text" placeholder="Ort" v-model="registerOrt" :class="{'input-err': registerOrtErr}">
                <div class="err-icon" v-if="registerOrtErr">
                    <img src="@/assets/icons/incorrect_icon.svg" />
                </div>
                <div class="err-msg" v-if="registerOrtErr">
                    <p v-for="error in v$.registerOrt.$errors" :key="error.$uid">
                        {{error.$message}}
                    </p>
                </div>
            </div>
        </div>

        <div class="input-wrapper">
            <!-- <input type="email" placeholder="Land" v-model="registerLand" :class="{'input-err': registerLandErr}"> -->
            <select placeholder="Land" v-model="registerLand" :class="{'input-err': registerLandErr}" @change="presetPrefix(registerLand)">
                <option value="Land" disabled>
                    Land
                </option>
                <option value="Schweiz">
                    Schweiz
                </option>
                <option value="Deutschland">
                    Deutschland
                </option>
                <option value="Österreich">
                    Österreich
                </option>
            </select>
            <div class="err-icon" v-if="registerLandErr">
                <img src="@/assets/icons/incorrect_icon.svg" />
            </div>
            <div class="err-msg" v-if="registerLandErr">
                <p v-for="error in v$.registerLand.$errors" :key="error.$uid">
                    {{error.$message}}
                </p>
            </div>
        </div>

        <div class="inputs-row">
            <div class="input-wrapper sm-input">
                <input type="text" placeholder="+41" v-model="registerPrefix">
            </div>

            <div class="input-wrapper xl-input">
                <input type="email" placeholder="Telefonnummer" v-model="registerPhone" :class="{'input-err': registerPhoneErr}">
                <div class="err-icon" v-if="registerPhoneErr">
                    <img src="@/assets/icons/incorrect_icon.svg" />
                </div>
                <div class="err-msg" v-if="registerPhoneErr">
                    <p v-for="error in v$.registerPhone.$errors" :key="error.$uid">
                        {{error.$message}}
                    </p>
                </div>
            </div>
        </div>

        <button v-if="loadingBtn" class="modal-btn loading-btn">
            <div class="loader"></div>
        </button>
        <button v-else class="modal-btn" @click="fullyRegisterUser()">
            <p>Weiter</p>
        </button>
    </div>
</div>
</template>

<script>
import useValidate from '@vuelidate/core';
//import Swal from 'sweetalert2';
import {
    required,
    helpers,
} from "@vuelidate/validators";
export default {
    mounted() {
        if (!this.idVar || !this.hashVar || !this.expireVar || !this.signatureVar) {
            this.$router.push("/register")
        }
    },
    watch: {
        "registerAdress"(value) {
            if (value) {
                this.registerAdressErr = false;
            } else {
                this.registerAdressErr = true;
            }
        },
        "registerOrt"(value) {
            if (value) {
                this.registerOrtErr = false;
            } else {
                this.registerOrtErr = true;
            }
        },
        "registerPlz"(value) {
            if (value) {
                this.registerPlzErr = false;
            } else {
                this.registerPlzErr = true;
            }
        },
        "registerLand"(value) {
            if (value) {
                this.registerLandErr = false;
            } else {
                this.registerLandErr = true;
            }
        },
        "registerPhone"(value) {
            if (value) {
                this.registerPhoneErr = false;
            } else {
                this.registerPhoneErr = true;
            }
        },
    },
    data() {
        return {
            v$: useValidate(),

            loadingBtn: false,

            registerAdress: '',
            registerAdressErr: false,

            registerOrt: '',
            registerOrtErr: false,

            registerPlz: '',
            registerPlzErr: false,

            registerLand: 'Land',
            registerLandErr: false,

            registerPrefix: '+41',

            registerPhone: '',
            registerPhoneErr: false,
        }
    },
    validations() {
        return {
            registerAdress: {
                required: helpers.withMessage('Addresse ist erforderlich', required),
            },

            registerOrt: {
                required: helpers.withMessage('Ort ist erforderlich', required),
            },

            registerPlz: {
                required: helpers.withMessage('PLZ ist erforderlich', required),
            },

            registerLand: {
                required: helpers.withMessage('Land ist erforderlich', required),
            },

            registerPhone: {
                required: helpers.withMessage('Telefonnummer ist erforderlich', required),
            },
        }
    },
    methods: {
        presetPrefix(land){ 
            if(land == 'Schweiz'){
                this.registerPrefix = '+41';
            }else{
                this.registerPrefix = '+49';
            }
        },

        async fullyRegisterUser() {
            this.loadingBtn = true;

            this.v$.registerAdress.$touch();
            this.v$.registerOrt.$touch();
            this.v$.registerPlz.$touch();
            this.v$.registerLand.$touch();
            this.v$.registerPhone.$touch();

            this.registerAdressErr = this.v$.registerAdress.$invalid;
            this.registerOrtErr = this.v$.registerOrt.$invalid;
            this.registerPlzErr = this.v$.registerPlz.$invalid;
            this.registerLandErr = this.v$.registerLand.$invalid;
            this.registerPhoneErr = this.v$.registerPhone.$invalid;

            if (
                !this.registerAdressErr && !this.registerOrtErr &&
                !this.registerPlzErr && !this.registerLandErr &&
                !this.registerPhoneErr
            ) {
                try {
                    const userData = {
                        address: this.registerAdress,
                        zip: this.registerPlz,
                        city: this.registerOrt,
                        countryCode: this.registerLand,
                        mobile: this.registerPhone,
                        password: this.registerPsw,
                        password_confirmation: this.confirmRegisterPsw,
                    }

                    const id = this.idVar;
                    const hash = this.hashVar;
                    const expire = this.expireVar;
                    const signature = this.signatureVar;

                    await this.registerStep2({
                        newUser: userData,
                        id: id,
                        hash: hash,
                        expires: expire,
                        signature: signature
                    });

                    this.loadingBtn = false;

                    this.$store.commit("auth/setRegisterPsw", null);
                    this.$store.commit("auth/setConfirmRegisterPsw", null);
                    this.$store.commit("auth/setExpireVar", null);
                    this.$store.commit("auth/setSignatureVar", null);
                    this.$store.commit("auth/setIdVar", null);
                    this.$store.commit("auth/setHashVar", null);
                } catch (error) { 
                    // Swal.fire("Bei der Registrierung ist ein Fehler aufgetreten!", "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.", "warning").then(() => {
                    //     this.loadingBtn = false;
                    // });

                    this.$store.commit("application/setErrorProcessModal", true); 
                    this.$store.commit("application/setErrorProcessTitle", 'Bei der Registrierung ist ein Fehler aufgetreten!'); 
                    this.$store.commit("application/setErrorProcessText", this.authError || "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."); 
                    this.loadingBtn = false;
                }
            }

            this.loadingBtn = false;

        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/modals.scss";

.inputs-row {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .input-wrapper {
        margin-bottom: 0;
    }

    .m-input {
        width: 48%;
    }

    .sm-input {
        width: 20%;
    }

    .xl-input {
        width: 76%;
    }

}

.modal-btn {
    margin-top: 55px;
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {}

/* 2304-1470 */
@media (min-width: 2304px) {}

/* 2470 - 1328 */
@media (min-width: 2470px) {}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {}

/*2573-1206*/
@media(min-width: 2573px) {}

/* 2880-1670 */
@media(min-width: 2880px) {}

/* 3000-1870 */
@media(min-width: 3000px) {}

/*3200-1800*/
@media(min-width: 3200px) {}

/*3360-1890 1695*/
@media(min-width:3360px) {}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px) {}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {}

/*1688-1080*/
@media(max-width: 1688px) {}

/*1680-1080*/
@media(max-width: 1680px) {}

/*1640-1080*/
@media(max-width: 1640px) {}

/* 1600 - 757 */
@media (max-width: 1600px) {}

/*1536-864*/
@media(max-width:1536px) {}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {}

/*1366-638*/
@media (max-width:1366px) {}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {}

/* 1200-1920 */
@media (max-width:1200px) {}

/*1134x712*/
@media(max-width:1134px) {}

/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {}

/* 962x601 */
@media(max-width: 962px) {}

/* 900x1600 */
@media(max-width: 900px) {}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {}

/*712-1138*/
@media(max-width:712px) {}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {}

/* 600x962 */
@media(max-width:600px) {}

/* 577-951 */
@media(max-width:577px) {}

/* 540-960 */
@media(max-width:540px) {}

/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
    .inputs-row {
        .sm-input {
            width: 30%;
        }

        .xl-input {
            width: 66%;
        }
    }
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px) {}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px) {}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) {}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {}

/* ip SE */
/*320x568*/
@media(max-width:320px) {}
</style>
