//import axiosInstance from "@/axios"; 

export const state = {
  
    addSignatureModal: false,

    addDocumentsModal: false,

    documentsVerifyingModal: false,

    failedVerifyModal: false,

    getStartedModal: false,

    searchDataModal: false,

    lookingForModal: false,

    congratsModal: false,




    errorProcessModal: false,
    errorProcessTitle: 'Error Process',
    errorProcessText: 'Deine Verifizierung war nicht erfolgreich. Bitte stelle sicher, dass dein Personalausweis deutlich sichtbar ist!',

    successSignatureModal: false,
    successSignatureTitle: 'Signature success',
    successSignatureText: 'Deine Verifizierung war nicht erfolgreich. Bitte stelle sicher, dass dein Personalausweis deutlich sichtbar ist!',
}

export const getters = {
 
    addSignatureModal: state => state.addSignatureModal, 

    addDocumentsModal: state => state.addDocumentsModal, 

    documentsVerifyingModal: state => state.documentsVerifyingModal, 

    failedVerifyModal: state => state.failedVerifyModal, 

    getStartedModal: state => state.getStartedModal, 

    
    lookingForModal: state => state.lookingForModal, 
    congratsModal: state => state.congratsModal, 
    searchDataModal: state => state.searchDataModal, 

    ///

    errorProcessModal: state => state.errorProcessModal, 
    errorProcessTitle: state => state.errorProcessTitle,
    errorProcessText: state => state.errorProcessText,
    
    ///

    successSignatureModal: state => state.successSignatureModal, 
    successSignatureTitle: state => state.successSignatureTitle,
    successSignatureText: state => state.successSignatureText,
}

export const mutations = {
    setErrorProcessModal(state, errorProcessModal){
        state.errorProcessModal = errorProcessModal
    },

    setErrorProcessTitle(state, errorProcessTitle){
        state.errorProcessTitle = errorProcessTitle
    },

    setErrorProcessText(state, errorProcessText){
        state.errorProcessText = errorProcessText
    },

    ///

    setSuccessSignatureModal(state, successSignatureModal){
        state.successSignatureModal = successSignatureModal
    },

    setSuccessSignatureTitle(state, successSignatureTitle){
        state.successSignatureTitle = successSignatureTitle
    },

    setSuccessSignatureText(state, successSignatureText){
        state.successSignatureText = successSignatureText
    },

    ///
 
    setAddSignatureModal(state, addSignatureModal){
        state.addSignatureModal = addSignatureModal
    },

    setAddDocumentsModal(state, addDocumentsModal){
        state.addDocumentsModal = addDocumentsModal
    },
     
    SET_DOCUMENTSVERIFY_MODAL(state, documentsVerifyingModal){
        state.documentsVerifyingModal = documentsVerifyingModal
    },

    SET_FAILEDVERIFY_MODAL(state, failedVerifyModal){
        state.failedVerifyModal = failedVerifyModal
    },

    SET_GETSTARTED_MODAL(state, getStartedModal){
        state.getStartedModal = getStartedModal
    },

    SET_SEARCHDATA_MODAL(state, searchDataModal){
        state.searchDataModal = searchDataModal
    },

    SET_CONGRATSMODAL_MODAL(state, congratsModal){
        state.congratsModal = congratsModal
    }, 

    SET_LOOKINGFOR_MODAL(state, lookingForModal){
        state.lookingForModal = lookingForModal
    },
}


 
export const actions = {
  
}
 


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  };
