<template>
<div  data-aos="fade-left" >
    <nav>
        <router-link to="/" class="nav-logo">
            <img src="../assets/cleandata_logo.svg" />
        </router-link>

        <div class="nav-links" v-if="!mobile">
            <a href="#services">Lösungen</a>
            <a href="#resume">Mein Bereich</a>
            <a href="#pricing">Preise</a>
            <a href="#contact">Kontakt</a> 
        </div>

        <div class="nav-btns" v-if="!mobile">  
            <router-link v-if="!currentUser" to="/login" class="default-btn default-login-btn">EINLOGGEN</router-link>
            <router-link v-if="!currentUser" to="/login" class="default-btn default-started-btn">JETZT STARTEN</router-link>
            <router-link v-if="currentUser" to="/dashboard" class="default-btn default-started-btn">DASHBOARD</router-link>
        </div>

        <div v-if="mobile" @click="toggleMobileMenu()">
            <div class="menu-btn">
                <img src="../assets/icons/menu_button.svg"/>
            </div> 
        </div> 
    </nav>

    <div v-if="mobile" class="mobile-menu" :class="{ 'mobile-menu-opened': slideMobMenu }">
        <div @click="toggleMobileMenu()" class="menu-btn close-menu-btn">
            <img src="../assets/icons/closemenu_icon.svg"/>
        </div>

        <div class="dsb-menu" >
            <div @click="toggleMobileMenu()" class="menu-link-wrapper"> 
                <a href="#services">Lösungen</a>
            </div>

            <div @click="toggleMobileMenu()" class="menu-link-wrapper"> 
                <a href="#resume">Mein Bereich</a>
            </div>

            <div @click="toggleMobileMenu()" class="menu-link-wrapper">   
                <a href="#pricing">Preise</a>
            </div>

            <div @click="toggleMobileMenu()" class="menu-link-wrapper"> 
                <a href="#contact">Kontakt</a>
            </div>

            <div class="menu-link-wrapper login-menu-link-wrapper"> 
                <router-link to="/login">EINLOGGEN</router-link>
            </div> 
        </div>
    </div>

    <div class="get-started-section">
        <div class="column big-column">
            <div class="big-text">
                <p>Deine Daten sind überall – <br> willst du wissen wo?</p>
            </div>

            <div class="small-text">
                <p>
                    CleanData zeigt dir, welche Unternehmen und Datenmakler deine<br> 
                    persönlichen Daten  gespeichert haben – DSGVO-konform und<br> 
                    übersichtlich. Du entscheidest selbst, was  gelöscht wird – und<br> 
                    behältst die volle Kontrolle über deine Privatsphäre.
                </p> 
            </div>

            <!-- <router-link to="/login" class="default-btn default-started-btn">JETZT STARTEN</router-link> -->
            <a @click="getStarted()" class="default-btn default-started-btn">JETZT STARTEN</a>
        </div>
        <div class="column">
            <div class="column-img">
                <img src="../assets/home-imgs/screenshots/home.png" />
            </div>
        </div>
    </div>

    <div class="partners-section"> 
        <swiper 
            :slides-per-view="5" 
            :space-between="150" 
            :loop="true" 
            :modules="modules"  
            :speed="1000" 
            :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
            } "
            :breakpoints=" { 
                982: {
                    slidesPerView: 5 ,
                    spaceBetween: 150 , 
                },
                801: {
                    slidesPerView: 4,
                    spaceBetween: 100 , 
                },
                601: {
                    slidesPerView: 3,
                    spaceBetween: 100, 
                },
                0: {
                    slidesPerView: 2.5,
                    spaceBetween: 50, 
                },  
            }"
       >
            <swiper-slide class="partner"><img src="../assets/home-imgs/partners/adits-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/home-imgs/partners/google-cloud-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/home-imgs/partners/google-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/home-imgs/partners/klabs-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/home-imgs/partners/microsoft-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/home-imgs/partners/monday-logo.svg" /></swiper-slide>
            <swiper-slide class="partner"><img src="../assets/home-imgs/partners/swa-logo.svg" /></swiper-slide>
        </swiper>
     
    </div>

    <div class="showcase-section" id="services">
        <div class="bg bg-1">
            <img src="../assets/home-imgs/components/backgorund_1.png" />
        </div>
        <div class="big-text">
            <p>Es sind Deine Daten. Besitze Sie.</p>
        </div>

        <div class="small-text">
            <p>Unsere Technologie bietet Dir Transparenz und Kontrolle über die Daten,<br> die Dir gehören.</p>
        </div> 

        <div class="rounded-white">
            <div class="row-images">
                <div class="row-images-group">
                    <div class="image1 image"> 
                        <img src="../assets/home-imgs/screenshots/companies.png" />
                    </div>

                    <div class="small1 small">
                        <img src="../assets/home-imgs/screenshots/companies-small.png" />
                    </div>
                </div>
 
                <div class="row-images-group" v-if="!mobileImg">
                    <div class="image2 image"> 
                        <img src="../assets/home-imgs/screenshots/details.png" />
                    </div>

                    <div class="small2 small">
                        <img src="../assets/home-imgs/screenshots/details-small.png" />
                    </div>
                </div>
            </div>

            <div class="info-rows">
                <div class="info-row">
                    <div class="info">
                        <div class="number">
                            <img src="../assets/home-imgs/components/one.png" />
                        </div>
                        <p class="text">
                            Entdecke, wo Deine<br> persönlichen Daten online sind<br> – in weniger als 30 Sekunden.
                        </p>
                        <!-- <router-link to="/login" class="default-btn default-started-btn">JETZT STARTEN</router-link> -->
                        <a @click="getStarted()" class="default-btn default-started-btn">JETZT STARTEN</a>
                    </div>

                    <div class="info">
                        <div class="number number-two">
                            <img src="../assets/home-imgs/components/two.png" />
                        </div>
                        <p class="text" style="text-align: right;">
                            Erkenne Dein digitales Risiko<br> und schütze Deine Daten vor<br> Missbrauch.
                        </p>
                        <!-- <router-link to="/login" class="default-btn default-started-btn">JETZT STARTEN</router-link> -->
                        <a @click="getStarted()" class="default-btn default-started-btn">JETZT STARTEN</a>
                    </div>
                </div>

                <div class="info-row info-row3">
                    <div class="info">
                        <div class="number">
                            <img src="../assets/home-imgs/components/three.png" />
                        </div>
                        <p class="text">
                            Sei proaktiv und ergreife<br> Massnahmen, um unnötige<br> Daten zu entfernen.
                        </p>
                        <!-- <router-link to="/login" class="default-btn default-started-btn">JETZT STARTEN</router-link> -->
                        <a @click="getStarted()" class="default-btn default-started-btn">JETZT STARTEN</a>
                    </div>

                    <div class="info info-image">

                        <div class="image image3">

                            <img src="../assets/home-imgs/screenshots/notifications.png" />
                        </div>

                        <div class="small small3">
                            <img src="../assets/home-imgs/screenshots/notifications-small.png" />
                        </div>

                    </div>
                </div>

                <div class="info-row">
                    <div class="info info-image">

                        <div class="image">

                            <img src="../assets/home-imgs/screenshots/dashboard.png" />
                        </div>

                        <div class="small">
                            <img src="../assets/home-imgs/screenshots/dashboard-small.png" />
                        </div>

                    </div>

                    <div class="info">
                        <div class="number">
                            <img src="../assets/home-imgs/components/four.png" />
                        </div>
                        <p class="text">
                            Wenn Dir Deine Freunde<br> und Familie am Herzen<br> liegen, teile die<br> Botschaft.
                        </p>
                        <!-- <router-link to="/login" class="default-btn default-started-btn">JETZT STARTEN</router-link> -->
                        <a @click="getStarted()" class="default-btn default-started-btn">JETZT STARTEN</a>
                    </div>
                </div>
            </div>

            <div class="shadow-img shadow-img1">
                <img src="../assets/home-imgs/components/shadow_1.png"/>
            </div>

            <div class="shadow-img shadow-img2">
                <img src="../assets/home-imgs/components/shadow_2.png"/>
            </div>
        </div>
    </div>

    <div class="numbers-stats" id="resume">
        <p class="title">Unsere Zahlen sprechen für sich</p>
        <div class="numbers-content">
            <div class="number-container">
                <p class="number">100M+</p>
                <p class="subtitle">Erfasste Datensätze analysiert</p>
                <p class="text">Millionen von Datensätzen wurden bereits gescannt, um Nutzer über ihre Online-Spuren aufzuklären.</p>
            </div>
            <div class="number-container">
                <p class="number">54+</p>
                <p class="subtitle">Unterstützte Länder</p>
                <p class="text">Unser Service hilft Nutzern weltweit, ihre persönlichen Daten zu schützen.</p>
            </div>
            <div class="number-container">
                <p class="number">2,389+</p>
                <p class="subtitle">Erfolgreiche Datenlöschungen</p>
                <p class="text">Tausende Unternehmen wurden bereits aufgefordert, personenbezogene Daten zu entfernen.</p>
            </div>
        </div>
    </div>

    <div class="pay-plans" id="pricing">
        <div class="bg bg-2">
            <img src="../assets/home-imgs/components/background_2.png" />
        </div>

        <p class="title">Flexible Pläne, massgeschneidert für<br> Deine Privatsphäre</p>
        <p class="subtitle">Effektive Datenschutzlösungen, die Dir helfen, die Kontrolle über Deine<br> Daten zurückzugewinnen.</p>

        <div class="plans-toggle-wrapper">
            <div class="plan-mode" @click="payMode = true" :class="{'plan-mode-active' : payMode}">
                <p>MONATLICH</p>
            </div>

            <div class="plan-mode" @click="payMode = false" :class="{'plan-mode-active' : !payMode}">
                <p>JÄHRLICH</p>
            </div>
        </div>

        <swiper 
            v-if="payMode"
            data-aos="fade-left"
            :slides-per-view="3" 
            :space-between="50" 
            :modules="modules" 
            :pagination="true" 
            :loop="false" 
            class="plans-wrapper"
            :breakpoints=" {  
                982: {
                    slidesPerView: 3 ,
                    spaceBetween: 50 
                },
                801: {
                    slidesPerView: 3,
                    spaceBetween: 15 
                },
                601: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                    loop: true 
                }, 
                541: {
                    slidesPerView: 1.5,
                    spaceBetween: 50, 
                    loop: true 
                },
                0: {
                    slidesPerView: 1,
                    spaceBetween: 100, 
                    loop: true 
                },
            }"    
        >
            <swiper-slide>
                <div class="plan">
                    <div class="title">
                        <p>Kostenlos <br> Basic</p>
                    </div>

                    <div class="plan-attributes plan-attributes-subtitle">
                        <div class="offer-indicator-wrapper">
                            <p class="attribute">Finde heraus, welche Unternehmen Deine Daten gespeichert haben – komplett kostenlos!</p>
                        </div> 
                    </div>

                    <div class="plan-attributes">
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>    
                            <p class="attribute">Einmalige E-Mail-Prüfung pro Monat</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Anzeige der Unternehmen mit Deinen Daten</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/doesnt-offer.png"/></div>  
                            <p class="attribute">Keine automatische Löschung möglich</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Basis-Support</p>
                        </div>
                    </div>

                    <a class="default-btn plan-btn">ABONNIEREN</a>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="plan plan-pro">
                    <div class="title">
                        <p>12,90 CHF <span class="monthly-indicator">/Monat</span> <br> Beliebteste Option</p>  
                    </div> 

                    <div class="plan-attributes plan-attributes-subtitle">
                        <div class="offer-indicator-wrapper">
                            <p class="attribute">Wöchentliche Scans & bis zu 5 Löschanfragen pro Monat.</p>
                        </div> 
                    </div>

                    <div class="plan-attributes">
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Wöchentliche E-Mail-Prüfung</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Anzeige der Unternehmen mit Deinen Daten</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Automatische Löschung bei bis zu 5 Unternehmen pro Monat</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Benachrichtigungen bei verdächtigen Aktivitäten</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Priorisierter Support</p>
                        </div>
                        
                    </div>

                    <a class="default-btn plan-btn">ABONNIEREN</a> 

                    <p class="price-tax-info">
                        Die Kosten betragen CHF 12,90, CHF 9,90 im ersten Jahr. Danach CHF 9,90 jährlich (MwSt./Umsatzsteuer kann anfallen).
                    </p>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="plan ">
                    <div class="title">
                        <p>23,90 CHF <span class="monthly-indicator">/Monat</span> <br> Pro</p>
                    </div> 

                    <div class="plan-attributes plan-attributes-subtitle">
                        <div class="offer-indicator-wrapper">
                            <p class="attribute">Tägliche Scans & unbegrenzte Löschanfragen – für maximalen Datenschutz.</p>
                        </div> 
                    </div>

                    <div class="plan-attributes">
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Tägliche E-Mail-Prüfung</p>
                        </div>
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Anzeige der Unternehmen mit Deinen Daten</p>
                        </div>
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Unbegrenzte automatische Löschungen</p>
                        </div>
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Erweiterter Datenschutz</p>
                        </div>
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Premium-Support rund um die Uhr</p>
                        </div> 
                    </div>

                    <a class="default-btn plan-btn">ABONNIEREN</a>
                </div>
            </swiper-slide>
        </swiper>
    
        <swiper 
            v-else
            data-aos="fade-left"
            :slides-per-view="3" 
            :space-between="50" 
            :modules="modules" 
            :pagination="true" 
            :loop="false" 
            class="plans-wrapper"
            :breakpoints=" {  
                982: {
                    slidesPerView: 3 ,
                    spaceBetween: 50 
                },
                801: {
                    slidesPerView: 3,
                    spaceBetween: 15 
                },
                601: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                    loop: true 
                }, 
                541: {
                    slidesPerView: 1.5,
                    spaceBetween: 50, 
                    loop: true 
                },
                0: {
                    slidesPerView: 1,
                    spaceBetween: 100, 
                    loop: true 
                },
            }"    
        >
            <swiper-slide>
                <div class="plan">
                    <div class="title">
                        <p>Kostenlos <br> Basic</p>
                    </div> 

                    <div class="plan-attributes plan-attributes-subtitle">
                        <div class="offer-indicator-wrapper">
                            <p class="attribute">Finde heraus, welche Unternehmen Deine Daten gespeichert haben – komplett kostenlos!</p>
                        </div> 
                    </div> 

                    <div class="plan-attributes">
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>    
                            <p class="attribute">Einmalige E-Mail-Prüfung pro Monat</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Anzeige der Unternehmen mit Deinen Daten</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/doesnt-offer.png"/></div>  
                            <p class="attribute">Keine automatische Löschung möglich</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Basis-Support</p>
                        </div>
                    </div>

                    <a class="default-btn plan-btn">ABONNIEREN</a>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="plan plan-pro">
                    <div class="title">
                        <p>9,90 CHF <span class="price-discount">CHF 12, 90</span> <br> Beliebteste Option</p>  
                    </div> 

                    <div class="plan-attributes plan-attributes-subtitle">
                        <div class="offer-indicator-wrapper">
                            <p class="attribute">Wöchentliche Scans & bis zu 5 Löschanfragen pro Monat.</p>
                        </div> 
                    </div>

                    <div class="plan-attributes">
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Wöchentliche E-Mail-Prüfung</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Anzeige der Unternehmen mit Deinen Daten</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Automatische Löschung bei bis zu 5 Unternehmen pro Monat</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Benachrichtigungen bei verdächtigen Aktivitäten</p>
                        </div>
                        
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Priorisierter Support</p>
                        </div>
                        
                    </div>

                    <a class="default-btn plan-btn">ABONNIEREN</a>

                    <p class="price-tax-info">
                        Die Kosten betragen CHF 12,90, CHF 9,90 im ersten Jahr. Danach CHF 9,90 jährlich (MwSt./Umsatzsteuer kann anfallen).
                    </p>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="plan ">
                    <div class="title">
                        <p>19,90 CHF <br> Pro</p>
                    </div>
                
                    <div class="plan-attributes plan-attributes-subtitle">
                        <div class="offer-indicator-wrapper">
                            <p class="attribute">Tägliche Scans & unbegrenzte Löschanfragen – für maximalen Datenschutz.</p>
                        </div> 
                    </div>

                    <div class="plan-attributes">
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Tägliche E-Mail-Prüfung</p>
                        </div>
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Anzeige der Unternehmen mit Deinen Daten</p>
                        </div>
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Unbegrenzte automatische Löschungen</p>
                        </div>
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Erweiterter Datenschutz</p>
                        </div>
                        <div class="offer-indicator-wrapper">
                            <div class="offer-indicator"><img src="../assets/home-imgs/offers.png"/></div>  
                            <p class="attribute">Premium-Support rund um die Uhr</p>
                        </div> 
                    </div>

                    <a class="default-btn plan-btn">ABONNIEREN</a>
                </div>
            </swiper-slide>
        </swiper>
    </div>


    <div class="faq-container" id="contact">
        <p class="title">
            FAQ
        </p>

        <div class="questions-list">
            <div class="question" v-for="question in frequentQuestions" :key="question.id">
                <div class="question-title-wrapper">
                    <h2>{{ question.question }}</h2>
                    <div @click="toggleQuestion(question.id)" class="question-btn">
                        <p v-if="question.isOpen">-</p>
                        <p v-else>+</p>
                    </div>
                </div>
        
                <div class="answ" :class="{ 'answ-active': question.isOpen }" >
                    <p>{{ question.answer }}</p>
                    <div v-if="question.answers">
                        <p v-for="ans in question.answers" :key="ans">{{ ans }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="reviews-container">
        <p class="title">
            Übernimm die Kontrolle über Deine<br> Daten – sicher und einfach.
        </p>
        <swiper 
            :slides-per-view="3" 
            :space-between="50" 
            :modules="modules" 
            :pagination="true" 
            :loop="false" 
            class="reviews"
            :breakpoints=" {  
                982: {
                    slidesPerView: 3 ,
                    spaceBetween: 50 
                },
                801: {
                    slidesPerView: 3,
                    spaceBetween: 15 
                },
                601: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                    loop: true 
                },
                0: {
                    slidesPerView: 1,
                    spaceBetween: 50, 
                    loop: true 
                },
            }"      
        >
            <swiper-slide>
                <div class="review">
                    <div class="quote-img">
                        <img src="../assets/home-imgs/reviews/quote.png" />
                    </div>

                    <p class="text">Endlich eine Lösung, die mir hilft, meine persönlichen Daten aus Unternehmensdatenbanken zu entfernen. Schnell, sicher und einfach!</p>

                    <div class="stars"></div>

                    <div class="reviewer">
                        <div class="image">
                            <img src="../assets/home-imgs/reviews/reviewer1.png"/>
                        </div>
                        <div class="about">
                            <p class="name">Danny Dario</p>
                            <p class="date">29.08.2024</p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="review">
                    <div class="quote-img">
                        <img src="../assets/home-imgs/reviews/quote.png" />
                    </div>

                    <p class="text">Ich wusste nicht, wo meine Daten überall gespeichert sind – CleanData hat mir gezeigt, wie ich sie wieder unter Kontrolle bekomme. Sehr empfehlenswert!</p>

                    <div class="stars"></div>

                    <div class="reviewer">
                        <div class="image">
                            <img src="../assets/home-imgs/reviews/reviewer2.png"/>
                        </div>
                        <div class="about">
                            <p class="name">Albert Cyrill</p>
                            <p class="date">29.08.2024</p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="review">
                    <div class="quote-img">
                        <img src="../assets/home-imgs/reviews/quote.png" />
                    </div>

                    <p class="text">Ich hätte nicht gedacht, dass es so einfach sein kann, meine Online-Präsenz zu reduzieren. Eine echte Erleichterung für meinen Datenschutz!</p>

                    <div class="stars"></div>

                    <div class="reviewer">
                        <div class="image">
                            <img src="../assets/home-imgs/reviews/reviewer3.png"/>
                        </div>
                        <div class="about">
                            <p class="name">Madona Cadee</p>
                            <p class="date">29.08.2024</p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>

        <!-- <router-link to="/login" class="default-btn default-started-btn reviews-btn">JETZT STARTEN</router-link> -->
        <a @click="getStarted()" class="default-btn default-started-btn reviews-btn">JETZT STARTEN</a>
    </div>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-top">
                <div class="footer-img-wrapper">
                    <div class="footer-img">
                        <img src="../assets/cleandata_logo_white.svg" />
                    </div>
                    <p>
                        Mit CleanData findest Du heraus, welche Unternehmen Deine Daten<br> gespeichert haben, und kannst sie automatisiert löschen lassen. Deine<br> Privatsphäre, Deine Entscheidung.
                    </p>
                </div>

                <div class="footer-links footer-menu">
                    <p class="title">MENU</p>
                       
                    <a href="#services">Lösungen</a>
                    <a href="#resume">Mein Bereich</a>
                    <a href="#pricing">Preise</a>
                    <a href="#contact">Kontakt</a>
                </div>

                <!-- <div class="footer-links footer-service">
                    <p class="title">SERVICE</p>

                    <a>SEO</a>
                    <a>Marketing</a>
                    <a>SMM</a>
                    <a>Optimisation</a>
                </div> -->

                <div class="footer-socials">
                    <div class="social instagram">
                        <img src="../assets/home-imgs/social/instagram.png" />
                    </div>
                    <div class="social youtube">
                        <img src="../assets/home-imgs/social/youtube.png" />
                    </div>
                    <div class="social facebook">
                        <img src="../assets/home-imgs/social/facebook.png" />
                    </div>
                    <div class="social linkedin">
                        <img src="../assets/home-imgs/social/linkedin.png" />
                    </div>
                </div>
            </div>

            <div class="footer-bottom">
                <p class="copyright">
                    Copyright © 2025 CleanData. Alle Rechte vorbehalten.
                </p>
                <div class="terms">
                    <a>Nutzungsbedingungen</a>
                    <a>Datenschutzrichtlinie</a>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
import {
    Swiper,
    SwiperSlide
} from 'swiper/vue';
import { Autoplay , Pagination } from 'swiper/modules';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data(){
      return{
        payMode: true,

        modules: [Autoplay, Pagination], 
        frequentQuestions:[
          {
            id:'question1',
            question:'Was ist ein Datenmakler?',
            answer: 'Datenmakler sind Unternehmen, die personenbezogene Daten aus verschiedenen Quellen sammeln, analysieren und weiterverkaufen. Sie erhalten diese Informationen beispielsweise aus öffentlichen Registern, Online-Formularen oder Social-Media-Profilen und verkaufen sie an Werbetreibende, Versicherungen oder andere Organisationen.',
            isOpen: false,
          },
          {
            id:'question2',
            question:'Wie gelangen meine persönlichen Daten ins Internet?',
            answer: '',
            answers: [
                "Deine persönlichen Daten können auf verschiedene Weise online gelangen:",
                '● Durch die Registrierung auf Websites und in Apps.',
                '● Durch öffentlich zugängliche Datenquellen wie Handelsregister oder soziale Netzwerke.',
                '● Durch Datenmakler, die Informationen sammeln und weitergeben.',
                '● Durch Sicherheitslücken oder Datenlecks von Unternehmen. Mit CleanData kannst Du herausfinden, wo Deine Daten gespeichert sind und Löschanfragen stellen.'
            ],
            isOpen: false,
          },
          {
            id:'question3',
            question:'Wie kann ich mich anmelden?',
            answer: '',
            answers: [
                '1. Klicke auf „Jetzt starten“.',
                '2. Gib Deine E-Mail-Adresse ein und erstelle ein sicheres Passwort.',
                '3. Bestätige Deine E-Mail-Adresse über den zugesendeten Link.',
                '4. Scanne Dein digitales Profil und beginne mit dem Datenschutz-Check.'
            ],
            isOpen: false,
          },
          {
            id:'question4',
            question:'Könnt Ihr Google-Suchergebnisse über mich löschen?',
            answer: 'Nein, wir können Google-Suchergebnisse nicht direkt löschen. Allerdings kannst Du bei Google selbst einen Antrag auf Entfernung stellen, wenn bestimmte Voraussetzungen erfüllt sind (z. B. veraltete oder falsche Informationen). Unser Service hilft Dir jedoch, Deine Daten bei Unternehmen und Datenmaklern zu löschen, sodass weniger persönliche Informationen online verfügbar sind und das Risiko unerwünschter Suchergebnisse sinkt.',
            isOpen: false,
          },
        ],

        mobMenu: true,
        mobile: false,

        slideMobMenu: false,

        mobileImg: false,
      }
    },    
    methods: {
        getStarted(){
            if(this.currentUser){
                this.$router.push(`/dashboard`)
            }else{
                this.$router.push(`/login`)
            }
        },

        toggleQuestion(questionId) {
            this.frequentQuestions = this.frequentQuestions.map(question => ({
                ...question,
                isOpen: question.id === questionId ? !question.isOpen : false
            }));
        }, 

        checkIfMobile(){
            if(window.innerWidth <= 768){
                this.mobile = true;
            }else{
                this.mobile = false;
            }
        },

        checkIfMobileImg(){
            if(window.innerWidth <= 600){
                this.mobileImg = true;
            }else{
                this.mobileImg = false;
            }
        },
        toggleMobileMenu(){
            this.slideMobMenu = !this.slideMobMenu;
            this.mobMenu = !this.mobMenu;
        }
       
    },
    mounted() { 
        this.checkIfMobile();

        this.checkIfMobileImg();
        
        window.addEventListener("resize", this.checkIfMobile);
        window.addEventListener("resize", this.checkIfMobileImg);
    },
    beforeUnmount() { 
        window.removeEventListener("resize", this.checkIfMobile);
        window.removeEventListener("resize", this.checkIfMobileImg);
    },
}
</script>

<style lang="scss" scoped> 
nav { 
    width: 78%;
    margin: 0 auto;
    margin-top: 26px;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.nav-logo {
    display: block;
    width: 100px;

    img {
        width: 100%;
    }
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 42px; 

    a {
        // font-size: 19px;
        font-size: 16px;
        color: #3C3C3C;
        cursor: pointer;
    }
}

.nav-btns {
    display: flex;
    align-items: center;
    gap: 31px;

}

.default-btn {
    display: block;
    width: 186px;
    cursor: pointer;
    border-radius: 20px; 
    padding: 10px 0;
    text-align: center; 
    font-size: 16px;
    transition: 0.3s ease;
    color: white;
    background-color: #FA3A67;
    border: 1px solid #FA3A67;

    &:hover{
        background-color: white;
        color: #FA3A67;
        border: 1px solid #FA3A67;
    }
}

.default-login-btn {
    color: #FA3A67;
    border: 1px solid #FA3A67;
    background-color: white;
    &:hover{
        background-color: #FA3A67;
        color: white; 
    }
}
 
.get-started-section {
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 140px;
    width: 1200px;
    width: 78%;
    display: flex;
    align-items: center;

    .column {
        width: 40%;
    }

    .big-column {
        width: 60%;
    }

    .column-img {
        width: 100%
    }
}

.column-img {
    img {
        width: 100%
    }
}

.big-text { 
    p{
        color: #3C3C3C;
        font-size: 38px;
        font-weight: bold;
    }
}

.small-text {
    margin: 20px 0; 
    p{
        font-size: 18px;
        color: #3C3C3C;
    }
}

.partners-section {
    display: flex;
    align-items: center;
    gap: 200px;
    width: 100%;
    padding: 0 55px;
    margin-bottom: 100px;

    .partner {
        width: 165px; 
        img {
            width: 100%;
        }
    }
}

.bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    img {
        width: 100%;
    }
}

.showcase-section {
    position: relative;
    width: 100%; 
    padding-top: 120px;  
    text-align: center; 

    .big-text {

        z-index: 2;
        position: relative;
        p{
            font-size: 42px;
            color: #FAFAFA;
        }
    }

    .small-text {
        p{
            font-size: 18px;
            color: #FAFAFA;
        }
        z-index: 2;
        position: relative;
        margin: 0;
    }
}

.rounded-white {
    position: relative;
    z-index: 2;
    margin-top: 70px;
    z-index: 2;
}

.row-images {
    display: flex; 
    justify-content: center;
    width: 75%;
    margin:  0 auto;
    justify-content: space-between;

    .row-images-group {
        position: relative;
        width: 48%;
        height: 100%;
    }

    .image { 
        width: 100%;
        img {
            width: 100%;
        }
    }

    .small {
        width: 97px;
        position: absolute;
        bottom: 0px;

        img {
            width: 100%;
        }
    }

    .small1{
        left: -30px;
    }

    .small2{
        right: -30px;
    }

}

.info-rows {
    padding-top: 70px;
    width: 1200px;
    width: 78%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.info-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 180px;

    .info {
        .number {
            width: 45px;

            img {
                width: 100%;
            }
        }

        .number-two{
            margin-left: auto;
        }

        .text {
            color: #333333;
            font-size: 28px;
            margin-top: 14px;
            margin-bottom: 18px;
            font-weight: 500;
            text-align: left;
        }
    }

    .info-image {
        width: 48%; 
        position: relative;

        img {
            width: 100%;
        }

        .small {
            position: absolute;
            width: 162px; 
            left: -50px;
            bottom: -80px; 
        }
    }
}

.shadow-img{
    width: 100%; 
    position: absolute;
    z-index: 1;
    left: 0;
    img{
        width: 100%;
    }
}

.shadow-img1{
    top: 46%; 
}

.shadow-img2{
    top: 78%; 
}

.row-images-group {
    position: relative;
}

.numbers-stats {
    background-color: white;
    padding: 100px 0;
    padding-top: 0;

    .title {
        font-weight: bold;
        color: #3C3C3C;
        font-size: 36px;
        text-align: center;
        margin-bottom: 80px;
    }

    .numbers-content {
        width: 1200px;
        width: 78%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .number-container {
            .number {
                color: #FF3A67;
                font-weight: bold;
                font-size: 36px;
                margin-bottom: 15px;
            }
 

            .subtitle, .text {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
            }

            .text { 
                font-weight: 400; 
            }
        }
    }
}

.pay-plans {
    position: relative;

    .title {
        color: #FAFAFA;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
        position: relative;
        z-index: 2;
        padding-top: 140px;
    }

    .subtitle {
        color: #FAFAFA;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-top: 25px;
        position: relative;
        z-index: 2;
    }

    .plans-toggle-wrapper{
        position: relative;
        z-index: 2;
        background: hsla(0, 0%, 51%, 0.2) ;
    
        border-radius: 25px;
        display: flex; 
        justify-content: space-between;
        width: 15%;
        margin: 0 auto;
        margin-top: 43px;
        padding: 5px;

        .plan-mode{ 
            cursor: pointer;
            width: 48%;
            text-align: center;
            padding: 10px;
            border-radius: 25px;
            p{
                font-size: 16px;
                color: white;
                font-weight: bold;
            }
        }

        .plan-mode-active{
            background-color: white;
            p{
                color:#FA3A67 ;
            }
        }
    }

    .plans-wrapper {
        position: relative;
        z-index: 2;
        width: 78%; 
        padding: 100px 10px;
        margin: 100px auto;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        .plan { 
            width: 90%;
            padding: 40px;
            background-color: white; 
            border-radius: 15px;
            box-shadow: 0px 4px 4px #00000040;

            .title {
                padding: 0;
                text-align: left; 

                p {
                    color: #292929;
                    font-size: 24px;
                }
            }

            .plan-attributes {
                margin-top: 40px;
                margin-bottom: 70px;

                .offer-indicator-wrapper{ 
                    .offer-indicator{ 
                        position: absolute; 
                    }

                    .attribute{ 
                        margin-left: 30px;
                        margin-bottom: 20px;
                        color: #838383;
                        font-size: 14px;
                    }
                } 
            }

            .plan-attributes-subtitle{
                margin: 0 !important;
                margin-top: 20px !important;

                .offer-indicator-wrapper{
                  .attribute{
                    margin-left: 0 !important;
                  }  
                }
            }

            .plan-btn {
                border-radius: 0;
                border-radius: 15px;
                margin: 0 auto;

                color: #FA3A67;
                border: 1px solid #FA3A67;
                background-color: white;
            }

            &-pro {
                transform: scale(1.2);
                .plan-btn{
                    color: white;
                    border: 1px solid #FA3A67;
                    background-color: #FA3A67;
                }

                .plan-attributes { 
                    margin-bottom: 50px !important;
                }
            }
             
            .price-tax-info{
                font-size: 14px;
                color: #333333;
                margin-top: 25px;
            }

            .monthly-indicator{ 
                font-size: 14px;
                color:  #222222;
            }

            .price-discount{
                color: #7A7A7A; 
                font-size: 14px;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    height: 2px;  
                    background-color: #e64560;  
                    transform: rotate(-5deg);  
                }
            } 
        }

    }

}


.faq-container{
    width: 1200px;
    margin: 0 auto;

    .title{
        color: #3C3C3C;
        font-size: 70px;
        position: relative;
        text-align: center;

        &::after{
            content: "";
            display: block;
            width: 103px;  
            margin: 0 auto;
            height: 4px;  
            border-radius: 5px;
            background-color: #FF3A67;  
        }
    }
}

.questions-list{
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
}

.question{
    box-shadow: 2px 4px 10px #00000029;
    border-radius: 15px;
    margin-bottom: 24px;
    padding: 5px;
}
 
.question-title-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 34px;
}

.question-title-wrapper h2{
    font-size: 24px;
    color: #292C31;
    font-weight: normal
}

.question-btn{
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer; 
}

.question-btn p{
  font-weight: normal;
  font-size: 36px;
  color: #FF3A67;
}

.answ{
  border: none;
  position: relative;
  background-color: var(--body-background-color);
  border-radius: 10px;
  max-height: 0; 
  overflow: hidden;
  transition: 0.5s ease; 
}

.answ p{
    font-size: 16px;
    width: 95%;
}

.answ-active{
    transition: 0.5s ease; 
     max-height: 15em;
    padding: 5px 20px;
}

 
.reviews-btn {
    margin: 0 auto;
    margin-top: 77px;
}

.reviews-container {
    margin: 90px auto;
    margin-top: 150px;
    width: 78%;

    .title {
        text-align: center;
        color: #333333;
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 77px;
    }

    .reviews {
        width: 99%;

        .review {
            border: 1px solid #CFCFCF;
            padding: 46px 40px;
            width: 100%;

            .quote-img {
                width: 46px;
                margin: 0 auto;
                margin-bottom: 42px;

                img {
                    width: 100%;
                }
            }

            .text {
                color: #3E5073;
                font-size: 18px;
                text-align: center;
                margin-bottom: 87px;
            }

            .stars {
                margin-bottom: 50px;
            }

            .reviewer {
                display: flex;
                align-items: center;
                gap: 10px;

                .image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    img{
                        width: 100%;
                    }
                }

                .about {
                    .name {
                        color: #3E5073;
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    .date {
                        color: #3E5073;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

footer {
    background-color: #3C3C3C;
    padding: 22px 0; 

    .footer-wrapper {
        width: 78%;
        margin: 0 auto;

    }

    .footer-top {
        width: 100%;
        justify-content: space-between;
        display: flex;
        margin-bottom: 100px;
    }

    .footer-bottom {
        justify-content: space-between;
        display: flex;
        align-items: center;
        border: 2px solid transparent;
        border-top-color: #464646;
        padding: 40px 0;
        padding-bottom: 18px;
        color: white;

        p{
            font-size: 18px;
        }

        .terms {
            justify-content: space-between;
            display: flex;
            align-items: center;
            gap: 96px;

            a{
                font-size: 18px;
            }
        }
    }
}

.footer-img-wrapper {
    p {
        font-size: 14px;
        color: white;
        margin-top: 42px;
    }
}

.footer-img {
    width: 120px;

    img {
        width: 100%;
    }
}

.footer-links {
    color: white;
    margin-top: 84px;

    .title {
        font-size: 20px;
        margin-bottom: 35px;
    }

    a {
        color: white;
        display: block;
        margin-bottom: 15px;
    }
}

.footer-socials {
    display: flex;
    align-items: center;
    gap: 35px;

    .social {
        img {
            width: 100%
        }

        width: 20px;
        height: 20px;

    }

    .youtube {
        width: 28px;
        height: 20px;
    }

    .facebook {
        width: 10px;
        height: 20px;
    }
}

.number-container {
    width: 32%;
}

.faq-container  {
    width: 65%;
}

.offer-indicator{
    width: 26px;
    height: 26px;
    
    img{
        width: 100%
    }
}


/* 1920-1080 */
@media (min-width: 1920px) {
    .nav-logo{
        width: 140px;
    }
    .nav-links a{
        font-size: 22px;
    }

    .default-btn{ 
        padding: 12px 0; 
        font-size: 19px;
    }

    .get-started-section{
        .big-column{
            .big-text{
                p{
                    font-size: 42px;
                }
            }

            .small-text{
                p{
                    font-size: 20px;
                }
            }
        }
    }

    .showcase-section {
        .big-text p{
            font-size: 46px;
        }

        .small-text{
            p{
                font-size: 20px;
            }
        }
    }

    .info-row .info .text { 
        font-size: 32px;
    }

    .numbers-stats .title { 
        font-size: 44px;
    }

    .numbers-stats .numbers-content .number-container {
        .number  {
            font-size: 40px;
        }
        
        .subtitle, .text  {
            font-size: 20px;
        }
    }

    .pay-plans {
        .title {
            font-size: 46px;
        }

        .subtitle{
            font-size: 20px;
        }

        .plans-wrapper {
            .plan {
                .title p {
                    font-size: 28px;
                }
                .plan-attributes{
                    .offer-indicator-wrapper{ 
                        .attribute{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .faq-container .title { 
        font-size: 74px;
    }

    .question-title-wrapper h2 {
        font-size: 26px;
    }

    .reviews-container {
        .title { 
            font-size: 44px;
        }

        .reviews .review{ 
            .text { 
                font-size: 20px;
            }

            .reviewer {
                .image {
                    width: 50px;
                    height: 50px;
                }
                .about {
                    .name  { 
                        font-size: 18px; 
                    }

                    .date{
                        font-size: 16px; 
                    }
                }
            }
        }
    }


    .footer-img-wrapper p {
        font-size: 16px; 
    }

    .footer-img {
        width: 140px;
    }

    .footer-links{
        .title {
            font-size: 24px;
        }

        a{
            font-size: 18px;
        }
    }

    .footer-bottom { 
        p{
            font-size: 20px;
        }

        .terms { 

            a{
                font-size: 20px;
            }
        }
    }
 
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) { 
    .pay-plans { 
        .plans-wrapper { 
            .plan {   
                .price-tax-info{
                    font-size: 16px; 
                } 
            } 
        } 
    }
}

/* 2304-1440 */
@media (min-width: 2304px) {
    .nav-logo {
        width: 160px;
    }
 
    .nav-links {
        gap: 48px;
        a {
            font-size: 25px;
        }
    }

    .nav-btns { 
        gap: 38px;
    }

    .default-btn { 
        font-size: 22px;
        width: 210px;
    }

    .get-started-section .big-column .big-text p {
        font-size: 46px;
    }

    .get-started-section .big-column .small-text p {
        font-size: 22px;
    }

    .showcase-section .big-text p {
        font-size: 52px;
    }

    .showcase-section .small-text p {
        font-size: 24px;
    }

    .info-row .info .number {
        width: 50px;
    }

    .info-row .info .text {
        font-size: 40px;
    }

    .number-container{
        width: 32%;
    }

    .numbers-stats{
        .title {
            font-size: 52px;
        }

        .numbers-content .number-container{
            .number {
                font-size: 56px;
            }

            .subtitle, .text {
                font-size: 22px;
            }
        }
    }
 

    .pay-plans{ 
        .title {
            font-size: 52px;
        }

        .subtitle {
            font-size: 24px;
        }

        .plans-wrapper{
            .plan {
                .title p {
                    font-size: 36px;
                }

                .plan-attributes { 
                    .offer-indicator-wrapper{
                        .attribute {
                            font-size: 18px;
                        }
                    }
                }

                .monthly-indicator{ 
                    font-size: 16px; 
                }

                .price-discount{ 
                    font-size: 16px; 
                } 
            }
        }

 
    } 


    .faq-container .title {
        font-size: 79px;
    }

    .question-title-wrapper h2 {
        font-size: 28px;
    }

    .question-btn {
        height: 30px;
        width: 30px;
        p { 
            font-size: 40px;
        }
    } 

    .answ-active {
        height: 120px;
    }

    .answ p {
        font-size: 20px; 
    }


    .reviews-container {
        .title {
            font-size: 52px;
        }

        .reviews{
            .review {
                .text {
                    font-size: 24px;
                }

                .reviewer{
                    .about{
                        .name {
                            font-size: 22px;
                        }
                        .date {
                            font-size: 20px;
                        }
                    }

                    .image {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }
    }


    .footer-img {
        width: 160px;
    }

    .footer-img-wrapper p {
        font-size: 18px;
    }

    .footer-links {
        .title {
            font-size: 26px;
        }

        a {
            font-size: 20px;
        }
    }
 

    .footer-socials {
        .social {
            width: 30px;
            height: 30px;
        }
        .youtube {
            width: 38px;
            height: 30px;
        }
        .facebook {
            width: 14px;
            height: 24px;
        }
    }

    footer .footer-bottom {
        p {
            font-size: 20px;
        }

        .terms a {
            font-size: 20px;
        }
    }
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
}

/*2560-1440*/
@media (min-width: 2560px) {
    .nav-logo {
        width: 180px;
    }
 
    .nav-links {
        gap: 52px;
        a {
            font-size: 28px;
        }
    }

    .nav-btns { 
        gap: 40px;
    }

    .default-btn { 
        font-size: 24px;
        width: 250px;
    }

    .get-started-section .big-column {
        .big-text p {
            font-size: 48px;
        }
        .small-text p {
            font-size: 24px;
        }
    }
 

    .showcase-section {
        .big-text p {
            font-size: 54px;
        }
        .small-text p {
            font-size: 26px;
        }
    } 

    .info-row .info {
        .number {
            width: 55px;
        }
        .text {
            font-size: 40px;
        }
    } 

 

    .numbers-stats{
        .title {
            font-size: 54px;
        }

        .numbers-content .number-container{
            .number {
                font-size: 58px;
            }

            .subtitle, .text {
                font-size: 24px;
            }
        }
    }


    .pay-plans {
        .title {
            font-size: 54px;
        }

        .subtitle {
            font-size: 26px;
        }

        .plans-wrapper {
            .plan {
                .title p {
                    font-size: 38px;
                }

                .plan-attributes {
                    .offer-indicator-wrapper{
                        .attribute {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
 

    .faq-container  {
        width: 60%;
        
        .title {
            font-size: 82px;
        }
    }

    .question-title-wrapper h2 {
        font-size: 30px;
    }

    .question-btn {
        height: 35px;
        width: 35px;
        p { 
            font-size: 42px;
        }
    } 

    .answ-active {
        height: 150px;
    }

    .answ p {
        font-size: 22px; 
    }


    .reviews-container {
        .title {
            font-size: 54px;
        }

        .reviews{
            .review {
                .text {
                    font-size: 26px;
                }

                .reviewer{
                    .about{
                        .name {
                            font-size: 24px;
                        }
                        .date {
                            font-size: 22px;
                        }
                    }

                    .image {
                        width: 65px;
                        height: 65px;
                    }
                }
            }
        }
    }


    .footer-img {
        width: 180px;
    }

    .footer-img-wrapper p {
        font-size: 20px;
    }

    .footer-links {
        .title {
            font-size: 28px;
        }

        a {
            font-size: 22px;
        }
    }
 

    .footer-socials {
        .social {
            width: 35px;
            height: 35px;
        }
        .youtube {
            width: 43px;
            height: 35px;
        }
        .facebook {
            width: 19px;
            height: 29px;
        }
    }

    footer .footer-bottom {
        p {
            font-size: 22px;
        }

        .terms a {
            font-size: 22px;
        }
    }
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .info-rows  {
        padding-top: 150px;
    } 
 
    .pay-plans { 
        .plans-wrapper { 
            margin-top: 0px;
            .plan {   
                .price-tax-info{
                    font-size: 18px; 
                } 
            } 
        } 
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
    .nav-logo {
        width: 200px;
    }
 
    .nav-links {
        gap: 55px;
        a {
            font-size: 30px;
        }
    }

    .nav-btns { 
        gap: 45px;
    }

    .default-btn { 
        font-size: 26px;
        width: 300px;
    }

    .get-started-section .big-column {
        .big-text p {
            font-size: 50px;
        }
        .small-text p {
            font-size: 26px;
        }
    }
 

    .showcase-section {
        .big-text p {
            font-size: 56px;
        }
        .small-text p {
            font-size: 28px;
        }
    } 

    .info-row .info {
        .number {
            width: 55px;
        }
        .text {
            font-size: 40px;
        }
    }  

    .numbers-stats{
        .title {
            font-size: 56px;
        }

        .numbers-content .number-container{
            .number {
                font-size: 60px;
            }

            .subtitle, .text {
                font-size: 26px;
            }
        }
    }


    .pay-plans {
        .title {
            font-size: 56px;
        }

        .subtitle {
            font-size: 28px;
        }

        .plans-wrapper {
            padding: 180px 15px;

            .plan {
                .title p {
                    font-size: 40px;
                }

                .plan-attributes .attribute {
                    font-size: 22px;
                }

                .price-tax-info{
                    font-size: 20px; 
                } 

                .monthly-indicator{ 
                    font-size: 18px; 
                }

                .price-discount{ 
                    font-size: 18px; 
                } 
            }
        }
    } 
 
    .faq-container  {
        width: 60%;
        
        .title {
            font-size: 85px;
        }
    }

    .question-title-wrapper h2 {
        font-size: 32px;
    }

    .question-btn {
        height: 38px;
        width: 38px;
        p { 
            font-size: 44px;
        }
    } 

    .answ-active {
        height: 150px;
    }

    .answ p {
        font-size: 24px; 
    }


    .reviews-container {
        .title {
            font-size: 56px;
        }

        .reviews{
            .review {
                .text {
                    font-size: 28px;
                }

                .reviewer{
                    .about{
                        .name {
                            font-size: 26px;
                        }
                        .date {
                            font-size: 24px;
                        }
                    }

                    .image {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }
    }


    .footer-img {
        width: 200px;
    }

    .footer-img-wrapper p {
        font-size: 22px;
    }

    .footer-links {
        .title {
            font-size: 30px;
        }

        a {
            font-size: 24px;
        }
    }
 

    .footer-socials {
        .social {
            width: 40px;
            height: 40px;
        }
        .youtube {
            width: 48px;
            height: 40px;
        }
        .facebook {
            width: 24px;
            height: 39px;
        }
    }

    footer .footer-bottom {
        p {
            font-size: 24px;
        }

        .terms a {
            font-size: 24px;
        }
    }
}

/*3200-1800*/
@media(min-width: 3200px) {
}

/*3360-1890 1695*/
@media(min-width:3360px) { 
}
 


/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
    .row-images { 
        width: 80%;
    }

    .row-images .small {
        width: 90px;
    }

    .info-rows { 
        width: 80%;
    }

    .info-row .info-image .small { 
        width: 140px;
        left: -40px;
        bottom: -70px;
    }


    .numbers-stats .numbers-content { 
        width: 85%;
    }
 
    .pay-plans {
        .title { 
            padding-top: 120px;
        }

        .plans-toggle-wrapper { 
            width: 18%;
            .plan-mode p {
                font-size: 14px;
            }
        }

        .plans-wrapper{
            .plans-wrapper { 
                padding: 70px 10px;
     
                .plan{
                    .plan-attributes { 
                        margin-bottom: 50px;
                    }

                    &-pro{
                        .plan-attributes { 
                            margin-bottom: 30px !important;
                        }
                    }
                }

               
            }
        }
    }  
}

/*1680-1080*/
@media(max-width: 1680px) {
    .info-row .info-image .small {
        width: 110px;
        left: -20px;
        bottom: -20px;
    }

    .info-row .info .number {
        width: 40px;
    }

    .info-row .info .text { 
        font-size: 26px;
    }
 
    .pay-plans { 
        .plans-wrapper { 
            .plan {   
                .title p { 
                    font-size: 22px;
                }
                .price-tax-info{
                    font-size: 12px; 
                } 
            } 
        } 
    }
}

/*1640-1080*/
@media(max-width: 1640px) {
    .pay-plans .plans-wrapper .plan { 
        padding: 30px;
    }
}

/*my big monitor 1600 - 757 */
@media (max-width: 1600px) {    
    .pay-plans { 
        .plans-wrapper { 
            .plan {   
                .price-tax-info{
                    font-size: 10px; 
                } 
            } 
        } 
    }  

    .offer-indicator-wrapper{
        .attribute {
            margin-left: 25px;
        }
        .offer-indicator {
            width: 20px;
            height: 20px;
        }
    }
}

/*my small monitor 1536-864*/
@media(max-width:1536px) {
    .reviews-container .title { 
        font-size: 36px;
    }

    .pay-plans .plans-wrapper { 
        width: 80%;
    }

    .reviews-container { 
        width: 80%;
    } 
}


/* 1440-990 */
@media(max-width:1440px) {
    .nav-links { 
        gap: 38px;
    }

    .nav-btns { 
        gap: 25px;
    }

    .big-text p { 
        font-size: 34px;
    }

    .small-text p {
        font-size: 16px;
    }

    .showcase-section .big-text p {
        font-size: 40px;
    }

    .showcase-section { 
        padding-top: 100px;
    }

    .info-row .info {
        .number {
            width: 38px;
        }
        .text { 
            font-size: 24px;
        }
    } 

    .pay-plans {
        .plans-wrapper { 
            width: 85%;
        }

        .plans-wrapper{
            .plan{
                .title p{
                    font-size: 22px;
                }

                .plan-attributes{
                    .offer-indicator-wrapper{
                        .attribute {
                            margin-left: 25px;
                        }
                        .offer-indicator {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
 
    .faq-container {
        width: 75%;
    }

    .question-title-wrapper { 
        padding: 26px 34px;
        
        h2 {
            font-size: 20px;
        }
    }
     
    .reviews-container { 
        width: 85%;
    }

    footer .footer-wrapper {
        width: 80%; 
    }
}

/*1366-768*/
@media (max-width:1366px) {
    .pay-plans {
        .plans-wrapper { 
            width: 90%;
            
            .plan{
                padding: 20px;
                .plan-attributes{
                    .offer-indicator-wrapper{
                        .attribute { 
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    } 

    .faq-container {
        width: 80%;
    } 
    
    .reviews-container { 
        width: 90%;
    }

    footer {
        .footer-wrapper {
            width: 85%; 
        } 

        .footer-bottom{
            p {
                font-size: 16px;
            }  
                
            .terms { 
                gap: 80px;

                a {
                    font-size: 16px;
                }  
            }
        }
    } 
}

/* 1280-800 */
@media (max-width:1280px) {
    nav,
    .get-started-section,
    .info-rows,
    .numbers-stats .numbers-content {
        width: 85%;
    }

    .pay-plans .plans-wrapper { 
        width: 95%;
    }
}

@media (max-width:1200px) {
    .nav-logo {
        width: 90px;
    }

    .nav-links {
        gap: 35px;

        a{
            font-size: 14px;
        }
    }

    .big-text p {
        font-size: 30px;
    }

    .small-text p {
        font-size: 14px;
    }

    .default-btn { 
        width: 160px; 
        font-size: 14px;
    }

    .showcase-section {
        .big-text p {
            font-size: 36px;
        }

        .small-text p {
            font-size: 16px;
        }
    }

    .row-images {
        width: 85%;
    }


    .info-row {
        .info {
            .number {
                width: 30px;
            }
            .text {
                font-size: 20px;
            }
        }
    }

    .info-row .info-image .small {
        width: 90px;
        left: -10px;
        bottom: -10px;
    }


    .numbers-stats {
        .title { 
            font-size: 34px;
        }
        .numbers-content .number-container {
            .number { 
                font-size: 32px; 
            }
            .subtitle, .text {
                font-size: 14px;
            }
        }  
    }
 
    .pay-plans {
        .title {
            padding-top: 70px;
            font-size: 36px;
        }

        .plans-toggle-wrapper {
            width: 22%;
            margin-top: 35px;
        }

        .plans-wrapper {
            padding: 62px 10px;

            .plan {
                .plan-attributes {
                    margin-top: 25px;
                    margin-bottom: 25px;
                }
            }
        }
    }
 
    .faq-container {
        width: 85%;
        .title { 
            font-size: 64px;
        }
    }

    .question-title-wrapper {
        padding: 22px 24px;

        h2{
            font-size: 18px;
        }
    }

    .answ p {
        font-size: 14px;
    }

    .reviews-container {
        .title { 
            font-size: 34px;
        }

        .reviews {
            .review { 
                padding: 30px 18px;
                width: 100%;
                
                .text { 
                    font-size: 16px;
                }
                .reviewer {
                    .image {
                        width: 35px;
                        height: 35px;
                    }
                    .about {
                        .name { 
                            font-size: 14px;
                        }
                        .date { 
                            font-size: 12px;
                        }
                    }  
                }
            }
        }
    }

    footer {
        .footer-top { 
            margin-bottom: 50px;

            .footer-img-wrapper{
                .footer-img {
                    width: 90px;
                } 

                p {
                    font-size: 12px;
                }
            }

            .footer-links {
                .title {
                    font-size: 18px;
                }
                a{
                    font-size: 14px;
                }
            }

            .footer-socials .youtube{
                width: 24px;
                height: 16px;
            } 
        }

        .footer-bottom {
            p {
                font-size: 14px;
            }
            .terms a {
                font-size: 14px;
            }
        }
    }
}

/*1134x712*/
@media(max-width:1134px) {
    .showcase-section {
        padding-top: 50px;
    }

    .pay-plans .plans-wrapper .plan .title {
        padding: 15px 0;
    }
}

/*1114x705*/
@media(max-width:1114px) {
    .big-text p {
        font-size: 28px;
    }
    
    .showcase-section{    
        padding-top: 70px;
    
        .big-text p {
            font-size: 34px;
        }
    }

    .rounded-white {
        margin-top: 30px;
    }

    .faq-container .title {
        font-size: 54px;
    }

    .reviews-container {
        width: 95%;
        margin-top: 100px;

        .title { 
            font-size: 32px; 
            margin-bottom: 65px;
        }
    } 
}

/*1024-768*/
@media (max-width:1024px) {
    .plans-wrapper{
        width: 90%;

        .plans-wrapper{
            .plan{
                .title p{
                    font-size: 20px;
                }

                .plan-attributes-subtitle{
                    margin-top: 0 !important; 
                }
            }
        }
    }

    .faq-container .title {
        font-size: 58px;
    }

    .reviews-container .title {
        font-size: 32px;
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
    nav, 
    .get-started-section, 
    .info-rows, 
    .numbers-stats 
    .numbers-content, 
    .pay-plans .plans-wrapper, 
    .reviews-container, 
    footer .footer-wrapper {
        width: 90%;
    }

    .pay-plans {
        .title {
            padding-top: 80px;
        }
 
        .plans-toggle-wrapper {
            width: 30%;
        }

        .plans-wrapper{
            .plan{
                .title {
                    padding: 10px 0;
                    p {
                        font-size: 20px;
                    }
                }

                .plan-attributes {
                    .offer-indicator {
                        width: 15px;
                        height: 15px;
                    }

                    .attribute {
                        margin-left: 23px;
                    }
                }
            }
        }
    }

    .row-images .small {
        width: 70px;
    }

    .info-row .info-image .small{
        width: 70px;
        left: -10px;
        bottom: -10px;
    }

    .footer-socials .youtube {
        width: 24px;
        height: 16px;
    }
}

/* 962x601 */
@media(max-width: 962px) {
    .nav-links {
        gap: 25px;
    }

    .nav-btns {
        gap: 20px;
    }
}

/* 900x1600 */
@media(max-width: 900px) {
    .nav-links {
        gap: 20px;
    }

    .nav-btns {
        gap: 16px;
    }

    .big-text p {
        font-size: 26px;
    }

    .pay-plans .title {
        font-size: 32px;
    }

    .faq-container .title {
        font-size: 50px;
    }

    .reviews-container .title {
        font-size: 30px;
    }

    .pay-plans {
        .plans-toggle-wrapper {
            width: 35%;
        }
    }
}

/* 884 x 1104 */
@media(max-width: 884px) {
    .faq-container {
        width: 80%;
    }
}

/*834-1112*/
@media(max-width: 834px) {
    .nav-links {
        gap: 14px;
    }

    .small {
        width: 90px;
    }

    .pay-plans {
        .plans-toggle-wrapper {
            width: 40%;
        }
    }
}

/* 820x1080 */
@media(max-width: 820px) {}

/* 800x1280 */
@media(max-width: 800px) {
    .get-started-section{
        flex-direction: column-reverse;
        .column {
            width: 50%;
        }
        .big-column {
            width: 100%;
            margin-top: 25px;
        } 
    }  

    .partners-section{
        padding: 0;
    }
 
    .showcase-section {
        padding-top: 40px;
    }
   
    .pay-plans {

        .title {
            padding-top: 50px;
        } 

        .plans-toggle-wrapper {
            width: 45%;
        }

        .plans-wrapper{
            padding: 10px 10px;

            .plan {
                width: 100%;
            }

            .plan-pro {
                transform: scale(1);
            }
        }
    } 
}

/*778-304*/
@media(max-width: 778px) {}

/* 768x1024 */
@media(max-width: 768px) {
    footer{
        .footer-top{
            .footer-links .title {
                font-size: 16px;
            }  
            .footer-socials { 
                gap: 25px;
            }
        }
         
        .footer-bottom {
            p {
                font-size: 12px;
            }
            .terms { 
                gap: 40px;
                a {
                    font-size: 12px;
                }
            }
        } 
    }
     
    .login-menu-link-wrapper a{
        border: 1px solid #FF3A67;
        border-radius: 20px;
        padding: 4px 15px;
        color: #FF3A67;
    }

    .plans-toggle-wrapper {
        width: 50%;
    }
}

/*712-1138*/
@media(max-width:712px) {
    .showcase-section .big-text p {
        font-size: 30px;
    }

    .row-images .small {
        width: 80px;
    }



    .info-row .info .text {
        font-size: 18px;
    }

    .numbers-stats .title {
        font-size: 30px;
    }

    .pay-plans{ 
        .title {
            padding-top: 40px;
        }

        .subtitle {
            font-size: 14px;
        }

        .plans-toggle-wrapper {
            width: 55%;
        }
    }

    .faq-container .title {
        font-size: 40px;
    }

    .question-title-wrapper {
        padding: 18px 14px;
        h2 {
            font-size: 14px;
        }
    }

    .question-btn p { 
        font-size: 28px;
    }

    .answ p {
        font-size: 12px;
    }


    .reviews-container {
        .title {
            font-size: 26px;
        }

        .reviews .review .quote-img {
            width: 36px;
        }
    }
    
    .info-row .info-image { 

        .small {
            left: -10px;
            bottom: -10px;
            width: 60px; 
        }
    } 
}

/* 694-768 */
@media(max-width:694px) {
    .showcase-section{
        .big-text p {
            font-size: 26px;
        }
        .small-text p {
            font-size: 14px;
        }
    } 

    .info-row .info .text {
        font-size: 16px;
    } 
}

/* 678-1024 */
@media(max-width:678px) {
    footer { 
        padding: 25px 0;
        .footer-top { 
            flex-direction: column; 
            margin-bottom: 50px;

            .footer-img-wrapper {
                .footer-img{
                    margin: 0 auto;
                }

                p {
                    font-size: 16px;
                    text-align: center; 
                    margin-top: 20px; 
                }
            }

            .footer-links{
                text-align: center;
                margin-top: 46px;

                .title {
                    font-size: 19px;
                    margin-bottom: 30px;
                }

                a {
                    font-size: 17px;
                }
            }

            .footer-socials{
                margin-top: 40px;  
                justify-content: center;
                gap: 35px;

                .youtube {
                    width: 28px;
                    height: 20px;
                }
            }
        }

        .footer-bottom {
            flex-direction: column;

            p {
                font-size: 16px;
            }

            .terms {
                
                a {
                    font-size: 14px;
                }
            }

            .copyright{
                margin-bottom: 30px;
                width: 190px;
                text-align: center;
            }
        }
    } 
 

}

/* 600x962 */
@media(max-width:600px) {
    .get-started-section{
        margin-bottom: 60px;
    }

    .big-text p,
    .small-text p{
        text-align: center;
    }

    .get-started-section .big-column .default-started-btn{
        margin: 0 auto;
    }

    .partners-section { 
        margin-bottom: 0px;
    }


    .showcase-section .big-text p {
        font-size: 24px;
    }

    .rounded-white {
        margin-top: 10px;
    }

    .row-images .row-images-group { 
        width: 100%; 
    }

    .row-images .small1 {
        width: 80px;
        left: -5px;
    }

    .info-rows {
        padding-top: 40px;
    }

    .info-row{
        flex-direction: column;
        gap: 60px;
        margin-bottom: 60px;

        .info{
            .number{
                margin: 0 auto;
                width: 38px;
            }

            .text {
                font-size: 24px;
                text-align: center !important;
            }

            .default-btn{
                margin: 0 auto;
            }
        }

        .info-image{
            margin: 0 auto;
            width: 100%;
        }
    }

    .info-row .info-image .small {
        left: -5px;
        bottom: -5px;
        width: 85px;
    }

    .info-row3{
        flex-direction: column-reverse;
    }

    .numbers-stats {
        padding-bottom: 60px;

        .title {
            font-size: 24px;
            margin-bottom: 60px;
        }

        .numbers-content{
            flex-direction: column;
            align-items: center;
            gap:40px;

            .number-container{
                width: 60%;
                .number{
                    text-align: center;
                    font-size: 34px;
                }
                .subtitle, .text{
                    text-align: center;
                }
            }
        }
    }

    .pay-plans{ 
        margin-bottom: 60px;
        
        .title {
            font-size: 24px;
        }

        .subtitle {
            margin-top: 15px;
        }

        .plans-toggle-wrapper {
            width: 55%;
        }

        .plans-wrapper { 
            margin-top: 10px;
        }

        .swiper{
            margin-bottom: 0;
        }
    }

 
    .faq-container {
        width: 90%;
        .title {
            font-size: 34px;
        }
    }

    .question-title-wrapper {
        padding: 14px 10px;
    }

    .answ-active{
        padding: 5px 10px;
    }

    .reviews-container{
        margin : 60px auto;
        .title {
            font-size: 24px;
            margin-bottom: 30px;
        }

        .reviews .review {
            width: 85%;
            margin: 0 auto;
            padding: 40px 35px; 
            .quote-img {
                width: 40px;
                margin-bottom: 26px;
            }
            .text {  
                margin-bottom: 60px;
            }
            .reviewer{
                justify-content: center;
            }
        }

        .reviews-btn{
            margin-top: 10px;
        } 
    }

   
    .default-btn{
        width: 176px;
    }

    .copyright{
        width: 60%;
    }  
}



/* 577-951 */
@media(max-width:577px) {}

/* 540-960 */
@media(max-width:540px) {
    .pay-plans .plans-wrapper .plan {
        width: 75%;
        margin: 0 auto;
    }
}

/*480-800*/
@media (max-width:480px) {
    .faq-container {
        width: 90%;
    }

    nav, .get-started-section, .info-rows, .numbers-stats .numbers-content, .pay-plans .plans-wrapper, .reviews-container, footer .footer-wrapper {
        width: 95%;
    }

    .get-started-section{
        margin-top: 60px;

        .column {
            width: 60%;
        }

        .big-column{
            width: 100% !important;
        }
    }

    .big-text p {
        font-size: 24px;
    }

    .small-text p {
        font-size: 12px;
    }

    .pay-plans{
        .title { 
            font-size: 20px; 
        }

        .subtitle {
            font-size: 12px;
            margin-top: 8px;
        }
    
        .plans-toggle-wrapper {
            width: 60%;
        }
    }

    .reviews-container .title {
        font-size: 22px;
    }

    .info-row .info .text {
        font-size: 22px;
    }

    .showcase-section .small-text p {
        font-size: 12px;
    } 
}

/*438-1024*/
@media(max-width:438px) {
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
    .numbers-stats .numbers-content .number-container {
        width: 75%;
    }
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
    .showcase-section {
        padding-top: 10px;
    }

    .pay-plans .title {
        padding-top: 20px;
    }
}

/*414-736 617   */
@media (max-width:414px) {
    .copyright{
        width: 65%;
    }

    footer .footer-bottom .terms {
        gap: 30px;
    }

    .pay-plans{ 
        .plans-toggle-wrapper {
            width: 65%;
            .plan-mode p {
                font-size: 12px;
            }
        }
    } 
}

/*390 x 844*/
@media(max-width:393px) {
}

/*384-640*/
@media(max-width:384px) {
}

/*375*/
@media(max-width:375px) { 
    .copyright{
        width: 70%;
    }
}

/*360x640*/
@media(max-width:360px) {
}

/*320x568*/
@media(max-width:320px) {
}
</style>
