<template>
    <div data-aos="fade-left" class="modal-wrapper">
        <div class="modal modal-sm">
            <div class="modal-title modal-title-lg">
               <p> Log dich in dein Konto ein</p>
            </div>

            <div class="input-wrapper">
                <input :class="{'input-err': loginEmailErr}" v-model="loginEmail" type="email" placeholder="E-mail">
                <div class="err-icon" v-if="loginEmailErr">
                    <img src="../../assets/icons/incorrect_icon.svg"/>
                </div>
              
                <div class="err-msg" v-if="loginEmailErr">
                    <p v-for="error in v$.loginEmail.$errors" :key="error.$uid" >
                        {{error.$message}}
                    </p>
                </div>
            </div>

            <div class="input-wrapper">
                <input :class="{'input-err': loginPasswordErr}" v-model="loginPassword" type="password" placeholder="Passwort">
                <div class="err-icon" v-if="loginPasswordErr">
                    <img src="../../assets/icons/incorrect_icon.svg"/>
                </div>
                <div class="err-msg" v-if="loginPasswordErr">
                    <p v-for="error in v$.loginPassword.$errors" :key="error.$uid" >
                        {{error.$message}}
                    </p> 
                </div>
            </div>

            <div class="login-modal-btns">

                <button v-if="loadingBtn" class="modal-btn loading-btn">
                    <div class="loader"></div>
                </button>
                <button v-else class="modal-btn" @click="loginUser()">
                    <p>Einloggen</p>
                </button>

                <div class="other-login-btns"> 
                    <div class="input-wrapper terms-wrapper">
                        <input type="checkbox" name="checkb" id="checkb-" class="custom-checkbox" />

                        <label for="checkb-" class="custom-label">
                            <span>Angemeldet bleiben</span>
                        </label>
                    </div>

                    <div class="forgot-password">
                        <a>Passwort vergessen?</a>
                    </div>
                </div>
            </div>

           <div class="modal-footer">
                <div class="cookies-msg modal-msg modal-msg-sm modal-msg-blue">
                    <p>Indem du fortfährst, stimmst du unseren <b>Nutzungsbedingungen</b> zu und bestätigst, dass du unsere <b>Datenschutz- und Cookie-Richtlinie</b> gelesen hast.</p>
                </div>
           

                <router-link to="/register"  class="register-msg modal-msg modal-msg-pink">
                    <p>Ich habe kein Konto </p>
                </router-link>
                
            </div>

         
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
//import Swal from "sweetalert2";
import useValidate from '@vuelidate/core';
import {
    required,
    helpers,
    email,
} from "@vuelidate/validators";
export default {
    watch: {
        "loginEmail"(value) {
            if (value) {
                this.loginEmailErr = false;
            } else {
                this.loginEmailErr = true;
            }
        },

        "loginPassword"(value) {
            if (value) {
                this.loginPasswordErr = false;
            } else {
                this.loginPasswordErr = true;
            }
        },
    },
    data() {
        return {
            v$: useValidate(),

            loginEmail: 'benbytyqi@gmail.com',
            loginEmailErr: false,

            loginPassword: 'password',
            loginPasswordErr: false,

            loadingBtn: false,
        }
    },
    validations() {
        return {
            loginEmail: {
                required: helpers.withMessage('E-Mail ist erforderlich', required),
                email
            },

            loginPassword: {
                required: helpers.withMessage('Passwort erforderlich ist', required)
            },
        }
    },
    computed: { 
        ...mapGetters('auth', ['authError']),
    },
    methods:{
        ...mapActions('auth', ['logIn']),

        async loginUser() {
            this.loadingBtn = true;

            this.v$.loginEmail.$touch();
            this.v$.loginPassword.$touch();

            this.loginEmailErr = this.v$.loginEmail.$invalid;
            this.loginPasswordErr = this.v$.loginPassword.$invalid;

            if (!this.loginEmailErr && !this.loginPasswordErr) {  
                try {
                    await this.logIn({
                        email: this.loginEmail,
                        password: this.loginPassword,
                    });
            
                    this.$router.push("/dashboard");   
                    this.loadingBtn = false;
                } catch (error) {  
                    // Swal.fire("Beim Einloggen ist ein Fehler aufgetreten!", this.authError || "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.", "warning").then(() => { 
                    //     this.loadingBtn = false;
                    // }); 

                    
                    this.$store.commit("application/setErrorProcessModal", true); 
                    this.$store.commit("application/setErrorProcessTitle", 'Beim Einloggen ist ein Fehler aufgetreten!'); 
                    this.$store.commit("application/setErrorProcessText", this.authError || "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."); 
                    this.loadingBtn = false;
                } 
            }else{ 
                this.loadingBtn = false;
            } 
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/modals.scss";  

.cookies-msg, .register-msg{
    text-align: left;
}

.modal-title{
    margin-bottom: 52px;
}

.forgot-password{
    a{
        cursor: pointer;
        color: #56597A;
        font-size: 16px;
        text-decoration: underline;
    }
}

    .login-modal-btns{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 45px;
    } 

    .modal-btn{
        margin: initial;
    }

    .terms-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }

.custom-checkbox {
    display: none;
}

.custom-checkbox:checked {
    color: #C0CCC2;
}

.custom-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    span {
        font-size: 18px;
        color: #395474;
    }

    .terms-color{
        color: #FF3A67;
    }

    &:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid #FF3A67;
        border-radius: 5px;
        margin-right: 5px;
        transition: 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &::after {
        content: "";
        display: none;
        transform: rotate(45deg);
        height: 7px;
        width: 3px;
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        position: absolute;
        left: 7px;
        top: 8px;
    }
}

.custom-checkbox:checked+.custom-label:before {
    background-color: #FF3A67;
}

.custom-checkbox:checked+.custom-label:after {
    display: inline-block;
}


/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
   
   .custom-label{
 
       &::before { 
           width: 18px;
           height: 18px;  
           margin-right: 10px;
       }

       &::after { 
           height: 8px;
           width: 4px; 
           left: 7px;
           top: 7px;
       }
   }
   
   
}

/* 2304-1470 */
@media (min-width: 2304px) {

}

/* 2470 - 1328 */
@media (min-width: 2470px) {
    .forgot-password a { 
        font-size: 18px;
    }

   .custom-label{
       span {
           font-size: 20px; 
       }

       &::before { 
           width: 22px;
           height: 22px;  
           margin-right: 15px;
       }

       &::after { 
           height: 9px;
           width: 5px; 
           left: 9px;
           top: 8px;
       }
   }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
 
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .forgot-password a { 
        font-size: 20px;
    }

   .custom-label{
       span {
           font-size: 22px; 
       }

       &::before { 
           width: 24px;
           height: 24px;  
           margin-right: 15px;
       }

       &::after { 
           height: 10px;
           width: 5px;
           left: 9px;
           top: 9px;
           border-bottom: 3px solid white;
           border-right: 3px solid white;
       } 
   }
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {

}

/*3360-1890 1695*/
@media(min-width:3360px) {
    .forgot-password a { 
        font-size: 22px;
    }

   .custom-label{
       span {
           font-size: 24px; 
       }

       &::before { 
           width: 26px;
           height: 26px;  
           margin-right: 15px;
       }

       &::after {  
           left: 10px; 
       } 
   }
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){

    .forgot-password a { 
        font-size: 26px;
    }

   .custom-label{
       span {
           font-size: 28px; 
       }

       &::before { 
           width: 28px;
           height: 28px;  
           margin-right: 20px;
       }

       &::after {   
           height: 12px;
           width: 7px; 
           left: 10px; 
           top: 10px;
       } 
   }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) {
    .forgot-password a { 
        font-size: 14px;
    }
    
   .custom-label{
        span {
           font-size: 16px; 
        }

        &::before { 
            width: 14px;
            height: 14px;  
        }
       
        &::after { 
            left: 6px;
            top: 6px;
        }
   } 
    .terms-wrapper { 
        margin-bottom: 5px;
    }
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {

}

/*1536-864*/
@media(max-width:1536px) {
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .forgot-password a { 
        font-size: 12px;
    }
    
   .custom-label{
        span {
           font-size: 14px; 
        }

        &::before { 
            width: 12px;
            height: 12px;  
        }
       
        &::after { 
            left: 5px;
            top: 5px;
        }
   } 
  
}

/*1366-638*/
@media (max-width:1366px) {
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .terms-wrapper { 
        margin-bottom: 0px;
    }
}

/* 1200-1920 */
@media (max-width:1200px) {
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
    .modal-title {
        margin-bottom: 40px;
    }


}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {

}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
    .forgot-password a { 
        font-size: 10px;
    }
    
   .custom-label{
        span {
           font-size: 12px; 
        } 
       
        &::after {  
            top: 4px;
        }
   } 

   .login-modal-btns{
        margin-bottom: 30px;
    }

    .cookies-msg{
        margin-bottom: 10px;
    }
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {

}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
  

   .custom-label {
       span {
           font-size: 12px;
       }  

       &::after {  
           top: 4px;
       }
   } 

    .modal-btn{
        width: 168px;
    }
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {

}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .modal-btn{
        width: 150px;
    }
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {
    .login-modal-btns{
        flex-direction: column;
        gap: 25px;
    }

    .forgot-password{
        margin-top: 10px;
        text-align: center;

        a{
            font-size: 12px;
        }
    }

    .custom-label {
       span {
           font-size: 14px;
       }  

       &::after {  
           top: 5px;
       }
   } 

   .modal-btn{
        width: 190px;
    }
}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {     
    .forgot-password{   
        a{
            font-size: 10px;
        }
    }

    .custom-label {
       span {
           font-size: 12px;
       }  

       &::after {  
           top: 4px;
       }
   } 
    
   .modal-btn{
        width: 160px;
    }

}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>