<template>
<div id="app">
    <router-view />

    <SuccessSignature v-if="successSignatureModal"/>
    <ErrorProcess v-if="errorProcessModal"/>
</div>
</template>

<script>
import SuccessSignature from './components/dashboard/SuccessSignature.vue';
import ErrorProcess from './components/dashboard/ErrorProcess.vue';
export default {
    mounted() {
        // if (this.$store.getters['darkMode/isDarkMode']) {
        //   document.body.classList.add('dark-mode');
        // }

        const isDarkMode = this.$store.getters['darkMode/isDarkMode'];
        document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    },
    components:{
        SuccessSignature,
        ErrorProcess
    }
};
</script>

<style lang="scss">
@import './assets/styles.css';
@import 'swiper/css';
@import 'swiper/css/pagination';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html { scroll-behavior: smooth; }

.loader {
    width: 25px;
    height: 25px;
    border: 3px solid white;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loading-btn{
    border: 1px solid #FF3A67 !important;
    background-color: #FF3A67 !important;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.swiper-pagination {
    display: none !important;
}

.menu-link-wrapper {
    margin-bottom: 55px;
    display: flex;
    align-items: center;
    gap: 26px;
    cursor: pointer;

    a {
        color: #3C3C3C;
        font-size: 16px;
        font-weight: 500;
        transition: 0.3s ease;
    }

    .menu-link-img {
        width: 30px;
        height: 30px;

        img {
            width: 100%;
        }
    }

    &:hover {
        a {
            color: #FF3A67;
        }

        .menu-link-img {
            img {
                filter: brightness(0) saturate(100%) invert(36%) sepia(37%) saturate(5316%) hue-rotate(327deg) brightness(105%) contrast(101%);
            }
        }
    }
}

@media (min-width: 2470px) {
    .menu-link-wrapper a {
        font-size: 18px;
    }

    .menu-link-wrapper .menu-link-img {
        width: 35px;
        height: 35px;
    }
}

@media(min-width: 2880px) {
    .menu-link-wrapper a {
        font-size: 20px;
    }

    .menu-link-wrapper .menu-link-img {
        width: 40px;
        height: 40px;
    }
}

@media(min-width: 3200px) {
    .menu-link-wrapper a {
        font-size: 22px;
    }

    .menu-link-wrapper .menu-link-img {
        width: 45px;
        height: 45px;
    }
}

@media(min-width:3840px) {
    .menu-link-wrapper a {
        font-size: 26px;
    }

    .menu-link-wrapper .menu-link-img {
        width: 55px;
        height: 55px;
    }
}

@media(max-width: 1680px) { 
    .menu-link-wrapper {
        margin-bottom: 50px;
        gap: 20px;

        .menu-link-img {
            width: 25px;
            height: 25px;
        }

        a {
            font-size: 14px;
        }
    }

}

@media(max-width:1440px) { 
    .menu-link-wrapper {
        gap: 10px;
    }
}

@media (max-width:1200px) { 
    .menu-link-wrapper {
        margin-bottom: 40px;
    }
}

@media (max-width:1024px) {
    .menu-link-wrapper a {
        font-size: 12px;
    }

    .menu-link-wrapper .menu-link-img {
        width: 22px;
        height: 22px;
    }
}

@media(max-width: 768px) {
    .swiper {
        padding-bottom: 50px !important;
    }

    .swiper-pagination {
        display: initial !important;
    }

    .swiper-pagination-bullet-active {
        background: #FF3A67 !important;
    }

    .swiper-pagination-bullet {
        background: hsla(346, 100%, 61%, 1) !important;
    }

    .swiper-pagination-bullet {
        width: 12px !important;
        height: 12px !important;
    }

    
    .mobile-menu {
        width: 250px;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        height: 100%;
        transform: translateX(-250px);
        transition: 0.3s ease;
        padding-left: 20px;
        padding-top: 25px;
    }

    .mobile-menu-opened {
        transform: translateX(0);
    }

    .menu-btn {
        height: 30px;
        width: 30px;
        cursor: pointer;

        img {
            width: 100%;
        }
    }

    .close-menu-btn {
        height: 25px;
        width: 25px;
        margin-bottom: 94px;
    }

    .menu-link-wrapper .menu-link-img {
        width: 25px;
        height: 25px;
    }

    .menu-link-wrapper a {
        font-size: 14px;
    }
}

@media(max-width: 600px) {
    .partners-section {
        .swiper {
            .swiper-wrapper {
                margin-left: -50px !important;
            }
        }
    }
}

@media(max-width: 393px) {
    .mobile-menu {
        width: 215px;
        padding-left: 15px;
        padding-top: 20px;
    }

    .menu-btn {
        height: 25px;
        width: 25px;
    }

    .close-menu-btn {
        height: 20px;
        width: 20px;
        margin-bottom: 70px;
    }

    .menu-link-wrapper {
        margin-bottom: 35px;

        .menu-link-img {
            width: 22px;
            height: 22px;
        }

        a {
            font-size: 13px;
        }
    }

}

@media(max-width: 360px) {
    .mobile-menu {
        width: 200px;
        padding-left: 10px;
        padding-top: 15px;
    }

    .close-menu-btn {
        margin-bottom: 50px;
    }

    .menu-link-wrapper {
        margin-bottom: 30px;

        .menu-link-img {
            width: 20px;
            height: 20px;
        }

        a {
            font-size: 12px;
        }

    }
}
</style>
