<template>
<div class="modal-wrapper colored-modal-wrapper user-identity">

    <div class="modal modal-lg" data-aos="fade-down">
        <div class="close-modal-btn" @click="closeModal()">
            <img src="../../assets/icons/close_icon.svg" />
        </div>

        <div class="signature-about">
            <div class="modal-title modal-title-sm">
                <p>Bevor Du fortfährst…</p>
            </div>
            <div class="signature-about-content">
                <p>
                    Autorisierungsformular<br> 
                    {{formattedDate}} Uhr 
                    Wohnhaft in 
                    <span v-if="userInfo && userInfo.address">
                        {{userInfo.address}}
                    </span> 
                    <span v-else>
                        [Adresse]
                    </span>
                    <br> 

                    E-Mail-Adresse:
                    <span v-if="userInfo && userInfo.email">
                        {{userInfo.email}}
                    </span> 
                    <span v-else>
                        [E-Mail]
                    </span> 
                </p>

                <p ref="signatureText" :style="maxHeightStyle">
                    Ich bevollmächtige CleanData , als meinen autorisierten Vertreter<br>
                    zu handeln und alle notwendigen rechtlichen Schritte in meinem <br>
                    Namen durchzuführen, um meine Datenschutzrechte gemäß den <br>
                    geltenden Datenschutzgesetzen durchzusetzen. <br><br>
                    Dies umfasst insbesondere: <br>
                    ● Für den DACH-Raum: Die Geltendmachung meiner Rechte gemäß der Datenschutz-Grundverordnung (DSGVO) , einschließlich des Rechts auf Auskunft, Berichtigung, Einschränkung der Verarbeitung, Datenübertragbarkeit und Löschung meiner personenbezogenen Daten. <br>
                    ● Für die USA (Kalifornien): Die Durchsetzung meiner Rechte gemäß dem California Consumer Privacy Act (CCPA) und dem California Privacy Rights Act (CPRA) , insbesondere das Recht auf Löschung, das Recht auf Einsicht und das Recht, der Weitergabe meiner Daten zu widersprechen. <br>
                    ● Die Kommunikation mit Unternehmen und Organisationen, um sicherzustellen, dass meine personenbezogenen Daten im Einklang mit den gesetzlichen Vorgaben verarbeitet oder gelöscht werden. <br>
                    ● Die Einreichung und Nachverfolgung von Anfragen zur Datenlöschung oder -einsicht sowie die Prüfung von Unternehmensantworten auf deren Rechtmäßigkeit und Vollständigkeit. Ich bestätige, dass CleanData in meinem Namen tätig werden darf, um meine Datenschutzrechte durchzusetzen. Ich verstehe, dass CleanData meine Identität verifizieren kann, um sicherzustellen, dass die Anfragen legitim sind, und dass die Verarbeitung meiner Daten im Rahmen der Datenschutzrichtlinien von CleanData erfolgt.
                </p>
            </div>

            <div class="show-more">
                <a @click="showMoreInfo = !showMoreInfo">
                    {{ showMoreInfo ? 'Weniger anzeigen' : 'Mehr anzeigen' }}
                </a>
            </div>
        </div>

        <div class="signature-input-wrapper">
            <div class="signature-input-title">
                <p>Bitte unterschreibe hier</p>

                <div class="signature-animation">
                    <Vue3Lottie :animationData="signatureAnimation" />
                </div>
            </div>
            <div class="signature-wrapper">
                <VueSignaturePad v-if="!signatureData" ref="signaturePad" class="signature-input" :options="{ onBegin, onEnd }" />

                <div class="signature-input" style="cursor: not-allowed;" v-else>
                    <img :src="signatureData" />
                </div>

                <div class="err-msg" v-if="signatureErr">
                    <p>Die Unterschrift ist erforderlich</p>
                </div>
            </div>

            <div class="signature-input-btns">
                <button class="modal-btn modal-btn-white modal-btn-sm" v-if="hasSignature" @click="undoSignature();">
                    <p>Undo</p>
                </button>

                <button @click="validateSignature();" class="modal-btn modal-btn-sm">
                    <p>Weiter</p>
                </button>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import signatureAnimation from '../../assets/animations/signature-animation.json'
import axiosInstance from "@/axios";
//import Swal from 'sweetalert2';
export default {
    watch: {

    },
    data() {
        return {
            hasSignature: false,

            signatureData: null,

            showMoreInfo: false,

            fullHeight: 0,

            signatureAnimation,

            signatureErr: false,

            userInfo:{  
                email: '',
                address: '', 
            },
        }
    },
    async mounted() { 
        await this.getProfilelData();
        this.userInfo = this.userPersonalData;   

        if(this.userInfo.showSignatureUploadWindow){
            this.fullHeight = this.$refs.signatureText.scrollHeight;
        }else{ 
            this.$store.commit("application/setAddDocumentsModal", false); 
        }
    },
    computed: {
        formattedDate() {
         return this.formatDate(new Date());
        },  

        maxHeightStyle() {
            return {
                maxHeight: this.showMoreInfo ? `${this.fullHeight}px` : '15em',
                transition: 'max-height 0.3s ease',
                overflow: 'hidden',
            };
        },
    },
    methods: {
        formatDate(date) {
            return `Datum: ${new Intl.DateTimeFormat('de-CH', { 
                day: '2-digit', month: 'long', year: 'numeric', 
                hour: '2-digit', minute: '2-digit', hour12: false 
            }).format(date).replace(',', ' um')}`;
        },

        closeModal() {
            this.$store.commit("application/setAddSignatureModal", false);
        },

        onBegin() {
            this.hasSignature = true;
            this.signatureErr = false;
        },

        onEnd() {
            this.hasSignature = true;
            this.signatureErr = false;
        },

        undoSignature() {
            if (this.signatureData) {
                this.signatureData = !this.signatureData;
            } else {
                this.$refs.signaturePad.undoSignature();
            }

            this.hasSignature = false;
        },

        base64ToFile(base64String, filename) {
            return new Promise((resolve, reject) => {
                fetch(base64String)
                    .then(res => res.blob())
                    .then(blob => {
                        const file = new File([blob], filename, {
                            type: "image/png"
                        });
                        resolve(file);
                    })
                    .catch(error => reject(error));
            });
        },

        async validateSignature() {
            try {
                this.signatureErr = false;
                const {data} = this.$refs.signaturePad.saveSignature();

                const file = await this.base64ToFile(data, "signature.png");
              
                if (!(file instanceof File)) {
                    throw new Error("Invalid file type. Expected a File object.");
                }

                const formData = new FormData();
                formData.append("signature", file); 

                await axiosInstance({ requiresAuth: true }).post(
                    "/customer/signature/reVerify",
                    formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                // Swal.fire({
                //     title: 'Wir verifizieren nun deine Unterschrift!',
                //     text: '',
                //     icon: 'success'
                // }).then(()=>{
                //     this.$store.commit("application/setAddSignatureModal", false); 
                //     this.checkUserIdentity();
                // });

                this.$store.commit("application/setAddSignatureModal", false); 
                this.$store.commit("application/setSuccessSignatureModal", true); 
                this.$store.commit("application/setSuccessSignatureTitle", 'Erfolgreich gesendet'); 
                this.$store.commit("application/setSuccessSignatureText", 'Wir verifizieren nun deine Unterschrift'); 
                this.checkUserIdentity();

            } catch (error) {
                // Swal.fire({
                //     title: 'Fehler bei der Verarbeitung der Signatur.',
                //     text: error.response.data,
                //     icon: 'error', 
                // }).then(()=>{
                //     this.$store.commit("application/setAddSignatureModal", false); 
                //     this.checkUserIdentity();
                // })

                console.log(error)

                this.$store.commit("application/setAddSignatureModal", false);  
                this.$store.commit("application/setErrorProcessModal", true); 
                this.$store.commit("application/setErrorProcessTitle", 'Fehler bei der Verarbeitung der Signatur.'); 
                this.$store.commit("application/setErrorProcessText", error.response.data.message); 
                this.checkUserIdentity();
            }
        }

    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/modals.scss";

.close-modal-btn {
    cursor: pointer;
}

.user-identity {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.images-err-msg {
    margin-bottom: 25px;
    text-align: center;
}

.signature-animation {
    width: 205px;
    height: 170px;
    margin-top: -50px;
    transform: rotate(15deg);
}

.modal-lg {
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
}

.signature-about {
    width: 45%;
}

.signature-input-wrapper {
    padding-top: 15px;
    width: 49%;
}

.modal-title {
    text-align: left;
    margin-bottom: 11px;
}

.signature-about-content {
    margin-bottom: 24px;

    p {
        color: #666666;
        font-size: 14px;
        transition: max-height 0.5s ease;
        overflow: hidden;

        &:first-child {
            margin-bottom: 30px;
        }
    }
}

.show-more {
    a {
        cursor: pointer;
        color: #FF3A67;
        font-size: 14px;
    }
}

.signature-input-title {
    p {
        color: #000000;
        font-size: 20px;
    }

    margin-bottom: 15px;

    display: flex;
    align-items: center;
    margin-bottom: -30px
}

.signature-wrapper {
    margin-bottom: 24px;
}

.signature-input {
    border: 1px solid #707070;
    border-radius: 20px;

    height: 281px !important;
}

.signature-input-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .modal-btn {
        margin: initial;
    }
}

/////
/// 
/// 
/// 
.modal-lg {
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
}

.identity-about {
    width: 45%;
}

.identity-inputs {
    padding-top: 15px;
    width: 49%;
}

.modal-title {
    text-align: left;
    margin-bottom: 11px;
}

.identity-about-content {
    margin-bottom: 24px;

    p {
        color: #666666;
        font-size: 14px;
        transition: max-height 0.5s ease;
        overflow: hidden;

        &:first-child {
            margin-bottom: 30px;
        }
    }
}

.show-more {
    a {
        cursor: pointer;
        color: #FF3A67;
        font-size: 14px;
    }
}

.identity-input-title {
    p {
        color: #000000;
        font-size: 20px;
    }

    margin-bottom: 15px;
}

.identity-animation {
    width: 213px;
    height: 213px;
    margin: 0 auto;
}

.identity-input-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;

    .identity-input-wrapper {
        width: 45%;

        .identity-input {
            width: 100%;
            border: 1px dashed #707070;
            border-radius: 20px;
            padding: 8px 0;
            text-align: center;
            cursor: pointer;

            p {
                font-size: 12px;
                color: #000000;
                margin-top: 3px;
            }
        }

        .identity-card-img {
            width: 38px;
            height: 39px;
            margin: 0 auto;

            img {
                width: 100%;
            }
        }

        .image-input-info {
            margin-top: 10px;
            width: 100%;

            p {
                color: #FA3A67;
                font-size: 12px;
            }
        }
    }
}

.identity-input-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .modal-btn {
        margin: initial;
    }
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {}

/* 2304-1470 */
@media (min-width: 2304px) {}

/* 2470 - 1328 */
@media (min-width: 2470px) {}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {

    .signature-input-title p,
    .identity-input-title p {
        font-size: 22px;
    }

    .identity-input-row {
        margin-bottom: 50px;

        .identity-input-wrapper {

            .identity-input {
                border: 2px dashed #707070;
                padding: 10px 0;

                p {
                    font-size: 14px;
                    margin-top: 5px;
                }

                .identity-card-img {
                    width: 48px;
                    height: 49px;
                }
            }

            .image-input-info p {
                font-size: 14px;
            }
        }
    }
}

/*2573-1206*/
@media(min-width: 2573px) {}

/* 2880-1670 */
@media(min-width: 2880px) {

    .signature-input-title p,
    .identity-input-title p {
        font-size: 24px;
    }

    .signature-animation {
        width: 215px;
        height: 180px;
        margin-top: -55px;
    }

    .signature-about-content p,
    .show-more a {
        font-size: 16px;
    }

    .identity-animation {
        width: 225px;
        height: 225px;
    }

    .identity-input-row {
        margin-bottom: 60px;

        .identity-input-wrapper {

            .identity-input {
                border: 3px dashed #707070;
                padding: 12px 0;

                p {
                    font-size: 16px;
                    margin-top: 8px;
                }

                .identity-card-img {
                    width: 58px;
                    height: 59px;
                }
            }

            .image-input-info p {
                font-size: 16px;
            }
        }
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {}

/*3200-1800*/
@media(min-width: 3200px) {

    .signature-input-title p,
    .identity-input-title p {
        font-size: 26px;
    }

    .signature-animation {
        width: 225px;
        height: 190px;
        margin-top: -60px;
    }

    .identity-animation {
        width: 235px;
        height: 235px;
    }

    .identity-input-row {
        margin-bottom: 70px;

        .identity-input-wrapper {

            .identity-input {
                padding: 15px 0;

                p {
                    font-size: 18px;
                    margin-top: 10px;
                }

                .identity-card-img {
                    width: 68px;
                    height: 69px;
                }
            }

            .image-input-info p {
                font-size: 18px;
            }
        }
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {

    .signature-about-content p,
    .show-more a {
        font-size: 18px;
    }
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px) {

    .signature-input-title p,
    .identity-input-title p {
        font-size: 30px;
    }

    .signature-animation {
        width: 235px;
        height: 200px;
        margin-top: -65px;
    }

    .signature-about-content p,
    .show-more a {
        font-size: 20px;
    }

    .identity-animation {
        width: 260px;
        height: 260px;
    }

    .identity-input-row {
        margin-bottom: 80px;

        .identity-input-wrapper {

            .identity-input {
                padding: 18px 0;
                border: 4px dashed #707070;

                p {
                    font-size: 20px;
                    margin-top: 15px;
                }

                .identity-card-img {
                    width: 78px;
                    height: 79px;
                }
            }

            .image-input-info p {
                font-size: 20px;
            }
        }
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {

    .modal {
        width: 1056px;
    }

    .signature-input-title {
        margin-bottom: -20px;
    }

    .signature-animation {
        width: 175px;
        height: 140px;
    }
}

/*1688-1080*/
@media(max-width: 1688px) {}

/*1680-1080*/
@media(max-width: 1680px) {}

/*1640-1080*/
@media(max-width: 1640px) {}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .signature-animation {
        width: 155px;
        height: 120px;
        margin-top: -40px;
    }

    .signature-input-title {
        margin-bottom: -10px;
    }

    .identity-animation {
        width: 200px;
        height: 200px;
    }
}

/*1536-864*/
@media(max-width:1536px) {}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {}

/*1366-638*/
@media (max-width:1366px) {

    .signature-input-title p,
    .identity-input-title p {
        font-size: 18px;
    }

    .signature-animation {
        width: 135px;
        height: 100px;
        margin-top: -30px;
    }

    .signature-input-title {
        margin-bottom: 0px;
    }

    .identity-animation {
        width: 190px;
        height: 190px;
    }
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {}

/* 1200-1920 */
@media (max-width:1200px) {}

/*1134x712*/
@media(max-width:1134px) {}

/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
    .modal {
        width: 95%;
        padding: 40px 40px;
    }
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
    .signature-input-title p {
        font-size: 16px;
    }

    .signature-animation {
        width: 115px;
        height: 100px;
        margin-top: -30px;
    }

    .signature-about-content p,
    .show-more a {
        font-size: 12px;
    }

    .identity-animation {
        width: 180px;
        height: 180px;
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {}

/* 962x601 */
@media(max-width: 962px) {}

/* 900x1600 */
@media(max-width: 900px) {}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
    .modal {
        width: 95%;
        padding: 35px 30px;
    }

    .signature-animation {
        width: 100px;
        height: 100px;
    }

    .identity-animation {
        width: 170px;
        height: 170px;
    }
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
    .signature-animation {
        width: 90px;
        height: 90px;
    }

    .modal {
        padding: 35px 20px;
    }
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {}

/*712-1138*/
@media(max-width:712px) {}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
    .modal {
        width: 90%;
        flex-direction: column;
        margin: 50px auto;
    }

    .signature-about,
    .signature-input-wrapper,
    .identity-inputs {
        width: 100%
    }

    .signature-input-title {
        margin-bottom: -20px;
        justify-content: space-between;
    }

    .signature-input-title p {
        font-size: 18px;
    }

    .signature-animation {
        width: 160px;
        height: 160px;
    }

    .modal-btn-sm {
        width: 168px;
        padding: 12px 0;

        p {
            font-size: 14px;
        }
    }

    .identity-animation {
        width: 160px;
        height: 160px;
    }
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {}

/* 600x962 */
@media(max-width:600px) {}

/* 577-951 */
@media(max-width:577px) {}

/* 540-960 */
@media(max-width:540px) {}

/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
    .signature-input-title p {
        font-size: 14px;
    }

    .signature-animation {
        width: 140px;
        height: 140px;
    }

    .identity-animation {
        width: 150px;
        height: 150px;
    }

    .identity-input-title p {
        font-size: 16px;
    }
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px) {}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px) {
    .signature-animation {
        width: 120px;
        height: 120px;
    }

    .identity-input-title p {
        font-size: 14px;
    }

    .identity-input-row {
        .identity-input-wrapper {
            .identity-input {
                padding: 12px 0;
            }

            .image-input-info p {
                font-size: 10px;
            }
        }
    }
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .signature-input-title {
        margin-bottom: -10px;
    }

    .signature-animation {
        width: 100px;
        height: 100px;
    }

    .modal-btn-sm {
        width: 140px;
    }
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) {}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
    .signature-animation {
        width: 90px;
        height: 90px;
    }

    .signature-input-title {
        margin-bottom: 0px;
    }

    .modal-btn-sm {
        width: 120px;
    }

    .identity-animation {
        width: 140px;
        height: 140px;
    }
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
    .signature-animation {
        width: 70px;
        height: 70px;
    }

    .signature-input-title {
        margin-bottom: 10px;
    }

    .modal-btn-sm {
        width: 110px;
    }
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
    .signature-animation {
        width: 90px;
        height: 90px;
    }

    .signature-input-title {
        margin-bottom: 0px;
    }
}
</style>
