import { createStore } from 'vuex';

import application from './modules/application';

import auth from './modules/auth';

import darkMode from './modules/darkMode';

import signature from './modules/signature';

import payment from './modules/payment';

import tickets from './modules/tickets';

const store = createStore({
  modules: {
    application,
    auth,
    darkMode,
    signature,
    payment,
    tickets,
  }
});

export default store;