import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', [
      'loggedIn',
      'currentUser', 
      'authError', 
      'documentsWereRejected', 
      'signtureWasRejected', 
      'userPersonalData', 
      'verificationEmail', 
      'registerPsw', 
      'confirmRegisterPsw', 
      'expireVar', 
      'signatureVar', 
      'idVar', 
      'hashVar'
    ]),

    ...mapGetters('tickets', [
      'ticketsData', 
      'ticketsError'
    ]),

    ...mapGetters('darkMode', ['isDarkMode']),

    ...mapGetters('application', [
      'addSignatureModal', 
      'addDocumentsModal', 
      'failedVerifyModal', 
      'documentsVerifyingModal', 
      'getStartedModal', 
      'searchDataModal', 
      'lookingForModal', 
      'congratsModal', 

      'errorProcessModal', 
      'errorProcessTitle',
      'errorProcessText',

      'successSignatureModal',
      'successSignatureTitle',
      'successSignatureText',
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'logIn',
      'registerStep1', 
      'registerStep2', 
      'logOut', 
      'requestCleanData', 
      'checkUserIdentity', 
      'getProfilelData'
    ]),
    
    ...mapActions('tickets', ['getTicketsData']),

    ...mapActions('darkMode', ['initializeDarkMode', 'toggleDarkMode']),

    //...mapActions('application', ['']),
  }
};
