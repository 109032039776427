<template>
<div class="dsb-router-wrapper">
    <div class="dsb-router-header">
        <div class="dsb-title dsb-title-lg">
            <p>Wo sind Ihre Daten?</p>
        </div>
    </div>
    <Loader v-if="loading" />
    <div class="details-table" v-else>
        <div class="header">
            <div class="header-el el-sm"></div>
            <div class="header-el el-md">
                <p>Unternehmen</p>
            </div>
            <div class="header-el el-md">
                <p>Problem</p>
            </div>
            <div class="header-el el-sm">
                <p>Risiko</p>
            </div>
            <div class="header-el el-md">
                <p>Anfrage gesendet</p>
            </div>
            <div class="header-el el-lg">
                <p>Status</p>
            </div>
            <div class="header-el el-sm"></div>
        </div>

        <div class="body">
            <div v-for="request in ticktes" :key="request.id" class="row">
                <div class="row-wrapper">
                    <div class="row-el el-sm expand-btn" @click="toggleRow(request.id)">
                        <img v-if="expandedRows === request.id" src="../../assets/icons/up.svg" />
                        <img v-else src="../../assets/icons/down.svg" />
                    </div>
                    <div class="row-el el-md">
                        <div class="pursue">
                            <p>{{request.company.name}}</p>
                        </div>
                    </div>
                    <div class="row-el el-md">
                        <div class="issue">
                            <!-- <p>{{request.title}}</p> -->
                            -----
                        </div>
                    </div>
                    <div class="row-el el-sm">
                        <div class="risk-icon">
                            <img :src="getRiskIcon(request.company.riskCount)" alt="Risk Icon" />
                        </div>
                    </div>
                    <div class="row-el el-md">
                        <div class="request">
                            <p>{{request.commentsCount}}</p>
                        </div>
                    </div>
                    <div class="row-el el-lg ">
                        <div :class="['status', getStatusClass(request.status.id)]">
                            <p>{{ request.status.name }}</p>
                        </div>
                    </div>
                    <div class="row-el el-sm">...</div>
                </div>

                <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                    <div v-if="expandedRows === request.id" class="row-expanded">
                        <div class="row-expanded-content">
                            <div class="content-wrapper">
                                <div class="dsb-title dsb-title-ss">
                                    <p>Anforderungsstatus</p>
                                </div>
                            </div>

                            <div class="progress-bar-container">
                                <div class="progress-bar">

                                    <div class="progress-fill" :class="'progress-fill-'+request.status.id">
                                        <div class="progress-ball"></div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="statuses-list">
                                <div class="status status1">
                                    <p v-if="request.status.id == 1 || request.status.id > 1">Unbestätigt</p>
                                    <span v-else></span>
                                </div>
                                <div class="status status2">
                                    <p v-if="request.status.id == 2 || request.status.id > 2">Angefragt</p>
                                    <span v-else></span>
                                </div>
                                <div class="status status3">
                                    <p v-if="request.status.id == 3 || request.status.id > 3">Antwort</p>
                                    <span v-else></span>
                                </div>
                                <div class="status status4">
                                    <p v-if="request.status.id == 4 || request.status.id > 4">Werden gelöscht</p>
                                    <span v-else></span>
                                </div>
                                <div class="status status5">
                                    <p v-if="request.status.id == 5">Abgeschlossen</p>
                                    <span v-else></span>
                                </div>
                            </div> -->

                            <div class="statuses-list">
                                <div 
                                    v-for="(status, index) in sliderStatuses" 
                                    :key="index" 
                                    :class="'status status' + (index + 1)"
                                >
                                    <p v-if="request.status.id >= status.id">{{ status.label }}</p>
                                    <span v-else></span>
                                </div>
                            </div>


                            <div class="content-wrapper">
                                <div class="dsb-title dsb-title-ss">
                                    <p>Allgemeine Statistiken</p>
                                </div>

                                <div class="request-dates">
                                    <div class="request-sent">
                                        <p>Letzte Anfrage gesendet</p>
                                        <p>{{ request.openDate }}</p>
                                    </div>
                                    <div class="request-completed">
                                        <p>Letzte Anfrage abgeschlossen</p>
                                        <p>{{ request.latestUpdateDate }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="content-wrapper">
                                <div class="dsb-title dsb-title-ss">
                                    <p>Über Bookyourdata</p>
                                </div>

                                <div class="about-data">
                                    <p>{{ request.company.aboutData }}</p>
                                </div>
                            </div>

                            <div class="content-wrapper">
                                <div class="dsb-title dsb-title-ss">
                                    <p>Verbundene Risiken</p>
                                </div>

                                <div class="risks-list">
                                    <div class="risk" v-for="risk in request.company.risks" :key="risk">
                                        <p>{{ risk }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
 
export default {
    components: {
        Loader
    },
    data() {
        return {
            ticktes: {},
            expandedRows: {},

            loading: false,

            sliderStatuses: [
                { id: 1, label: "Unbestätigt" },
                { id: 2, label: "Angefragt" },
                { id: 3, label: "Antwort" },
                { id: 4, label: "Werden gelöscht" },
                { id: 5, label: "Abgeschlossen" },
            ]
        }
    },
    computed: { },
    methods: { 

        toggleRow(id) {
            this.expandedRows = this.expandedRows === id ? null : id;
        },

        beforeEnter(el) {
            el.style.height = '0';
        },
        enter(el) {
            el.style.transition = 'height 0.3s ease-out, opacity 0.3s ease-out';
            el.style.height = el.scrollHeight + 'px';
            el.style.opacity = '1';
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px';
        },
        leave(el) {
            el.style.transition = 'height 0.3s ease-in, opacity 0.2s ease-in';
            el.style.height = '0';
            el.style.opacity = '0';
        },

        getRiskIcon(riskId) {
            switch (riskId) {
                case 1:
                    return require('../../assets/icons/green_emoji.svg');
                case 2:
                    return require('../../assets/icons/yellow_emoji.svg');
                case 3:
                    return require('../../assets/icons/orange_emoji.svg');
                case 4:
                    return require('../../assets/icons/red_emoji.svg');
                default:
                    return require('../../assets/icons/green_emoji.svg');
            }
        },

        getStatusClass(statusId) {
            switch (statusId) {
                case 1:
                    return 'pending-status';
                case 2:
                    return 'answer-status';
                case 3:
                    return 'apply-delete-status';
                case 4:
                    return 'completed-status';
                default:
                    return 'status';
            }
        },
    },
    async mounted() {
        this.loading = true;
        const isVerified = await this.checkUserIdentity();
        if (isVerified) { 
            await this.getTicketsData();
            console.log(this.ticketsData)
            this.ticktes = this.ticketsData.data;
        }
       
        this.loading = false;
    }
}
</script>

<style lang="scss" scoped>
.expand-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 30%;
    }
}

.progress-bar-container {
    width: 100%;
    padding: 10px 0;
}

.progress-bar {
    width: 100%;
    height: 20px;
    background: #D3D3D380;
    border-radius: 50px;
    position: relative;
    box-shadow: 8px 6px 20px #56597A4D;
}

.progress-fill {
    height: 100%;
    width: 0%;
    background: #FF3A67;
    border-radius: 50px;
    transition: width 0.3s ease-in-out;
    position: relative;
}

.progress-ball {
    width: 38px;
    height: 38px;
    background: #FF3A67;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.statuses-list {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .status {
        width: 25%;
        text-align: center;

        p {
            color: #000000;
            font-size: 18px;
        }
    }

    .status1 {
        text-align: left;
    }

    .status5 {
        text-align: right;
    }
}

.progress-fill-1 {
    width: 7%;
}

.progress-fill-2 {
    width: 31%;
}

.progress-fill-3 {
    width: 51%;
}

.progress-fill-4 {
    width: 71%;
}

.progress-fill-4 {
    width: 100%;
}

@import "../../assets/dashboard.scss";

.el-sm {
    flex: 1;
}

.el-md {
    flex: 2;
}

.el-lg {
    flex: 3;
}

.details-table {
    margin-top: 150px;

    .header {
        padding: 0 10px;
        padding-bottom: 30px;

        display: flex;
        justify-content: space-between;

        .header-el {
            text-align: center;

            p {
                color: #000000;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }

    .body {
        .row-wrapper {
            border: 1px solid transparent;
            border-top-color: #7070704d;
            padding: 30px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .row-el {
                text-align: center;
            }
        }

        .row-expanded {
            overflow: hidden;
            opacity: 0;
            transition: opacity 0.3s ease;
            width: 100%;

            .row-expanded-content {
                padding: 30px 4%;
                padding-top: 10px;
                width: 100%;
                border: 1px solid transparent;
                border-top-color: #FA3A67;

                .content-wrapper {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.dsb-title {
    margin-bottom: 15px;
}

.request-dates {
    display: flex;
    gap: 65px;
    align-items: center;

    p {
        font-size: 16px;
        color: #56597A;

        &:first-child {
            margin-bottom: 5px;
        }
    }
}

.about-data {
    p {
        font-size: 16px;
        color: #56597A;
    }
}

.risks-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;

    .risk {
        p {
            color: #FFFFFF;
            font-size: 16px;
        }

        padding: 5px 20px;
        background-color: #3C3C3C65;
        box-shadow: 4px 6px 14px #56597A4D;
        border-radius: 20px;
        margin: initial !important;
        width: auto;

        display: flex;
        align-items: center;
    }
}

/* Inner content padding */

.risk-icon {
    width: 35px;
    height: 35px;
    margin: 0 auto;

    img {
        width: 100%;
    }
}

.pursue,
.issue,
.request {
    p {
        font-size: 16px;
    }
}

.pursue,
.request {
    p {
        color: #000000;
    }
}

.issue {
    p {
        color: #56597A;
    }
}

.status {
    width: 160px;
    padding: 10px 0;
    margin: 0 auto;
    border-radius: 20px;

    p {
        font-size: 14px;
        color: white;
    }
}

.completed-status {
    background-color: #39E23E;
}

.pending-status {
    background-color: #557BEA;
}

.answer-status {
    background-color: #9F9F9F;
}

.apply-delete-status {
    background-color: #FA3A67;
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {}

/* 2304-1470 */
@media (min-width: 2304px) {
    .risk-icon {
        width: 40px;
        height: 40px;
    }
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
    .details-table .header .header-el p {
        font-size: 18px;
    }

    .pursue p,
    .issue p,
    .request p {
        font-size: 18px;
    }

    .status {
        width: 180px;

        p {
            font-size: 16px;
        }
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .risk-icon {
        width: 45px;
        height: 45px;
    }

    .details-table .body .row-expanded .row-expanded-content {
        padding-top: 20px;
    }

    .dsb-title {
        margin-bottom: 25px;
    }

    .details-table .body .row-expanded .row-expanded-content .content-wrapper {
        margin-bottom: 30px;
    }

    .request-dates p {
        font-size: 18px;
    }

    .about-data p {
        font-size: 18px;
    }

    .risks-list .risk p {
        font-size: 18px;
    }
}

/*2573-1206*/
@media(min-width: 2573px) {}

/* 2880-1670 */
@media(min-width: 2880px) {
    .details-table .header .header-el p {
        font-size: 20px;
    }

    .pursue p,
    .issue p,
    .request p {
        font-size: 20px;
    }

    .status {
        width: 200px;
        padding: 12px 0;

        p {
            font-size: 18px;
        }
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {}

/*3200-1800*/
@media(min-width: 3200px) {
    .risk-icon {
        width: 50px;
        height: 50px;
    }

    .details-table .body .row-expanded .row-expanded-content {
        padding: 40px 4%;
        padding-top: 30px;
    }

    .dsb-title {
        margin-bottom: 30px;
    }

    .details-table .body .row-expanded .row-expanded-content .content-wrapper {
        margin-bottom: 40px;
    }

    .request-dates p {
        font-size: 20px;
    }

    .about-data p {
        font-size: 20px;
    }

    .risks-list {
        .risk {
            padding: 8px 25px;

            p {
                font-size: 20px;
            }
        }
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px) {
    .details-table .header .header-el p {
        font-size: 24px;
    }

    .pursue p,
    .issue p,
    .request p {
        font-size: 24px;
    }

    .status {
        width: 240px;

        p {
            font-size: 22px;
        }
    }

    .risk-icon {
        width: 55px;
        height: 55px;
    }

    .details-table .body .row-expanded .row-expanded-content {
        padding: 50px 4%;
        padding-top: 40px;
    }

    .dsb-title {
        margin-bottom: 40px;
    }

    .details-table .body .row-expanded .row-expanded-content .content-wrapper {
        margin-bottom: 50px;
    }

    .request-dates p {
        font-size: 24px;
    }

    .about-data p {
        font-size: 24px;
    }

    .risks-list .risk p {
        font-size: 24px;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
    .risk-icon {
        width: 30px;
        height: 30px;
    }
}

/*1688-1080*/
@media(max-width: 1688px) {}

/*1680-1080*/
@media(max-width: 1680px) {}

/*1640-1080*/
@media(max-width: 1640px) {
    .details-table {
        margin-top: 100px;
    }

    .details-table .header .header-el p {
        font-size: 14px;
    }

    .pursue p,
    .issue p,
    .request p {
        font-size: 14px;
    }

    .status {
        width: 145px;
    }

    .request-dates p {
        font-size: 14px;
    }

    .about-data p {
        font-size: 14px;
    }

    .risks-list .risk p {
        font-size: 14px;
    }
}

/* 1600 - 757 */
@media (max-width: 1600px) {}

/*1536-864*/
@media(max-width:1536px) {}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .risk-icon {
        width: 28px;
        height: 28px;
    }

    .status {
        width: 130px;

        p {
            font-size: 12px;
        }
    }
}

/*1366-638*/
@media (max-width:1366px) {}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .details-table {
        margin-top: 80px;
    }

    .details-table .header .header-el p {
        font-size: 12px;
    }

    .pursue p,
    .issue p,
    .request p {
        font-size: 12px;
    }

    .request-dates p {
        font-size: 12px;
    }

    .about-data p {
        font-size: 12px;
    }

    .risks-list .risk p {
        font-size: 12px;
    }

    .risk-icon {
        width: 25px;
        height: 25px;
    }
}

/* 1200-1920 */
@media (max-width:1200px) {
    .details-table .header {
        padding-bottom: 20px;
    }

    .details-table .body .row-wrapper {
        padding: 20px 10px;
    }

    .details-table .body .row-expanded .row-expanded-content {
        padding: 10px 4%;
    }

    .risks-list {
        gap: 20px;
    }
}

/*1134x712*/
@media(max-width:1134px) {}

/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
    .details-table {
        margin-top: 50px;
    }

    .details-table .header .header-el p {
        font-size: 10px;
    }

    .pursue p,
    .issue p,
    .request p {
        font-size: 10px;
    }

    .request-dates p {
        font-size: 10px;
    }

    .about-data p {
        font-size: 10px;
    }

    .risks-list .risk p {
        font-size: 10px;
    }

    .risk-icon {
        width: 23px;
        height: 23px;
    }

    .status {
        width: 100px;
        padding: 8px 0;

        p {
            font-size: 10px;
        }
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {}

/* 962x601 */
@media(max-width: 962px) {}

/* 900x1600 */
@media(max-width: 900px) {
    .risk-icon {
        width: 20px;
        height: 20px;
    }
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {}

/*712-1138*/
@media(max-width:712px) {}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
    .dsb-router-wrapper {
        padding: 40px 5px !important;
        padding-top: 18px !important;
    }
}

/* 600x962 */
@media(max-width:600px) {}

/* 577-951 */
@media(max-width:577px) {}

/* 540-960 */
@media(max-width:540px) {
    .details-table {
        margin-top: 30px;
    }

    .details-table .header .header-el p {
        font-size: 8px;
    }

    .pursue p,
    .issue p,
    .request p {
        font-size: 8px;
    }

    .request-dates p {
        font-size: 8px;
    }

    .about-data p {
        font-size: 8px;
    }

    .risks-list .risk p {
        font-size: 8px;
    }

    .risk-icon {
        width: 15px;
        height: 15px;
    }

    .status {
        width: 90px;
        padding: 5px 0;

        p {
            font-size: 8px;
        }
    }

    .details-table .header {
        padding-bottom: 15px;
    }

    .details-table .body .row-wrapper {
        padding: 15px 10px;
    }

    .details-table .body .row-expanded .row-expanded-content {
        padding: 10px 4%;
    }

    .risks-list {
        gap: 15px;
    }

    .dsb-title {
        margin-bottom: 10px;
    }

    .details-table .body .row-expanded .row-expanded-content .content-wrapper {
        margin-bottom: 15px;
    }
}

/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px) {}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px) {}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .request-dates {
        gap: 40px;
    }
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) {}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {}

/* ip SE */
/*320x568*/
@media(max-width:320px) {}
</style>
