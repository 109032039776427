<template>
    <div data-aos="fade-left" class="modal-wrapper">
        <div class="modal modal-sm"> 
            <div class="modal-title modal-title-lg">
                <p>Erstelle dein Passwort</p>
            </div>

            <div class="modal-msg modal-msg-blue">
                <p>
                    Erstelle dein Passwort mit mindestens 10 Zeichen und einer Zahl, um dein Konto optimal zu schützen.
                </p>  
            </div> 

            <div class="input-wrapper" >
                <input type="password" placeholder="Passwort" v-model="registerPassword" :class="{'input-err': registerPasswordErr}">
                <div class="err-icon" v-if="registerPasswordErr">
                    <img src="@/assets/icons/incorrect_icon.svg"/>
                </div>
                <div class="err-msg" v-if="registerPasswordErr">
                    <p v-for="error in v$.registerPassword.$errors" :key="error.$uid" >
                        {{error.$message}}
                    </p> 
                </div>
            </div>

            <div class="input-wrapper">
                <input type="password" placeholder="Passwort wiederholen" v-model="registerConfirmPassword" :class="{'input-err': registerConfirmPasswordErr}">
                <div class="err-icon" v-if="registerConfirmPasswordErr">
                    <img src="@/assets/icons/incorrect_icon.svg"/>
                </div>
                <div class="err-msg" v-if="registerConfirmPasswordErr">
                    <p v-for="error in v$.registerConfirmPassword.$errors" :key="error.$uid" >
                        {{error.$message}}
                    </p> 
                </div>
            </div>  

            <button v-if="loadingBtn" class="modal-btn loading-btn">
                <div class="loader"></div>
            </button>
            <button v-else class="modal-btn" @click="setRegisterPass()">
                <p>Weiter</p>
            </button>
        </div>
    </div>
</template>
<script>
import useValidate from '@vuelidate/core';
import {
    required,
    helpers, 
    sameAs
} from "@vuelidate/validators";
export default {
    watch: {
        "registerPassword"(value) {
            if (value) {
                this.registerPasswordErr = false;
            } else {
                this.registerPasswordErr = true;
            }
        },
        "registerConfirmPassword"(value) {
            if (value) {
                this.registerConfirmPasswordErr = false;
            } else {
                this.registerConfirmPasswordErr = true;
            }
        },
    },
    data(){
        return{
            v$: useValidate(),
            registerPassword: '',
            registerPasswordErr: false,
            registerConfirmPassword: '',
            registerConfirmPasswordErr: false,
            loadingBtn: false,
        }
    },
    validations() {
        return {
            registerPassword: {
                required: helpers.withMessage('Passwort ist erforderlich', required), 
            },

            registerConfirmPassword: {
                required: helpers.withMessage('Passwort bestätigen ist erforderlich', required), 
                sameAsPassword: helpers.withMessage('Der Wert muss dem Passwortwert entsprechen', sameAs(this.registerPassword)),
            },
        }
    },
    methods:{
        setRegisterPass(){
            this.loadingBtn = true;

            this.v$.registerPassword.$touch();
            this.v$.registerConfirmPassword.$touch(); 

            this.registerPasswordErr = this.v$.registerPassword.$invalid;
            this.registerConfirmPasswordErr = this.v$.registerConfirmPassword.$invalid; 
 

            if (!this.registerPasswordErr && !this.registerConfirmPasswordErr && this.registerPassword && this.registerConfirmPassword) {  
                this.$store.commit("auth/setRegisterPsw", this.registerPassword);
                this.$store.commit("auth/setConfirmRegisterPsw", this.registerConfirmPassword);

                this.$store.commit("auth/setExpireVar", this.$route.query.expires);
                this.$store.commit("auth/setSignatureVar", this.$route.query.signature);
                this.$store.commit("auth/setIdVar", this.$route.query.id);
                this.$store.commit("auth/setHashVar", this.$route.query.hash);

                
                this.$router.push("/nextPhase");
            } else{ 
                this.$store.commit("auth/setRegisterPsw", null);
                this.$store.commit("auth/setConfirmRegisterPsw", null);
                this.$store.commit("auth/setExpireVar", null);
                this.$store.commit("auth/setSignatureVar", null);
                this.$store.commit("auth/setIdVar", null);
                this.$store.commit("auth/setHashVar", null);
            }

            this.loadingBtn = false;
        }
        
    }
}
</script> 
<style scoped lang="scss">
@import "../../assets/modals.scss"; 
 
.confirm-pass{ 
    margin-bottom: 35px;
}
</style>