<template>
    <div data-aos="fade-left" class="modal-wrapper">
        <div class="modal modal-sm"> 
            <div class="modal-title modal-title-lg">
                <p>Erstelle ein neues Konto</p>
            </div>
            <div class="genders-wrapper" :class="{'err-active': registerGenderErr}">
                <div class="gender" @click="toggleGender('m')" :class="{'gender-active': registerGender == 'm'}">
                    <p>Herr</p>
                </div>
                <div class="gender " @click="toggleGender('f')" :class="{'gender-active': registerGender == 'f'}">
                    <p>Frau</p>
                </div>
            </div>
            <div class="err-msg err-msg-margin" v-if="registerGenderErr">
                <p v-for="error in v$.registerGender.$errors" :key="error.$uid" >
                    {{error.$message}}
                </p> 
            </div>

            <div class="input-wrapper">
                <input type="text" placeholder="Vorname" v-model="registerName" :class="{'input-err': registerNameErr}">
                <div class="err-icon" v-if="registerNameErr">
                    <img src="@/assets/icons/incorrect_icon.svg"/>
                </div>
                <div class="err-msg" v-if="registerNameErr">
                    <p v-for="error in v$.registerName.$errors" :key="error.$uid" >
                        {{error.$message}}
                    </p> 
                </div>
            </div>

            <div class="input-wrapper">
                <input type="text" placeholder="Nachnahme" v-model="registerSurname" :class="{'input-err': registerSurnameErr}">
                <div class="err-icon" v-if="registerSurnameErr">
                    <img src="@/assets/icons/incorrect_icon.svg"/>
                </div>
                <div class="err-msg" v-if="registerSurnameErr">
                    <p v-for="error in v$.registerSurname.$errors" :key="error.$uid" >
                        {{error.$message}}
                    </p> 
                </div>
            </div>


            <div class="input-wrapper">
                <input type="date" placeholder="Geburtsdatum" v-model="registerBirthdate" :class="{'input-err': registerBirthdateErr}">
                <div class="err-icon" v-if="registerBirthdateErr">
                    <img src="@/assets/icons/incorrect_icon.svg"/>
                </div>
                <div class="err-msg" v-if="registerBirthdateErr">
                    <p v-for="error in v$.registerBirthdate.$errors" :key="error.$uid" >
                        {{error.$message}}
                    </p> 
                </div>
            </div>

            <div class="input-wrapper">
                <input type="email" placeholder="E-mail" v-model="registerEmail" :class="{'input-err': registerEmailErr}">
                <div class="err-icon" v-if="registerEmailErr">
                    <img src="@/assets/icons/incorrect_icon.svg"/>
                </div>
                <div class="err-msg" v-if="registerEmailErr">
                    <p v-for="error in v$.registerEmail.$errors" :key="error.$uid" >
                        {{error.$message}}
                    </p> 
                </div>
            </div>

            
            <div class="input-wrapper terms-wrapper" :class="{'err-active': registerTermsErr}">
                
                <input type="checkbox" name="termsConditions" v-model="registerTerms"  id="termsConditions" class="custom-checkbox" />

                <label for="termsConditions" class="custom-label"  @click.prevent="toggleTerms">
                    <span>Ich stimme den <span class="terms-color">CleanData-Bedingungen zu.</span></span>
                </label>
            </div> 
            <div class="err-msg" v-if="registerTermsErr" :class="{'err-msg-margin': registerTermsErr}"> 
                <p>Bedingungen ist erforderlich</p>
            </div>

            <div class="register-btns">
                <div class="human">
                    <VueRecaptcha
                        @verify="onRecaptchaVerified"
                        @expire="onRecaptchaExpired"
                       
                    ></VueRecaptcha>
                    <!-- @error="onCaptchaError" -->
                </div> 

                <button v-if="loadingBtn" class="modal-btn loading-btn">
                    <div class="loader"></div>
                </button>
                <button v-else class="modal-btn" @click="registerUser()">
                    <p>Jetzt registrieren</p>
                </button>
            </div>
    
            <div class="modal-msg modal-msg-blue modal-msg-sm">
                <p>
                    Indem du fortfährst, stimmst du unseren <b>Nutzungsbedingungen</b> zu und bestätigst, dass du unsere <b>Datenschutz - und Cookie-Richtlinie</b> gelesen hast. 
                </p>
            </div>

            <div class="modal-msg modal-msg-pink">
                <router-link to="/login">
                    Hast du bereits ein Konto?
                </router-link>
            </div>
        </div> 

        <GoConfirm v-if="goConfirmModal"/>
    </div>
</template>
<script> 

import GoConfirm from './GoConfirm.vue';

import { VueRecaptcha } from 'vue-recaptcha-v3';
import useValidate from '@vuelidate/core';
//import Swal from 'sweetalert2';
 
import {
    required,
    helpers,
    email,
} from "@vuelidate/validators";
export default {
    watch: {
        "registerName"(value) {
            if (value) {
                this.registerNameErr = false;
            } else {
                this.registerNameErr = true;
            }
        },

        "registerSurname"(value) {
            if (value) {
                this.registerSurnameErr = false;
            } else {
                this.registerSurnameErr = true;
            }
        },

        "registerBirthdate"(value) {
            if (value) {
                this.registerBirthdateErr = false;
            } else {
                this.registerBirthdateErr = true;
            }
        },

        "registerEmail"(value) {
            if (value) {
                this.registerEmailErr = false;
            } else {
                this.registerEmailErr = true;
            }
        },

        "registerTerms"(value) {
            console.log(value)
            if (value) {
                this.registerTermsErr = false;
            } else {
                this.registerTermsErr = true;
            }
        },
    },
    components:{
        GoConfirm,
        VueRecaptcha
    },
    data() {
        return {
            recaptchaToken: null,

            v$: useValidate(),

            loadingBtn: false,

            registerGender: null,
            registerGenderErr: false,

            registerName: '',
            registerNameErr: false,
 
            registerSurname: '',
            registerSurnameErr: false,
 
            registerBirthdate: '',
            registerBirthdateErr: false, 
            
            registerEmail: '',
            registerEmailErr: false,

            registerTerms: false,
            registerTermsErr: false,

            goConfirmModal: false,
        }
    }, 
    validations() {
        return {
            registerGender: {
                required: helpers.withMessage('Geschlecht ist erforderlich', required), 
            },
            registerName: {
                required: helpers.withMessage('Vorname ist erforderlich', required), 
            },
            registerSurname: {
                required: helpers.withMessage('Nachnahme ist erforderlich', required),
                 
            },
            registerBirthdate: {
                required: helpers.withMessage('Geburtsdatum ist erforderlich', required), 
            },
            registerEmail: {
                required: helpers.withMessage('E-Mail ist erforderlich', required),
                email
            },
            registerTerms: {
                required: helpers.withMessage('Bedingungen ist erforderlich', required),    
            }, 
        }
    },
    computed: {  
    },
    methods:{ 
        onRecaptchaVerified(token) {
            this.recaptchaToken = token;
        },
        onRecaptchaExpired() {
            this.recaptchaToken = '';
        },
        formatBirthday(date) {
            if (!date) return '';

            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');  // Ensure two-digit day
            const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month
            const year = d.getFullYear();

            return `${day}.${month}.${year}`;
        },

        toggleTerms(){
            this.registerTerms = !this.registerTerms
        },

        toggleGender(name){
            this.registerGender = null;
            this.registerGender = name;
        },

        async registerUser() {
            this.loadingBtn = true;

            if (!this.recaptchaToken) {
                alert("Bitte bestätigen Sie, dass Sie kein Roboter sind.");
                return;
            }

            this.v$.registerGender.$touch();
            this.v$.registerName.$touch();
            this.v$.registerSurname.$touch();
            this.v$.registerBirthdate.$touch();
            this.v$.registerEmail.$touch(); 

            this.registerGenderErr = this.v$.registerGender.$invalid;
            this.registerNameErr = this.v$.registerName.$invalid;
            this.registerSurnameErr = this.v$.registerSurname.$invalid;
            this.registerBirthdateErr = this.v$.registerBirthdate.$invalid;
            this.registerEmailErr = this.v$.registerEmail.$invalid;
            
            if (!this.registerTerms) {
                this.registerTermsErr = true;  
            } else {
                this.registerTermsErr = false;   
            }

            if (
                !this.registerGenderErr && !this.registerNameErr && 
                !this.registerSurnameErr && !this.registerBirthdateErr && 
                !this.registerEmailErr && !this.registerTermsErr
            ) { 
                
                try {

                    this.$store.commit("auth/verificationEmail", this.registerEmail);

                    await this.registerStep1({
                        privacyPolicy: this.registerTerms ? 1 : 0,
                        gender: this.registerGender,
                        firstName: this.registerName,
                        lastName: this.registerSurname,
                        birthday: this.formatBirthday(this.registerBirthdate),
                        email: this.registerEmail, 
                    });
                    this.loadingBtn = false;
                    this.goConfirmModal = true;
                    this.resetForm();
                    // Swal.fire("Erfolgreich registriert!", "Überprüfen Sie den nächsten Schritt", "info").then(() => { 
                    //     this.loadingBtn = false;
                    //     this.$router.push("/goConfirm");
                    //     this.resetForm();
                    // }); 
                } catch (error) {  
                   // console.log(error)
                    // Swal.fire("Bei der Registrierung ist ein Fehler aufgetreten!", "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.", "warning").then(() => { 
                    //     this.loadingBtn = false;
                    // }); 

                    this.$store.commit("application/setErrorProcessModal", true); 
                    this.$store.commit("application/setErrorProcessTitle", 'Bei der Registrierung ist ein Fehler aufgetreten!'); 
                    this.$store.commit("application/setErrorProcessText", this.authError || "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."); 
                    this.loadingBtn = false;
                }
            }else{
                this.loadingBtn = false;
            }  
        },

        resetForm(){
            this.loadingBtn = false;

            this.registerGender = null; 
            this.registerName = '';
            this.registerNameErr = false;

            this.registerSurname = '';
            this.registerSurnameErr = false;

            this.registerBirthdate = '';
            this.registerBirthdateErr = false; 

            this.registerEmail = '';
            this.registerEmailErr = false;

            this.registerTerms = false;
            this.registerTermsErr = false;

            this.v$.$reset();
        }
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/modals.scss"; 
.modal-title{ 
    margin-bottom: 35px;
}

.err-msg-margin{
    margin-bottom: 30px;
    text-align: center;
}


.genders-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-bottom: 30px;

    .gender{
        border: 2px solid #E9EAEE;
        border-radius: 31px;
        padding: 8px 22px;
        cursor: pointer;
        transition: 0.3s ease;

        p{
           font-size: 16px;
           color: #395474; 
        }
    }
    
    .gender:hover,
    .gender-active{
        p{ 
            color: white;
        }
        background-color: #FF3A67;
    }
}

.err-active{
    margin-bottom: 0;
}
  
.terms-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-checkbox {
    display: none;
}

.custom-checkbox:checked {
    color: #C0CCC2;
}

.custom-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    span {
        font-size: 15px;
        color: #395474;
    }

    .terms-color{
        color: #FF3A67;
    }

    &:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid #FF3A67;
        border-radius: 5px;
        margin-right: 5px;
        transition: 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &::after {
        content: "";
        display: none;
        transform: rotate(45deg);
        height: 7px;
        width: 3px;
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        position: absolute;
        left: 7px;
        top: 6px;
    }
}

.custom-checkbox:checked+.custom-label:before {
    background-color: #FF3A67;
}

.custom-checkbox:checked+.custom-label:after {
    display: inline-block;
}


.register-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    .modal-btn{
        margin: 0;
    }
}

.human{
    width: 220px; 
}

.modal-msg{
    text-align: left;
    &-blue{
        margin-bottom: 25px;
    }
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
    .genders-wrapper { 
        gap: 20px;
        margin-bottom: 35px;

        .gender {
            padding: 10px 30px;
        }
    } 

    .custom-label{
        span {
            font-size: 18px; 
        }

        &::before { 
            width: 18px;
            height: 18px;  
            margin-right: 10px;
        }

        &::after { 
            height: 8px;
            width: 4px; 
            left: 7px;
            top: 7px;
        }
    }
    
    
}

/* 2304-1470 */
@media (min-width: 2304px) {
    .genders-wrapper {  
        .gender {
            padding: 12px 35px;
        }
    } 
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
    .genders-wrapper { 
        gap: 30px;
        margin-bottom: 40px;

        .gender {
            padding: 12px 40px;
            p {
                font-size: 18px; 
            }
        } 
    } 

    .custom-label{
        span {
            font-size: 20px; 
        }

        &::before { 
            width: 22px;
            height: 22px;  
            margin-right: 15px;
        }

        &::after { 
            height: 9px;
            width: 5px; 
            left: 9px;
            top: 8px;
        }
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .genders-wrapper {  
        margin-bottom: 45px; 
    } 
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .genders-wrapper { 
        gap: 40px;
        margin-bottom: 45px;

        .gender {
            padding: 12px 50px;
            p {
                font-size: 20px; 
            }
        } 
    } 

    .custom-label{
        span {
            font-size: 22px; 
        }

        &::before { 
            width: 24px;
            height: 24px;  
            margin-right: 15px;
        }

        &::after { 
            height: 10px;
            width: 5px;
            left: 9px;
            top: 9px;
            border-bottom: 3px solid white;
            border-right: 3px solid white;
        } 
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .genders-wrapper { 
        gap: 50px; 

        .gender {
            padding: 12px 60px; 
        } 
    } 
}

/*3360-1890 1695*/
@media(min-width:3360px) {
    .genders-wrapper { 
        gap: 60px;
        margin-bottom: 50px;

        .gender {
            padding: 14px 70px;
            p {
                font-size: 22px; 
            }
        } 
    }

    .custom-label{
        span {
            font-size: 24px; 
        }

        &::before { 
            width: 26px;
            height: 26px;  
            margin-right: 15px;
        }

        &::after {  
            left: 10px; 
        } 
    }
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){

    .genders-wrapper { 
        gap: 70px;
        margin-bottom: 60px;

        .gender {
            padding: 16px 80px;
            p {
                font-size: 26px; 
            }
        } 
    }

    .custom-label{
        span {
            font-size: 28px; 
        }

        &::before { 
            width: 28px;
            height: 28px;  
            margin-right: 20px;
        }

        &::after {   
            height: 12px;
            width: 7px; 
            left: 10px; 
            top: 10px;
        } 
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) {
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .genders-wrapper .gender p {
        font-size: 14px; 
    }

    .register-btns {
        margin-bottom: 40px;
    }   
}

/*1536-864*/
@media(max-width:1536px) {
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .modal-msg-pink{
        margin-bottom: 0 !important;
    }
}

/*1366-638*/
@media (max-width:1366px) {
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .custom-label {
        span {
            font-size: 14px;
        }
    
        &:before { 
            width: 14px;
            height: 14px;  
            margin-right: 8px;  
        }

        &::after { 
            height: 6px;
            width: 3px; 
            left: 6px;
            top: 6px;
        }
    } 
}

/* 1200-1920 */
@media (max-width:1200px) {
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
    .genders-wrapper .gender { 
        padding: 8px 0; 
        width: 72px;
        text-align: center;
    }
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
   

    .custom-label {
        span {
            font-size: 12px;
        } 

        &:before {
            margin-right: 5px;
        }

        &::after {  
            top: 4px;
        }
    } 

    .human{
        border: 1px solid black;
    }

    .register-btns{
        flex-direction: column;
        gap: 25px
    }
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
 
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {

}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {    
    .genders-wrapper .gender{
        padding: 5px 0;
         
        p{
            font-size: 12px;
        }
    }

    .custom-label {
        span {
            font-size: 10px;
        } 

        &:before {
            margin-right: 5px;
        }

        &::after {  
            top: 3px;
        }
    } 
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>