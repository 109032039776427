<template>
    <div class="modal-wrapper colored-modal-wrapper user-identity">
        <div class="modal modal-sm"> 
            <div class="mail-animation">
                <Vue3Lottie :animationData="mailAnimation"/>
            </div>

            <div class="modal-title modal-title-lg">
                <p>Bestätige, dass du es bist</p>
            </div>
 
            <div class="modal-msg modal-msg-sm modal-msg-blue">
                <p>
                    Wir haben dir einen Verifizierungscode an 
                    <span v-if="verificationEmail">{{verificationEmail}} </span>
                    <span v-else>Ihre E-Mail</span>
                    gesendet. Bitte überprüfe dein Postfach.
                </p>                
            </div> 
             
  
            <button v-if="loadingBtn" class="modal-btn loading-btn">
                    <div class="loader"></div>
                </button>
            <button v-else @click="verifyEmail()" class="modal-btn">
                <p>Verifikation</p>
            </button>

            <div class="modal-msg modal-msg-pink">
                <a>
                    Hast du keine E-Mail erhalten? <b>Versuch es erneut</b>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import mailAnimation from '../../assets/animations/mail-animation.json'
export default {
    data(){
        return{
            mailAnimation,
            loadingBtn: false,
        }
    },
    methods:{
        verifyEmail(){
            this.loadingBtn = true;
            setTimeout(() => {
                this.loadingBtn = false;
                this.$router.push("/login");
                this.$store.commit("auth/verificationEmail", null);
            }, "1000");
        }
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/modals.scss"; 
.user-identity{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
.mail-animation{
    width: 126px;
    height: 202px;
    margin: 0 auto;
    margin-bottom: 37px;
    margin-top: -70px;
} 
 
.modal-btn{ 
    margin-bottom: 32px; 
} 


.modal-msg-pink{
    margin-bottom: 0;
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1470 */
@media (min-width: 2304px) {
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .mail-animation{
        width: 140px;
        height: 220px; 
    } 
    
    .modal-btn{ 
        margin-bottom: 35px; 
    } 
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .mail-animation{
        width: 160px;
        height: 240px; 
    } 
    
    .modal-btn{ 
        margin-bottom: 40px; 
    } 
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .mail-animation{
        width: 180px;
        height: 260px; 
    } 
    
    .modal-btn{ 
        margin-bottom: 45px; 
    } 
}

/*3360-1890 1695*/
@media(min-width:3360px) { 
    .mail-animation{
        width: 190px;
        height: 270px; 
    }  
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .mail-animation{
        width: 240px;
        height: 320px; 
    } 
    
    .modal-btn{ 
        margin-bottom: 55px; 
    } 
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
}

/*1688-1080*/
@media(max-width: 1688px) {
    .mail-animation {
        width: 120px;
        height: 190px;
    }
}

/*1680-1080*/
@media(max-width: 1680px) {
}

/*1640-1080*/
@media(max-width: 1640px) {
    .mail-animation {
        width: 110px;
        height: 180px;
    }
}

/* 1600 - 757 */
@media (max-width: 1600px) {
}

/*1536-864*/
@media(max-width:1536px) {
    .mail-animation {
        width: 100px;
        height: 170px;
    }
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
}

/*1366-638*/
@media (max-width:1366px) {
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .mail-animation {
        width: 90px;
        height: 160px;
    }
}

/* 1200-1920 */
@media (max-width:1200px) {
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
    .mail-animation { 
        margin-bottom: 25px;
        margin-top: -50px;
    }
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
    .mail-animation { 
        width: 80px;
        height: 150px;
        margin-bottom: 15px;
        margin-top: -40px;
    } 
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
    .mail-animation { 
        width: 70px;
        height: 120px; 
    } 
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
    .mail-animation { 
        width: 63px;
        height: 120px;
        margin-bottom: 0px;
        margin-top: -30px;
    } 

    .modal-sm { 
        padding: 30px 10px;
    }
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
    .modal-sm { 
        padding: 20px 10px;
    }
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
    .mail-animation { 
        width: 50px;
        height: 100px;
        margin-bottom: 0px;
        margin-top: -30px;
    } 

    .modal-sm { 
        padding: 20px 5px;
    }
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>