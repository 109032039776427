<template>
    <Loader v-if="loading"/>
    <div v-else class="dsb-router-wrapper">
        <div class="dsb-router-header">
            <div class="dsb-title dsb-title-lg">
                <p v-if="currentUser">Willkommen, {{currentUser.firstName}}!</p>
            </div>
            <div class="stats">
                <div class="companies">
                    <p>0 Unternehmen</p>
                </div>
                <span>|</span>
                <div class="requests">
                    <p>0 Abgeschlossen</p>
                </div>
            </div>
        </div>

        <div class="data-section">
            <div class="search-section">
                <div class="search-info">
                    <div class="info-content">
                        <div class="dsb-title dsb-title-md">
                            <p>Deine Daten</p>
                        </div>
                        <div class="dsb-msg">
                            <p>
                                Finde heraus, welche Unternehmen<br>
                                deine Daten sammeln und welche<br>
                                Informationen sie über dich speichern!
                            </p>
                        </div>
                    </div>
                    <div class="search-animation">
                        <Vue3Lottie :animationData="searchCompaniesAnimtaion" />
                    </div>
                </div>
                <button @click="handleUserVerification()" class="dsb-btn">
                    <p>JETZT STARTEN</p>
                </button>
            </div>

            <div class="data-overview">
                <div class="data-info">
                    <div class="data-count">
                        <p>0</p>
                    </div>
                    <div class="dsb-msg">
                        <p>Dies sind die Unternehmen, die deine<br> Daten haben.</p>
                    </div>
                </div> 
                <div class="location-animation">
                    <Vue3Lottie :loop="2" :speed="0.5" :animationData="locationAnimtaion" />
                </div>
            </div>
        </div>

        <div class="activity">
            <div class="activity-stats">
                <div class="header">
                    <div class="dsb-title dsb-title-sm">
                        <p>Deine Aktivität</p>
                    </div>
                    <!-- <div ></div> -->
                        <select class="picker">
                            <option value="7">Letzte 7 Tage</option>
                            <option value="30">Letzte 30 Tage</option>
                        </select>
                    
                </div>

                <div class="stats-list">
                    <div class="stat">
                        <div class="amount">
                            <p>0</p>
                            <div class="line"></div>
                        </div>
                        <div class="type"><p>Offene Unternehmen</p></div>
                    </div>

                    <div class="stat">
                        <div class="amount">
                            <p>0</p>
                            <div class="line"></div>
                        </div>
                        <div class="type"><p>Zurückgeforderte Daten</p></div>
                    </div>

                    <div class="stat">
                        <div class="amount">
                            <p>0</p>
                            <div class="line"></div>
                        </div>
                        <div class="type"><p>Abgeschlossene Anfragen</p></div>
                    </div>
                </div>

                <div class="dsb-msg dsb-msg-sm dsb-msg-grey">
                    <p>Behalte den Überblick über deine letzten Aktivitäten.</p>
                </div>
            </div>

            <div class="activity-visual">
                <div class="dsb-title dsb-title-sm">
                    <p>Deine Daten, deine Kontrolle</p>
                </div>
                <div  >
                    <ApexCharts 
                        type="radialBar" 
                        :options="chartOptions" 
                        :series="[progressValue]"
                        height="350"   
                    /> 
                </div> 
            </div>
        </div>
    </div>
</template>
 
<script>  
import Loader from '@/components/Loader.vue';

import VueApexCharts from 'vue3-apexcharts';
import searchCompaniesAnimtaion from '../../assets/animations/search-companies-animtaion.json'
import locationAnimtaion from '../../assets/animations/location-animation.json'
export default {
    components:{
        Loader,
        ApexCharts: VueApexCharts
    },
    computed: {  progressValue() {
      return this.currentUser.progress || 88;  
    }},
    mounted(){
        this.loading = true; 
        this.checkUserIdentity();

        this.loading = false;
    },
    data(){
        return{
            searchCompaniesAnimtaion,
            locationAnimtaion,

            loading: false,

            chartOptions: {
                chart: {
                height: 200,
                type: 'radialBar',
                offsetY: 0, 
                
                },
                plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    dataLabels: {
                    name: {
                        fontSize: '14px',
                        fontWeight: '200',
                        color: "#000000",
                        offsetY: 40
                    },
                    value: {
                        offsetY: -25,
                        fontSize: '38px',
                        fontWeight: 'bold',
                        color: "#FF3B64",
                        formatter: function (val) {
                        return val + "%";
                        }
                    }
                    }
                }
                },
                fill: {
                    shade: 'dark',
                    colors: ['#FF3B64', '#FF3B64']
                },
                stroke: {
                dashArray: 8
                },
                labels: ['Ergriffene Massnahmen'],
            },
        }
    },
    methods:{
        async handleUserVerification() {
            const isVerified = await this.checkUserIdentity();
            if (isVerified) { 
                this.$store.commit("application/SET_LOOKINGFOR_MODAL", true);
            }
        },
    },
 
}
</script>
<style scoped lang="scss">
@import "../../assets/dashboard.scss";  
 
    .dsb-btn{
        margin: initial !important;
    }
  
    .welcome-text {
        p {
            color: #000000;
            font-size: 32px;
            font-weight: 500;
        }
    }

    .stats {
        display: flex;
        align-items: center;
        gap: 20px;

        .companies,
        .requests {
            p {
                color: #000000;
                font-size: 16px;
            }
        }

        span {
            color: #000000;
            font-size: 16px;
        }
    }

    .data-section {
        display: flex;
        width: 100%;
        box-shadow: 4px 6px 14px #56597A4D;
        border-radius: 20px;
        margin-bottom: 52px;
    }

    .search-section {
        background-color: hsla(346, 100%, 61%, 0.1);
        padding: 32px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        width: 50%;
    }

    .search-info {
        display: flex;
        align-items: start;
        justify-content: space-between;

        .info-content {
            .dsb-title {
                margin-bottom: 16px;
            }
        }
    }

    .search-animation {
        width: 264px;
        height: 189px;
    }
 
    .data-overview {
        padding: 32px;
        width: 50%;
        display: flex;
        justify-content: space-between;

        .data-count {
            margin-bottom: 11px;

            p {
                font-size: 48px;
                color: #000000;
            }
        }
    }  

    .location-animation {
        width: 220px;
        height: 220px;
    }
  

    .activity {
        width: 90%;
        box-shadow: 4px 6px 14px #56597A4D;
        border-radius: 20px;
        padding: 32px;
        display: flex;
    }

    .activity-stats {
        width: 60%;

        .header {
            display: flex;
            gap: 34px;
            align-items: center;
            margin-bottom: 50px;
        }

        .picker {
            border: 1px solid #56597A;
            border-radius: 25px;
            padding: 10px 15px;
            color: #56597A;
            padding-right: 5px;
            font-weight: 500;

            &:focus{
                outline: none; 
            }
        } 

        .stats-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 41px;

            .stat {
                width: 30%;

                .amount {
                    margin-bottom: 10px;

                    p {
                        font-weight: 500;
                        font-size: 38px;
                        color: #56597A;
                        margin-bottom: 5px;
                    }

                    .line { 
                        height: 2px;
                        background-color: #FF3A67;
                        width: 40%;
                        border-radius: 25px;
                    }
                }

                .type {
                    p {
                        font-size: 14px;
                        color: #000000;
                    }
                }
            }
        }
    }

    .activity-visual {
        width: 40%;

        .dsb-title {
            text-align: center;
        }

        .percentage {
            margin-top: 50px;
        }
    }


    /* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
    .location-animation {
        width: 240px;
        height: 240px;
    }
}

/* 2304-1470 */
@media (min-width: 2304px) {
    .search-animation {
        width: 275px;
        height: 200px; 
    }

    .location-animation {
        width: 260px;
        height: 260px;
    }
}

/* 2470 - 1328 */
@media (min-width: 2470px) {  
    .stats span,
    .stats .companies p, 
    .stats .requests p {
        font-size: 18px;
    }

    .data-section { 
        margin-bottom: 60px;
    }

    .data-overview{ 
        .data-count {
            p {
                font-size: 50px; 
            }
        }
    }

    .search-animation {
        width: 285px;
        height: 210px; 
    }

    .picker select{
        font-size: 16px;
    }

    .activity-stats {
        .stats-list {
            .stat {
                .amount {
                    p {
                        font-size: 40px;
                    }
                }

                .type {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    } 

    .activity-stats .header{
        gap: 40px;
    }

    .location-animation {
        width: 280px;
        height: 280px;
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .location-animation {
        width: 300px;
        height: 300px;
    }

    .search-section,
    .data-overview,
    .activity { 
        padding: 40px;
    }
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .search-section,
    .data-overview,
    .activity { 
        padding: 45px;
    }

    .location-animation {
        width: 320px;
        height: 300px;
    } 
 
    .stats span,
    .stats .companies p, 
    .stats .requests p {
        font-size: 20px;
    }

    .data-section { 
        margin-bottom: 70px;
    }

    .data-overview{ 
        .data-count {
            p {
                font-size: 52px; 
            }
        }
    }

    .search-animation {
        width: 295px;
        height: 220px; 
    }

    .picker select{
        font-size: 18px;
    }

    .activity-stats {
        .stats-list {
            .stat {
                .amount {
                    p {
                        font-size: 42px;
                    }
                }

                .type {
                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .activity-stats .header{
        gap: 50px;
        margin-bottom: 60px;
    }

    .activity-stats .stats-list { 
        margin-bottom: 50px;
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .search-section,
    .data-overview,
    .activity { 
        padding: 55px;
    }

    .location-animation {
        width: 350px;
        height: 350px;
    } 

    .stats span,
    .stats .companies p, 
    .stats .requests p {
        font-size: 22px;
    }

    .data-section { 
        margin-bottom: 80px;
    }

    .data-overview{ 
        .data-count {
            p {
                font-size: 54px; 
            }
        }
    }

    .search-animation {
        width: 250px;
        height: 250px; 
    } 
 
 

    .activity-stats {
        .stats-list {
            .stat {
                .amount {
                    p {
                        font-size: 44px;
                    }
                }

                .type {
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .activity-stats .header{
        gap: 60px;
        margin-bottom: 70px;
    }

    .activity-stats .stats-list { 
        margin-bottom: 60px;
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .search-section,
    .data-overview,
    .activity { 
        padding: 75px;
    }

    .location-animation {
        width: 380px;
        height: 380px;
    } 

    .stats span,
    .stats .companies p, 
    .stats .requests p {
        font-size: 26px;
    }

    .data-section { 
        margin-bottom: 100px;
    }

    .data-overview{ 
        .data-count {
            p {
                font-size: 85px; 
            }
        }
    }

    .search-animation {
        width: 350px;
        height: 350px; 
    }
 

    .activity-stats {
        .stats-list {
            .stat {
                .amount {
                    p {
                        font-size: 48px;
                    }
                }

                .type {
                    p {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .activity-stats .header{
        gap: 75px;
        margin-bottom: 90px;
    }

    .activity-stats .stats-list { 
        margin-bottom: 80px;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {  
    .search-animation {
        width: 240px;
        height: 180px;
    }

    .location-animation {
        width: 200px;
        height: 200px;
    }

    .activity {
        width: 100%;
    }
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) { 
 
    .data-overview .data-count p {
        font-size: 46px; 
    }
}

/*1640-1080*/
@media(max-width: 1640px) {
    .search-animation {
        width: 220px;
        height: 180px;
    }

    .location-animation {
        width: 190px;
        height: 190px;
    }
}

/* 1600 - 757 */
@media (max-width: 1600px) {
}

/*1536-864*/
@media(max-width:1536px) { 
 

    .activity-stats .stats-list .stat{ 
        .amount p { 
            font-size: 30px; 
        }
        .type p {
            font-size: 12px; 
        }
    }
 
    .data-overview .data-count p {
        font-size: 44px; 
    }

    .picker {
        font-size: 12px;
    }
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) { 
    .search-animation {
        width: 200px;
        height: 160px;
    }

    .location-animation {
        width: 180px;
        height: 180px;
    }

    .data-overview .data-count p {
        font-size: 42px; 
    }

    .search-section,
    .data-overview,
    .activity {
        padding: 25px;
    }
}

/*1366-638*/
@media (max-width:1366px) {
    .data-overview .data-count p {
        font-size: 40px; 
    }

    .stats span,
    .stats .companies p , 
    .stats .requests p { 
        font-size: 14px;
    }

    .activity {
        width: 100%;
    }

    .search-animation {
        width: 180px;
        height: 140px;
    }
    .location-animation {
        width: 170px;
        height: 170px;
    }
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .data-overview .data-count p {
        font-size: 36px;
    }

    .picker{ 
        font-size: 12px;
    }

    .activity-stats .stats-list .stat .amount p {
        font-size: 28px;
    }

    .search-section,
    .data-overview,
    .activity { 
        padding: 22px;
    }
 
    .search-animation {
        width: 170px;
        height: 130px;
    }
    .location-animation {
        width: 160px;
        height: 160px;
    }
}

/* 1200-1920 */
@media (max-width:1200px) { 
    .search-animation {
        width: 160px;
        height: 140px;
    }

    .search-section,
    .activity-stats{
        width: 55%  ;
    }

    .data-overview,
    .activity-visual{
        width: 45%;
    }

    .location-animation {
        width: 150px;
        height: 150px;
    }
}

/*1134x712*/
@media(max-width:1134px) { 
    .search-animation {
        width: 160px;
        height: 120px;
    }

    .location-animation {
        width: 140px;
        height: 140px;
    }
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
    .search-section,
    .data-overview,
    .activity { 
        padding: 18px;
    }
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) { 
    .stats { 
        gap: 10px;
        span, .companies p, .requests p {
            font-size: 12px;
        }
    }

    .data-overview .data-count p {
        font-size: 32px;
    }
 
    .data-section {
        margin-bottom: 35px;
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
    .data-section,
    .activity{
        flex-direction: column;
        gap: 35px;
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
    }

    .search-section,
    .data-overview,
    .activity-stats,
    .activity-visual{
        width: 100%  ;
    }

    .search-section,
    .data-overview,
    .activity-stats,
    .activity-visual{
        box-shadow: 4px 6px 14px #56597A4D;
        border-radius: 20px;
        padding: 14px;
    }

    .activity-visual{
        .dsb-title {
            text-align: left;
        }

        .search-animation {
            width: 172px;
            height: 123px;
        } 
    }
 

    .data-overview .data-count p {
        font-size: 28px;
    } 

    .location-animation {
        width: 167px;
        height: 167px;
    }
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
    .stats{ 
        gap: 5px; 

        span, 
        .companies p, 
        .requests p {
            font-size: 10px;
        }
    }
}

/*778-304*/
@media(max-width: 778px) {

}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) { 
    .stats{ 
        gap: 8px; 

        span, 
        .companies p, 
        .requests p {
            font-size: 12px;
        }
    }
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) { 
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) { 
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) { 

    .activity-stats{ 
        .header{
            gap: 0;
            justify-content: space-between;
            margin-bottom: 35px;
        }

        .stats-list .stat{ 
            width: 32%;
 
            .amount p {
                font-size: 26px;
            }

            .type p {
                font-size: 10px;
            }
        }
 
    }
 
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .search-animation {
        width: 140px;
        height: 140px;
    }

    .location-animation {
        width: 150px;
        height: 150px;
    }
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {
    .data-overview .data-count p {
        font-size: 26px;
    }

    .stats span, 
    .stats .companies p, 
    .stats .requests p {
        font-size: 11px;
    }

    .activity-visual .percentage {
        margin-top: 30px;
    } 
}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
    .search-animation {
        width: 130px;
        height: 130px;
    }

    .location-animation {
        width: 130px;
        height: 130px;
    }
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
    .stats span, 
    .stats .companies p, 
    .stats .requests p {
        font-size: 10px;
    }

    .search-animation {
        width: 120px;
        height: 120px;
    }

    .location-animation {
        width: 120px;
        height: 120px;
    }
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) { 
    .search-animation {
        width: 100px;
        height: 100px;
    }

    .location-animation {
        width: 100px;
        height: 100px;
    }
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
    .search-animation {
        width: 85px;
        height: 85px;
    }

    .location-animation {
        width: 85px;
        height: 85px;
    }
}
</style>
