<template >
    <div class="dsb-wrapper">
        <div class="dsb-menu-wrapper">
            <router-link to="/" class="dsb-logo" v-if="!mobile">
                <img src="../../assets/cleandata_logo.svg" />
            </router-link>

            <div class="dsb-menu" v-if="!mobile">
                <router-link to="/dashboard/overview" class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/overview' }">
                    <div class="menu-link-img"><img src="../../assets/icons/dashboard_icon.svg" /></div>
                    <a>Dashboard</a>
                </router-link>

                <router-link to="/dashboard/details" class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/details' }">
                    <div class="menu-link-img"><img src="../../assets/icons/detail_icon.svg" /></div>
                    <a>Detaillierte Ansicht</a>
                </router-link>

                <router-link to="/dashboard/connections" class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/connections' }">
                    <div class="menu-link-img"><img src="../../assets/icons/connections_icon.svg" /></div>
                    <a>Verbindungen</a>
                </router-link>

                <router-link to="/dashboard/notifications" class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/notifications' }">
                    <div class="menu-link-img"><img src="../../assets/icons/notifications_icon.svg" /></div>
                    <a>Benachrichtigungen</a>
                </router-link>

                <router-link to="/dashboard/settings" class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/settings' }">
                    <div class="menu-link-img"><img src="../../assets/icons/settings_icon.svg" /></div>
                    <a>Einstellungen</a>
                </router-link>

                <div @click="logOut()" class="menu-link-wrapper">
                    <div class="menu-link-img"><img src="../../assets/icons/logout-icon.svg" /></div>
                    <a>Abmelden</a>
                </div> 
            </div>

            <div v-if="mobile" @click="toggleMobileMenu()">
                <div class="menu-btn">
                    <img src="../../assets/icons/menu_button.svg"/>
                </div> 
            </div> 
        </div>

        <div v-if="mobile" class="mobile-menu" :class="{ 'mobile-menu-opened': slideMobMenu }">
     
            <div  @click="toggleMobileMenu()" class="menu-btn close-menu-btn">
                <img src="../../assets/icons/closemenu_icon.svg"/>
            </div>

            <div class="dsb-menu" > 
                <router-link @click="toggleMobileMenu()" to="/dashboard/overview"  class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/overview' }">
                    <div class="menu-link-img"><img src="../../assets/icons/dashboard_icon.svg" /></div>
                    <a>Dashboard</a>
                </router-link>

                <router-link @click="toggleMobileMenu()" to="/dashboard/details"  class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/details' }">
                    <div class="menu-link-img"><img src="../../assets/icons/detail_icon.svg" /></div>
                    <a>Detaillierte Ansicht</a>
                </router-link> 

                <router-link @click="toggleMobileMenu()" to="/dashboard/connections" class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/connections' }">
                    <div class="menu-link-img"><img src="../../assets/icons/connections_icon.svg" /></div>
                    <a>Verbindungen</a>
                </router-link> 

                <router-link @click="toggleMobileMenu()" to="/dashboard/notifications" class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/notifications' }">
                    <div class="menu-link-img"><img src="../../assets/icons/notifications_icon.svg" /></div>
                    <a>Benachrichtigungen</a>
                </router-link> 

                <router-link @click="toggleMobileMenu()" to="/dashboard/settings" class="menu-link-wrapper" :class="{ 'active-link': $route.path === '/dashboard/settings' }">
                    <div class="menu-link-img"><img src="../../assets/icons/settings_icon.svg" /></div>
                    <a>Einstellungen</a>
                </router-link>

                <div @click="logOut()" class="menu-link-wrapper">
                    <div class="menu-link-img"><img src="../../assets/icons/logout-icon.svg" /></div>
                    <a>Abmelden</a>
                </div>
            </div>
        </div>


        <div class="dsb-router">  
            <router-view data-aos="fade-left" />
            <div class="dashboard-footer">
                <div  class="dashboard-footer-animation">
                    <Vue3Lottie :animationData="dashboardFooterAnimation" />
                </div>
                <div>
                    <div class="footer-title">
                        <p>Hier, um zu helfen</p>
                    </div>
                    <div class="dsb-msg dsb-msg-sm dsb-msg-grey">
                        <p>Brauchst du Hilfe? Sag einfach Bescheid!</p> 
                    </div>
                    <div class="dsb-msg dsb-msg-sm dsb-msg-pink">
                        <p>Clean Data Hilfe-Center</p>
                    </div> 
                </div>
            </div>
        </div>

        <DocumentsChecking data-aos="fade" v-if="documentsVerifyingModal" />
        <UserSignature data-aos="fade" v-if="addSignatureModal"/>
        <UserDocuments data-aos="fade" v-if="addDocumentsModal"/>
        <FailedVerification data-aos="fade" v-if="failedVerifyModal"/>

        <LookingFor data-aos="fade" v-if="lookingForModal"/>

        <OrderSuccess data-aos="fade" v-if="congratsModal"/>

        <SearchForData data-aos="fade" v-if="searchDataModal"/>
    </div>
</template>
<script> 
import DocumentsChecking from '../../components/verification/DocumentsChecking.vue';
import FailedVerification from '../../components/verification/FailedVerification.vue';
 
//import dashboardFooterAnimation from '../../assets/animations/dashboard-footer-animation.json';
import dashboardFooterAnimation from '../../assets/animations/robot_anim.json';
import LookingFor from '@/components/dashboard/LookingFor.vue';
import OrderSuccess from '@/components/dashboard/OrderSuccess.vue';
import SearchForData from '@/components/dashboard/SearchForData.vue';

import UserSignature from '../../components/verification/UserSignature.vue';
import UserDocuments from '@/components/verification/UserDocuments.vue';
export default {
    components:{
        DocumentsChecking,
        FailedVerification,
        UserSignature,
        UserDocuments,

        LookingFor,
        OrderSuccess,
        SearchForData, 
    },
    computed: { },
    data(){
        return{
            dashboardFooterAnimation,

            mobMenu: true,
            mobile: false,

            slideMobMenu: false,

            UserSignatureConfirmed: false,
            showIdentity: false
        }
    },
    methods: {   
        checkIfMobile(){
            if(window.innerWidth <= 694){
                this.mobile = true;
            }else{
                this.mobile = false;
            }
        }, 
       
        toggleMobileMenu(){
            this.slideMobMenu = !this.slideMobMenu;
            this.mobMenu = !this.mobMenu;
        },  
    },
    mounted() { 
        this.checkIfMobile();

        //this.checkUserIdentity(); 
        
        window.addEventListener("resize", this.checkIfMobile); 
    },
    beforeUnmount() { 
        window.removeEventListener("resize", this.checkIfMobile); 
    },
}
</script>
<style scoped lang="scss">
@import "../../assets/dashboard.scss";   
.active-link {
        a {
            color: #FF3A67;
        }

        .menu-link-img {
            img {
                filter: brightness(0) saturate(100%) invert(36%) sepia(37%) saturate(5316%) hue-rotate(327deg) brightness(105%) contrast(101%);
            }
        }
    }

    .dsb-wrapper{
        display: flex;
        width: 100%; 
    }

    .dsb-router{ 
        width: 100%;
    }

    .dsb-menu-wrapper{
        padding-left: 125px;
        margin-top: 26px;
        padding-right: 54px;

        width: 398px;
        height: 65vh;

        border: 1px solid transparent;
        border-right-color: #DCDCDC;
    }

    .dsb-logo{
        display: block;
        width: 120px;
        margin-bottom: 52px;

        img{
            width: 100%;
        }
    } 

    .dashboard-footer{
        display: flex;
        gap: 17px;
        justify-content: center;
        align-items: center;
    }

    .dashboard-footer-animation{
        width: 250px;
        height: 250px;
    }

    .footer-title{
        margin-bottom: 14px;

        p{
          color: #000000;
          font-weight: 500;
          font-size: 26px;  
        }
    } 


    /* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1470 */
@media (min-width: 2304px) {
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
    .dsb-menu-wrapper { 
        width: 500px;
    }

    .dsb-logo {
        width: 130px;
        margin-bottom: 65px;
    } 

    .footer-title p { 
        font-size: 28px;
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .dsb-menu-wrapper { 
        width: 600px;
    }

    .dsb-logo {
        width: 140px;
        margin-bottom: 75px;
    }
 
    .footer-title p { 
        font-size: 30px;
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .dsb-menu-wrapper { 
        width: 700px;
    }

    .dsb-logo {
        width: 150px;
        margin-bottom: 85px;
    } 

    .footer-title p { 
        font-size: 32px;
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .dsb-menu-wrapper { 
        width: 850px;
    }

    .dsb-logo {
        width: 170px;
        margin-bottom: 100px;
    } 

    .footer-title p { 
        font-size: 36px;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
    .dsb-menu-wrapper {
        padding: 0 55px;   
        width: 400px; 
    } 
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) {
    .dsb-menu-wrapper {
        padding: 0 50px;   
        width: 380px; 
    }

    .dsb-logo {
        width: 110px;
        margin-bottom: 50px;
    }

    .footer-title p { 
        font-size: 24px;
    }
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .dsb-menu-wrapper {
        height: 75vh;
    }
}

/*1536-864*/
@media(max-width:1536px) {
    .dsb-menu-wrapper {
        padding: 0 40px;
        width: 340px;
    }
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .dsb-menu-wrapper {
        padding: 0 40px;
        width: 310px;
    } 

    .footer-title p {
        font-size: 22px;
    }
}

/*1366-638*/
@media (max-width:1366px) {
    
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {

}

/* 1200-1920 */
@media (max-width:1200px) {
    .dsb-menu-wrapper{
        padding: 0 25px; 
        width: 290px;
    } 
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
    .footer-title p {
        font-size: 20px;
    }  

    .dsb-logo {
        width: 90px;
        margin-bottom: 40px;
    }

    .dsb-menu-wrapper {
        padding: 0 25px;
        width: 270px;
    }

    .dashboard-footer-animation {
        width: 200px;
        height: 200px;
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
    .footer-title p {
        font-size: 22px;
    }
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
    .dsb-menu-wrapper{ 
        margin: 0;
        padding: 0;
        margin-left: 20px;
        margin-top: 25px;
        height: 35px;
        width: 35px;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
    } 
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
    .dashboard-footer-animation {
        width: 176px;
        height: 176px;
    }

    .dashboard-footer{
        padding: 0 20px;
        gap: 10px;
    }
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .dashboard-footer-animation {
        width: 150px;
        height: 150px;
    }

    .footer-title p {
        font-size: 20px;
    }
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) { 
    .dsb-menu-wrapper { 
        margin-left: 15px;
        margin-top: 20px;
        width: 25px;
        height: 25px; 
    } 

    .footer-title p {
        font-size: 18px;
    }

    .dashboard-footer-animation {
        width: 140px;
        height: 140px;
    }
}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
    .dashboard-footer-animation {
        width: 130px;
        height: 130px;
    }
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
    .dsb-menu-wrapper { 
        margin-left: 10px;
        margin-top: 15px;
    }
 
    .footer-title p {
        font-size: 16px;
    }
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>