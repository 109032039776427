import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSignaturePad from 'vue-signature-pad';

import globalMixin from './mixins/globalMixin';

import Vue3Lottie from 'vue3-lottie';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { VueReCaptcha } from 'vue-recaptcha-v3';

import "./axios";

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueSignaturePad);
app.use(VueReCaptcha, { siteKey: '6LcEsggrAAAAALy548lLgAgcxQSoZUtbT2RNfFyB' });
app.use(Vue3Lottie);

store.dispatch('darkMode/initializeDarkMode');

app.mixin(globalMixin);

app.mount('#app');