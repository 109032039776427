import axiosInstance from "@/axios";
import router from '../../router'
import Swal from 'sweetalert2';
const axios = axiosInstance();

export const state = {
    currentUser: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,

    authError: null,

    documentsWereRejected: null,
    signtureWasRejected: null,

    userPersonalData: null,


    verificationEmail: null,

    registerPsw: null,
    confirmRegisterPsw: null,

    expireVar: null,
    signatureVar: null,
    idVar: null,
    hashVar: null,
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('user', newValue)
        localStorage.setItem("user", JSON.stringify(newValue));
    },
    SET_AUTH_MSG(state, authError) {
        state.authError = authError
    },

    setDocumentsWereRejected(state, documentsWereRejected) {
        state.documentsWereRejected = documentsWereRejected
    },
    setSigntureWasRejected(state, signtureWasRejected) {
        state.signtureWasRejected = signtureWasRejected
    },

    setUserPersonalData(state, userPersonalData) {
        state.userPersonalData = userPersonalData
    },

    setRegisterPsw(state, registerPsw) {
        state.registerPsw = registerPsw
    },

    setConfirmRegisterPsw(state, confirmRegisterPsw) {
        state.confirmRegisterPsw = confirmRegisterPsw
    },

    setExpireVar(state, expireVar) {
        state.expireVar = expireVar
    },

    setSignatureVar(state, signatureVar) {
        state.signatureVar = signatureVar
    },

    setIdVar(state, idVar) {
        state.idVar = idVar
    },

    setHashVar(state, hashVar) {
        state.hashVar = hashVar
    },

    setVerificationEmail(state, verificationEmail) {
        state.verificationEmail = verificationEmail
    },
}

export const getters = {
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser: state => state.currentUser,
    authError: state => state.authError,

    documentsWereRejected: state => state.documentsWereRejected,
    signtureWasRejected: state => state.signtureWasRejected,

    userPersonalData: state => state.userPersonalData,

    registerPsw: state => state.registerPsw,
    confirmRegisterPsw: state => state.confirmRegisterPsw,


    expireVar: state => state.expireVar,
    signatureVar: state => state.signatureVar,
    idVar: state => state.idVar,
    hashVar: state => state.hashVar,

    verificationEmail: state => state.verificationEmail,
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    async logIn({ commit }, { email, password } = {}) {
        try {
            const result = await axios.post("/customer/login", {
                email,
                password,
            });
            commit('SET_CURRENT_USER', result.data.customer);
            localStorage.setItem('token', result.data.token);
            commit("SET_AUTH_MSG", null);
        } catch (error) {
            commit("SET_AUTH_MSG", error.response.data.message);
            throw error;
        }
    },


    async registerStep1({ commit }, newUser) {
        try {
            await axios.post("/customer/register", newUser);
            // router.push({ name: 'Home' });
            commit("SET_AUTH_MSG", null);
        } catch (error) {
            console.log(error)
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "Ein unerwarteter Fehler ist aufgetreten.";
            commit("SET_AUTH_MSG", errorMessage);
            throw new Error(errorMessage);
        }
    },

    async registerStep2({ commit }, {newUser, id, hash, expires, signature}) { 
        try {
            await axios.post(`/customer/verify-email/${id}/${hash}?expires=${expires}&signature=${signature}`, newUser);
            commit("SET_AUTH_MSG", null);
         
            Swal.fire({
                title: 'Registration Successful!',
                text: '',
                icon: 'success'
            }).then(()=>{ 
                router.push({ name: 'Login' });
            });
        } catch (error) {
            console.log(error)
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "Ein unerwarteter Fehler ist aufgetreten.";
            commit("SET_AUTH_MSG", errorMessage);
            throw new Error(errorMessage);

            // Swal.fire({
            //     title: 'Validation Error!',
            //     text: '',
            //     icon: 'error', 
            // })
        }
    },

    async logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        await axiosInstance({ requiresAuth: true }).post("/customer/logout")
            .then(() => {
                commit('SET_CURRENT_USER', null);
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                this.authUser = null;
                router.push({ name: 'Home' });
            });
    },

    async checkUserIdentity({ commit }) {
        try {
            const result = await axiosInstance({ requiresAuth: true }).get("/customer/personalData");

            const user = result.data;

            if (!user.verified) {
                if (user.showSignatureUploadWindow) {
                    commit("application/setAddSignatureModal", true, { root: true });
                } else if (user.showDocumentsUploadWindow) {
                    commit("application/setAddDocumentsModal", true, { root: true });
                } else if (user.showSignatureRejectedWindow) {
                    commit("setSigntureWasRejected", true);
                    commit("application/SET_FAILEDVERIFY_MODAL", true, { root: true });
                } else if (user.showDocumentsRejectedWindow) {
                    commit("setDocumentsWereRejected", true);
                    commit("application/SET_FAILEDVERIFY_MODAL", true, { root: true });
                } else {
                    commit("application/SET_DOCUMENTSVERIFY_MODAL", true, { root: true });
                }

                router.push({ name: 'Overview' });
            } else { 
                return true;
            } 
        } catch (error) {
            console.log('in catch')
            console.log(error)
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "Ein unerwarteter Fehler ist aufgetreten.";
            commit("SET_AUTH_MSG", errorMessage);
            throw new Error(errorMessage);
        }
    },

    async requestCleanData({ commit }) {
        try {
            const response = await axiosInstance({ requiresAuth: true }).post("/customer/requestCleanData");
           
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Ein unerwarteter Fehler ist aufgetreten.";
            commit("SET_AUTH_MSG", errorMessage);
            throw new Error(errorMessage);
        }
    },
    

    async getProfilelData({ commit }) {
        try {
            const result = await axiosInstance({ requiresAuth: true }).get("/customer/personalData");
            commit("setUserPersonalData", result.data);

        } catch (error) {
            console.log(error)
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "Ein unerwarteter Fehler ist aufgetreten.";
            commit("SET_AUTH_MSG", errorMessage);
            throw new Error(errorMessage);
        }
    },
}

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
