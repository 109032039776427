import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue'; 
import Login from '@/components/login/Login.vue'; 
import store from '@/store';


import Register from '../components/register/Register.vue';
import GoConfirm from '../components/register/GoConfirm.vue';
import SetPassword from '../components/register/SetPassword.vue';
import NextPhase from '../components/register/NextPhase.vue';  

import Dashboard from '@/views/dashboard/Dashboard.vue';
import RequestsOverview from '@/views/dashboard/RequestsOverview.vue';
import Connections from '@/views/dashboard/Connections.vue';
import Notifications from '@/views/dashboard/Notifications.vue';
import RequestsDetails from '@/views/dashboard/RequestsDetails.vue';
import Settings from '@/views/dashboard/Settings.vue';

 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/goConfirm',
    name: 'GoConfirm',
    component: GoConfirm
  }, 
 
  {
    path: '/email-verify',
    name: 'SetPassword',
    component: SetPassword,
    beforeEnter: (to, from, next) => { 
      if (!to.query.id || !to.query.hash || !to.query.signature || !to.query.expires) { 
        next({ name: 'Login' }); 
      } else {
        next();  
      }
    }
  },
  {
    path: '/nextPhase',
    name: 'NextPhase',
    component: NextPhase
  },

 
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: RequestsOverview
      },
      {
        path: 'connections',
        component: Connections
      },
      {
        path: 'notifications',
        component: Notifications
      },
      {
        path: 'details',
        component: RequestsDetails
      },
      {
        path: 'settings',
        component: Settings
      },
      {
        path: '',
        redirect: '/dashboard/overview', 
      },

    ],
  
  },
 
];

const router = createRouter({
  history: createWebHistory(),
  routes, 
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0, left: 0 };
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ['/', '/login', '/register', '/forgotPassword', '/nextPhase', '/email-verify', '/goConfirm'];
  const authRequired = !publicPages.includes(routeTo.path);

  let loggedUser = localStorage.getItem("user") 
    ? JSON.parse(localStorage.getItem("user")) 
    : store.state.auth.currentUser;
  
  if (authRequired && !loggedUser) {
    return next('/login');
  }
 
  next();  
});
export default router;
