<template>
    <Loader v-if="loading"/>
    <div class="dsb-router-wrapper" v-else>
        <div class="dsb-router-header">
            <div class="dsb-title dsb-title-lg">
                <p>Wo sind Ihre Daten?</p>
            </div> 
        </div>

        <div class="dsb-router-tabs">
            <div @click="activeTab = 1" class="tab-el tab-el-active">
                <p>Alle anzeigen (8)</p>
            </div>
            <div @click="activeTab = 2" class="tab-el ">
                <p>Karte anzeigen</p>
            </div>
        </div>

        <div v-if="activeTab == 1" class="tab active-tab companies-list">
            <div class="company" v-for="company in companies" :key="company.id">
                <div class="img"><img :src="require(`@/assets/icons/${company.img }`)"/></div>
                <div class="name"><p>{{company.name}}</p></div>
                <div class="type"><p>{{company.type}}</p></div>
                <div class="joined"><p>Joined in {{company.joined}}</p></div>
                <button @click="search()" class="dsb-btn dsb-btn-sm dsb-btn-light">
                    <p>Daten Anfragen</p>
                </button>
            </div>  

            <div class=" clean-data">
                <div class="company">
                    <div class="img"><img src="../../assets/icons/connection_datasaver_icon.svg"/></div>
                    <div class="name"><p>Clean Data</p></div>
                    <div class="type"><p>Privacy</p></div>
                    <div class="joined"><p>Joined in Nov,2024</p></div>
                </div>
                 
                <div class="message">
                    <p>Nach Bedarf aufgeführt</p>
                </div>
            </div>
        </div>

        <div v-if="activeTab == 2" class="tab">
            map
        </div>
    </div>
</template>
<script>
import Loader from '@/components/Loader.vue'; 
export default {
    components:{
        Loader
    },
    computed: {  },
    data(){
        return{
            loading : false,

            activeTab: 1,

            companies:[
                {
                    id: 1,
                    img: 'connection_default_icon.svg',
                    name: 'Freepik',
                    type: 'File sharing & website',
                    joined: 'Sep,2023',
                },
                {
                    id: 2,
                    img: 'connection_default_icon.svg',
                    name: 'Aliexpress',
                    type: 'Unspecified Industry',
                    joined: 'Oct,2024',
                }, 
                {
                    id: 3,
                    img: 'connection_default_icon.svg',
                    name: 'Shutterstock',
                    type: 'Computers & Technology',
                    joined: 'Jan,2022',
                }, 
                {
                    id: 4,
                    img: 'connection_default_icon.svg',
                    name: 'Adobe',
                    type: 'Unspecified Industry',
                    joined: 'Apr,2024',
                }, 
                {
                    id: 5,
                    img: 'connection_default_icon.svg',
                    name: 'Behance',
                    type: 'Career',
                    joined: 'Apr,2023',
                }, 
                {
                    id: 6,
                    img: 'connection_default_icon.svg',
                    name: 'Canva',
                    type: 'Computers & Technology',
                    joined: 'Dec,2023',
                }, 
                {
                    id: 7,
                    img: 'connection_default_icon.svg',
                    name: 'Spotify',
                    type: 'Music',
                    joined: 'Feb,2024',
                },
            ]
        }
    },
    methods:{
        search(){
            this.$store.commit("application/SET_SEARCHDATA_MODAL", true);
        }
    },
    async mounted(){
        if(!this.isUserVerified){
            this.loading = true;
            await this.checkUserIdentity();
            this.loading = false;
        }else{
            this.loading = false;
            return
        }
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/dashboard.scss";  

.companies-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 55px 0;
}

.company{
    box-shadow: 4px 6px 14px #56597A4D;
    border-radius: 20px;
    width: 22%;
    padding:30px;

    .img{
        width: 84px;
        height: 84px;
        margin: 0 auto;
        margin-bottom: 16px;
        img{
            width: 100%;
        }
    }

    .name{
        margin-bottom: 10px;
        text-align: center;
        p{
            font-size: 24px;
            color: #000000;
        }
    }

    .type{
        margin-bottom: 10px;
        text-align: center;
        p{
            font-size: 16px;
            color: #666666;
        }
    }

    .joined{
        margin-bottom: 22px;
        text-align: center;
        p{
            font-size: 12px;
            color: #666666;
        }
    } 
}

.clean-data{
    box-shadow: 4px 6px 14px #56597A4D;
    border-radius: 20px;
    width: 22%; 

    .company{
        box-shadow: none !important;
        border-radius: none !important;
        width: 100% !important;
        padding-bottom: 0 !important;
    }

    .message{
        text-align: center;
        padding: 17px 0;
        padding-bottom: 24px;
        border: 1px solid transparent;
        border-top-color: #7070704d;
        p{
            font-size: 16px;
            color: #FF3A67;
            font-weight: 500;
        }
    }
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1470 */
@media (min-width: 2304px) {
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
    .company{ 
        width: 22%;
        padding: 30px;

        .img{
            width: 90px;
            height: 90px;
            margin-bottom: 16px;
        
        }

        .name{
            margin-bottom: 10px;
            p{
                font-size: 26px;
            }
        }

        .type{
            margin-bottom: 10px;
            p{
                font-size: 18px;
            }
        }

        .joined{
            margin-bottom: 22px;
            p{
                font-size: 14px;
            }
        } 
    }

    .clean-data .message p{
        font-size: 18px;
    }
}
 

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
}

/*2573-1206*/
@media(min-width: 2573px) {

}

/* 2880-1670 */
@media(min-width: 2880px) {
    .company{ 
        width: 22%;
        padding: 30px;

        .img{
            width: 100px;
            height: 100px;
            margin-bottom: 16px;
        
        }

        .name{
            margin-bottom: 10px;
            p{
                font-size: 28px;
            }
        }

        .type{
            margin-bottom: 10px;
            p{
                font-size: 20px;
            }
        }

        .joined{
            margin-bottom: 22px;
            p{
                font-size: 16px;
            }
        } 
    }

    .clean-data .message p{
        font-size: 20px;
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .company{ 
        width: 22%;
        padding: 30px;

        .img{
            width: 110px;
            height: 110px;
            margin-bottom: 20px; 
        }

        .name{
            margin-bottom: 15px;
            p{
                font-size: 30px;
            }
        }

        .type{
            margin-bottom: 15px;
            p{
                font-size: 22px;
            }
        }

        .joined{
            margin-bottom: 28px;
            p{
                font-size: 18px;
            }
        } 
    }

    .clean-data .message p{
        font-size: 22px;
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .company{ 
        width: 22%;
        padding: 30px;

        .img{
            width: 130px;
            height: 130px;
            margin-bottom: 25px; 
        }

        .name{
            margin-bottom: 20px;
            p{
                font-size: 34px;
            }
        }

        .type{
            margin-bottom: 20px;
            p{
                font-size: 26px;
            }
        }

        .joined{
            margin-bottom: 32px;
            p{
                font-size: 22px;
            }
        } 
    }

    .clean-data .message p{
        font-size: 26px;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
    .company{ 
        padding:25px;

        .img{
            width: 80px;
            height: 80px;
            
        }

        .name{ 
            p{
                font-size: 22px; 
            }
        }

        .type{ 
            p{
                font-size: 14px; 
            }
        }

        .joined{ 
            p{
                font-size: 12px; 
            }
        } 
    }

    .clean-data{ 
        .message{ 
            p{
                font-size: 14px; 
            }
        }
    }
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) {
 
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .company{  
        .img{
            width: 75px;
            height: 75px;  
        }

        .name{ 
            p{
                font-size: 20px; 
            }
        } 
    }
}

/*1536-864*/
@media(max-width:1536px) {
 
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .company{    
        padding: 20px;

        .img{
            width: 75px;
            height: 75px;  
        }

        .name{ 
            p{
                font-size: 18px; 
            }
        }

        .type{ 
            p{
                font-size: 12px; 
            }
        }

        .joined{ 
            p{
                font-size: 10px; 
            }
        }
    }

    .clean-data .message p {
        font-size: 12px;
    }
}

/*1366-638*/
@media (max-width:1366px) {
    .companies-list{ 
        justify-content: initial;
        gap: 40px ;
    }
 
    .clean-data,
    .company {   
        width: 30%;   
    }

}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {

}

/* 1200-1920 */
@media (max-width:1200px) {
  
}

/*1134x712*/
@media(max-width:1134px) { 

    .company,
    .clean-data{   
        width: 29%;  
    }  
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
    .company{    
        .img{
            width: 65px;
            height: 65px;  
        }

        .name{ 
            p{
                font-size: 16px; 
            }
        }

        .type{ 
            p{
                font-size: 10px; 
            }
        }

        .joined{ 
            p{
                font-size: 8px; 
            }
        }
    }

    .clean-data {     
        .message p {
            font-size: 10px;
        }
    }
 
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
    .companies-list{ 
        justify-content: space-between;
        gap: 40px 0;
    }

    .company{   
        width: 45%; 

        .img{
            width: 60px;
            height: 60px;  
        } 
    }

    .clean-data{
        width: 45%; 
    }
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
 
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
 
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) { 
    .companies-list{  
        gap: 35px 0;
    }

    .company{    

        .img{
            margin-bottom: 10px;
        }

        .name{ 
            margin-bottom: 10px;
        }

        .type{ 
            margin-bottom: 10px;
        }

        .joined{ 
            margin-bottom: 10px;
        }
    }

    .clean-data .message { 
        padding-bottom: 17px;
    }
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
 
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
    .company{
        padding: 20px 15px;    
    }
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){

}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
 

}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {
 
}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
    .company{    
        .name{ 
            p{
                font-size: 14px; 
            }
        } 
    }
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
 
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
    .company{
        padding: 15px 10px;    
    }
}
</style>