<template>
    <div class="modal-wrapper colored-modal-wrapper user-identity">
        <div class="modal"> 
            <div class="search-animation">
                <Vue3Lottie :animationData="searchingAnimation"/>
            </div>

            <div class="modal-title modal-title-sm">
                <p>Bist du sicher?</p>
            </div>
 
            <div class="modal-msg">
                <p> 
                    Bist du sicher, dass wir weiterhin nach deinen<br> Daten suchen dürfen?
                </p>
            </div> 
 
            <div class="buttons-wrapper">
                <button @click="close()" class="modal-btn modal-btn-sm modal-btn-white">
                    <p>Nein</p>
                </button>
                
                <button @click="open()"  class="modal-btn modal-btn-sm">
                    <p>Ja</p>
                </button>
    
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import searchingAnimation from '../../assets/animations/search-animation.json'
export default {
    computed: {
        ...mapGetters('auth', ['currentUser']),
        ...mapGetters('application', ['searchDataModal', 'congratsModal']), 
    },
    data(){
        return{
            searchingAnimation
        }
    },
    methods:{
        close(){
            this.$store.commit("application/SET_SEARCHDATA_MODAL", false);
        },

        open(){
            this.$store.commit("application/SET_SEARCHDATA_MODAL", false);
            this.$store.commit("application/SET_CONGRATSMODAL_MODAL", true);
        }
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/modals.scss";

.user-identity{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
 
.search-animation{
    width: 193px;
    height: 193px;
    margin: 0 auto;
    margin-bottom: 20px; 
} 

.buttons-wrapper{
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 25px;

    .modal-btn{   
        margin: initial;    
    }
}

/* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1470 */
@media (min-width: 2304px) {
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .search-animation{
        width: 220px;
        height: 220px; 
    } 

    .buttons-wrapper{ 
        gap: 35px; 
    }
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .search-animation{
        width: 230px;
        height: 230px; 
    } 

    .buttons-wrapper{ 
        gap: 45px; 
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .buttons-wrapper{ 
        gap: 60px; 
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .search-animation{
        width: 250px;
        height: 250px; 
    } 

    .buttons-wrapper{ 
        gap: 80px; 
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
}

/*1688-1080*/
@media(max-width: 1688px) {
    .search-animation{
        width: 180px;
        height: 180px; 
    } 

    .buttons-wrapper{ 
        gap: 30px; 
    }
}

/*1680-1080*/
@media(max-width: 1680px) {
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .search-animation{
        width: 170px;
        height: 170px; 
    } 

    .buttons-wrapper{ 
        gap: 35px; 
    }
}

/*1536-864*/
@media(max-width:1536px) {
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    .search-animation{
        width: 160px;
        height: 160px; 
    } 

}

/*1366-638*/
@media (max-width:1366px) {
    .search-animation{
        width: 150px;
        height: 150px; 
    } 

    .buttons-wrapper{ 
        gap: 40px; 
    }
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
}

/* 1200-1920 */
@media (max-width:1200px) {
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
    .search-animation{
        width: 140px;
        height: 140px; 
    } 
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
    .search-animation{
        width: 130px;
        height: 130px; 
    }
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
    .search-animation{
        width: 120px;
        height: 120px; 
    }

    .buttons-wrapper{ 
        gap: 30px; 
    }
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
    .modal-btn-sm {
        width: 168px;
        padding: 10px 0;
        p {
            font-size: 14px;
        }
    } 

    .buttons-wrapper{ 
        gap: 11px; 
    }
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
    .modal { 
        padding: 30px 20px;
    }
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
    .modal { 
        padding: 30px 15px;
    }

    .search-animation{
        width: 110px;
        height: 110px; 
    }
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {

}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
    .modal-btn-sm { 
        padding: 8px 0;
        p {
            font-size: 12px;
        }
    } 
    
    .search-animation{
        width: 100px;
        height: 100px; 
    }
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>