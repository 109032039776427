<template>
<div class="dsb-router-wrapper">
    <div class="dsb-router-header">
        <div class="dsb-title dsb-title-lg">
            <p>
                <span v-if="activeTab == 1">Mein Konto</span>
                <!-- <span v-if="activeTab == 2">Meine Kreditkarte</span> -->
            </p>
        </div>
    </div>

    <div class="dsb-router-tabs">
        <div @click="activeTab = 1" class="tab-el " :class="{'tab-el-active' :  activeTab == 1}">
            <p>Konto</p>
        </div>
        <div @click="activeTab = 2" class="tab-el " :class="{'tab-el-active' :  activeTab == 2}">
            <p>Abrechnung</p>
        </div>
        <div @click="activeTab = 3" class="tab-el " :class="{'tab-el-active' :  activeTab == 3}">
            <p>Abonnement</p>
        </div>
    </div>

    <Loader v-if="loading"/>

    <div v-else>

        <div v-if="activeTab == 1" class="tab active-tab account">
            <div class="user-info">
                <div class="user-details">
                    <div class="avatar">
                        <img />
                    </div>
                    <div class="user-text">
                        <div class="dsb-title dsb-title-ss">
                            <p>{{userInfo.firstName}} {{userInfo.lastName}}</p>
                        </div>
                        <div class="dsb-msg dsb-msg-grey">
                            <p>{{userInfo.email}}</p>
                        </div>
                    </div>
                </div>
                <button class="dsb-btn">
                    <p>Profilbild aktualisieren</p>
                </button>
            </div>

            <div class="profile-edit">
                <div class="dsb-msg">
                    <p>Profil</p>
                </div>
                <div class="dsb-msg dsb-msg-grey">
                    <p>Statt dem Platzhalter müsste etwas relevantes stehen.</p>
                </div>

                <form class="edit-form">
                    <div class="inputs-wrapper">
                        <div class="inputs-column">
                            <div class="input-wrapper">
                                <label class="dsb-msg">Vorname</label>
                                <input class="edit-input" v-model="userInfo.firstName">
                            </div>
                            <div class="input-wrapper">
                                <label class="dsb-msg">Nachname</label>
                                <input class="edit-input" v-model="userInfo.lastName">
                            </div>
                            <div class="input-wrapper">
                                <label class="dsb-msg">Geburtsdatum</label>
                                <input class="edit-input" v-model="userInfo.birthday">
                            </div>
                            <div class="input-wrapper">
                                <label class="dsb-msg">E-Mail</label>
                                <input class="edit-input" v-model="userInfo.email">
                            </div>
                        </div>
                        <div class="inputs-column">
                            <div class="input-wrapper">
                                <label class="dsb-msg">Adresse</label>
                                <input class="edit-input" v-model="userInfo.address">
                            </div>
                            <div class="inputs-row">
                                <div class="input-wrapper xl">
                                    <label class="dsb-msg">Ort</label>
                                    <input class="edit-input" v-model="userInfo.city">
                                </div>
                                <div class="input-wrapper sm">
                                    <label class="dsb-msg">PLZ</label>
                                    <input class="edit-input" v-model="userInfo.zip">
                                </div>
                            </div>
                            <div class="input-wrapper">
                                <label class="dsb-msg">Land</label> 
                                <select placeholder="Land" class="edit-input" v-model="userInfo.country">
                                    <option value="Land" disabled>
                                        Land
                                    </option>
                                    <option value="Schweiz">
                                        Schweiz
                                    </option>
                                    <option value="Deutschland">
                                        Deutschland
                                    </option>
                                    <option value="Österreich">
                                        Österreich
                                    </option>
                                </select>
                            </div>
                            <div class="input-wrapper">
                                <label class="dsb-msg">Telefonnnummer</label>
                                <input class="edit-input" v-model="userInfo.mobile">
                            </div>
                        </div>
                    </div>
                    <button class="dsb-btn dsb-btn-lg">
                        <p>Abmelden</p>
                    </button>
                </form>
            </div>
        </div>

        <div v-if="activeTab == 2" class="tab active-tab invoice">
            <div class="invoice-wrapper">
                <div class="card-info">
                    <div class="dsb-title dsb-title-sm">
                        <p>Karteninformationen</p>
                    </div>

                    <div class="dsb-msg"> 
                        <p>Gib die Details der Kreditkarte an, von<br> der das Geld abgebucht werden soll</p>
                    </div>

                    <div class="card">
                        <div class="card-header"> 
                            <div class="card-img visa">
                            <img src="../../assets/icons/visa.svg"/></div>
                            <div class="card-img mastercard">
                            <img src="../../assets/icons/mastercard.svg"/></div>
                        </div>

                        <div class="input-group">
                            <div class="label">
                                <p>Kartennummer</p>
                            </div>
                            <div class="card-number">
                                <input placeholder="****">
                                <input placeholder="****">
                                <input placeholder="****">
                                <p>2356</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="input-group date">
                                <div class="label">
                                    <p>Monat und Jahr</p>
                                </div>
                                <div class="date-input">
                                    <input placeholder="00">
                                    <span>/</span>
                                    <input placeholder="00">
                                </div>
                            </div>

                            <div class="input-group cvv">
                                <div class="label">
                                    <p>CVV code</p>
                                </div>
                                <input placeholder="***">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-personal-info">
                    <div class="dsb-title dsb-title-sm">
                        <p>Persönliche Informationen</p>
                    </div>

                    <div class="inputs-row">
                        <div class="input-wrapper md">
                            <input class="edit-input" placeholder="Vorname" v-model="userInfo.firstName">
                        </div>
                        <div class="input-wrapper md">
                            <input class="edit-input" placeholder="Nachname" v-model="userInfo.lastName">
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <input class="edit-input" placeholder="Kanton" v-model="userInfo.address">
                    </div>
                    <div class="inputs-row">
                        <div class="input-wrapper md">
                            <input class="edit-input" placeholder="Ort" v-model="userInfo.city">
                        </div>
                        <div class="input-wrapper md">
                            <input class="edit-input" placeholder="PLZ" v-model="userInfo.zip">
                        </div>
                    </div>
                    <div class="input-wrapper">  
                        <select placeholder="Land" class="edit-input" v-model="userInfo.country">
                            <option value="Land" disabled>
                                Land
                            </option>
                            <option value="Schweiz">
                                Schweiz
                            </option>
                            <option value="Deutschland">
                                Deutschland
                            </option>
                            <option value="Österreich">
                                Österreich
                            </option>
                        </select>
                    </div>
                    <div class="input-wrapper">
                        <input class="edit-input" placeholder="Telefonnnummer" v-model="userInfo.mobile">
                    </div>
                </div>
            </div>
            <button class="dsb-btn dsb-btn-lg">
                <p>Änderungen speichern</p>
            </button>
        </div>
    </div>
</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
export default {
    components:{
        Loader
    },
    data() {
        return {
            activeTab: 1,

            userInfo:{
                firstName: '',
                lastName: '',
                birthday: '',
                email: '',
                address: '',
                city: '',
                zip: '',
                country: '',
                mobile: '',
            },

            loading: false,
        }
    },
    async mounted() {
        this.loading = true;
        await this.getProfilelData();
        this.userInfo = this.userPersonalData;
        this.loading = false;
    }
}
</script>

<style lang="scss" scoped>
 @import "../../assets/dashboard.scss";
 @import "../../assets/modals.scss";

 .dsb-router-wrapper {
     .edit-input {
         background-color: #F9F9F9 !important;
         border: none !important;
         color: rgba(0, 0, 0, 0.8) !important;

         &::placeholder {
             color: #00000080;
             //font-size: 14px;
         }
     }

     .input-wrapper label {
         font-size: 16px;
         margin-left: 10px;
         margin-bottom: 10px;
         display: block;
         color: rgba(0, 0, 0, 0.8);
     }

     .xl {
         width: 65%;
     }

     .sm {
         width: 30%;
     }

     .md {
         width: 48%;
     }

     .inputs-row {
         display: flex;
         justify-content: space-between;
     }
 }

 ///////////

 .account {
     .dsb-btn {
         margin: initial !important;
         border-radius: 20px !important;
     }
 }

 .user-info {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-bottom: 30px;
     border-bottom: 1px solid rgba(112, 112, 112, 0.3);

     .dsb-btn {
         width: 212px;

         p {
             font-size: 14px;
         }
     }
 }

 .user-details {
     display: flex;
     gap: 24px;
     align-items: center;
 }

 .avatar {
     width: 122px;
     height: 122px;
     background: #D9D9D9;
     border-radius: 8px;

     img {
         width: 100%;
     }
 }

 .profile-edit {
     padding-top: 30px;

     .edit-form {
         margin-top: 30px;
         width: 78%;
     }

     .inputs-wrapper {
         display: flex;
         gap: 80px;
         margin-bottom: 30px;
     }

     .inputs-column {
         width: 50%;
     }

     .dsb-btn {
         width: 172px;
     }
 }

 ///////////

 .invoice {
     .dsb-btn {
         margin-top: 70px;
     }
 }

 .invoice-wrapper {
     display: flex;
     gap: 80px;
     width: 86%;

     .card-info {
         width: 45%;
     }

     .card-personal-info {
         width: 50%;
     }
 }

 .card-info {
     .dsb-msg {
         margin: 20px 0 33px;

         a,
         p {
             color: #00000080;
         }
     }

     .card {
         padding: 37px;
         width: 100%;
         box-shadow: 0px 1px 30px #56597A;
         border-radius: 20px;

         .card-header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 32px;

            .card-img{
                width: 44px;

                img{
                    width: 100%;
                }
            }
         }

         .input-group {
             .label p {
                 font-size: 16px;
                 color: #00000080;
                 margin-bottom: 5px;
             }
         }

         .card-number {
             display: flex;
             gap: 11px;
             width: 100%;
             padding-bottom: 12px;
             border-bottom: 1px solid #A7ADB4;
             align-items: center;

             input,
             p {
                 font-size: 20px;
                 color: #A7ADB4;
             }

             input {
                 width: 15%;
                 border: none;
                 outline: none;
             }
         }

         .row {
             margin-top: 36px;
             display: flex;
             justify-content: space-between;
         }

         .date {
             width: 35%;

             .date-input {
                 display: flex;
                 align-items: center;
                 gap: 11px;

                 span {
                     font-size: 20px;
                     color: rgba(0, 0, 0, 0.5);
                 }

                 input {
                     font-size: 20px;
                     width: 50%;
                     color: rgba(0, 0, 0, 0.5);
                     padding-bottom: 10px;
                     border: none;
                     outline: none;
                     border-bottom: 1px solid #A7ADB4;
                 }
             }
         }

         .cvv {
             width: 25%;

             input {
                 font-size: 20px;
                 width: 100%;
                 color: rgba(0, 0, 0, 0.5);
                 border: none;
                 border-bottom: 1px solid #A7ADB4;
                 margin-top: 3px;
                 outline: none;
             }
         }
     }
 }

 .card-personal-info {

     .dsb-title,
     .input-wrapper {
         margin-bottom: 35px;
     }
 }

 /////
 /// 
 /// 
 /// 

 /* imac 24" */
 /* 2048 x 1152 */
 @media (min-width: 2048px) {}

 /* 2304-1470 */
 @media (min-width: 2304px) {}

 /* 2470 - 1328 */
 @media (min-width: 2470px) {
     .dsb-router-wrapper .input-wrapper label {
         font-size: 18px;
     }

     .user-info .dsb-btn {
         width: 220px;

         p {
             font-size: 16px;
         }
     }

     .profile-edit {
         .dsb-btn {
             width: 180px;
         }
     }

     .avatar {
         width: 130px;
         height: 130px;
     }

     .user-info {
         padding-bottom: 40px;
     }

     .profile-edit {
         padding-top: 40px;
     }

     .profile-edit .edit-form {
         margin-top: 40px;
     }

     /// 
     .card-info .card .input-group .label p {
         font-size: 18px;
         margin-bottom: 10px;
     }

     .card-info .card .card-number input,
     .card-info .card .date .date-input input,
     .card-info .card .cvv input,
     .card-info .card .card-number p {
         font-size: 22px;
     }

     .card-info .card .card-header { 
        margin-bottom: 35px;
            
        .card-img{
            width: 50px; 
        }
    }
 }

 /* imac retina */
 /*2560-1440*/
 @media (min-width: 2560px) {}

 /*2573-1206*/
 @media(min-width: 2573px) {}

 /* 2880-1670 */
 @media(min-width: 2880px) {
     .dsb-router-wrapper .input-wrapper label {
         font-size: 20px;
     }

     .user-info .dsb-btn {
         width: 240px;

         p {
             font-size: 18px;
         }
     }

     .profile-edit {
         .dsb-btn {
             width: 200px;
         }
     }

     .avatar {
         width: 150px;
         height: 150px;
     }

     .user-info {
         padding-bottom: 50px;
     }

     .profile-edit {
         padding-top: 50px;
     }

     .profile-edit .edit-form {
         margin-top: 50px;
     }

     ///
     .card-info .card .input-group .label p {
         font-size: 20px;
         margin-bottom: 15px;
     }

     .card-info .card .card-number input,
     .card-info .card .date .date-input input,
     .card-info .card .cvv input,
     .card-info .card .card-number p {
         font-size: 24px;
     }

     .card-info .card .card-header { 
        margin-bottom: 40px;
            
        .card-img{
            width: 55px; 
        }
    }
 }

 /* 3000-1870 */
 @media(min-width: 3000px) {}

 /*3200-1800*/
 @media(min-width: 3200px) {
     .dsb-router-wrapper .input-wrapper label {
         font-size: 22px;
     }

     .user-info .dsb-btn {
         width: 260px;

         p {
             font-size: 20px;
         }
     }

     .profile-edit {
         .dsb-btn {
             width: 220px;
         }
     }

     .avatar {
         width: 160px;
         height: 160px;
     }

     .user-info {
         padding-bottom: 60px;
     }

     .profile-edit {
         padding-top: 60px;
     }

     .profile-edit .edit-form {
         margin-top: 60px;
     }

     ///
     .card-info .card .input-group .label p {
         font-size: 22px;
         margin-bottom: 20px;
     }

     .card-info .card .card-number input,
     .card-info .card .date .date-input input,
     .card-info .card .cvv input,
     .card-info .card .card-number p {
         font-size: 26px;
     }

     .card-info .card .card-header { 
        margin-bottom: 45px;
            
        .card-img{
            width: 60px; 
        }
    }
 }

 /*3360-1890 1695*/
 @media(min-width:3360px) {}

 /* 3840 x 2160 2049 (4K) */
 @media(min-width:3840px) {
     .dsb-router-wrapper .input-wrapper label {
         font-size: 26px;
     }

     .user-info .dsb-btn {
         width: 300px;

         p {
             font-size: 24px;
         }
     }

     .profile-edit {
         .dsb-btn {
             width: 250px;
         }
     }

     .avatar {
         width: 180px;
         height: 180px;
     }

     .user-info {
         padding-bottom: 70px;
     }

     .profile-edit {
         padding-top: 70px;
     }

     .profile-edit .edit-form {
         margin-top: 70px;
     }

     ///
     .card-info .card .input-group .label p {
         font-size: 26px;
         margin-bottom: 30px;
     }

     .card-info .card .card-number input,
     .card-info .card .date .date-input input,
     .card-info .card .cvv input,
     .card-info .card .card-number p {
         font-size: 30px;
     }

     .card-info .card .card-header { 
        margin-bottom: 50px;
            
        .card-img{
            width: 65px; 
        }
    }
 }

 /* 1920x961 (current) */

 /* macbook pro 16 2021 */
 /*1728x1085*/
 @media(max-width: 1728px) {

     /// 
     .card-info .card .input-group .label p {
         font-size: 14px;
     }

     .card-info .card .card-number input,
     .card-info .card .date .date-input input,
     .card-info .card .cvv input,
     .card-info .card .card-number p {
         font-size: 18px;
     }

 }

 /*1688-1080*/
 @media(max-width: 1688px) {}

 /*1680-1080*/
 @media(max-width: 1680px) {}

 /*1640-1080*/
 @media(max-width: 1640px) {
     .avatar {
         width: 110px;
         height: 110px;
     }

     .dsb-router-wrapper .input-wrapper label {
         font-size: 14px;
     }

     .user-info .dsb-btn {
         width: 180px;

         p {
             font-size: 14px;
         }
     }

 }

 /* 1600 - 757 */
 @media (max-width: 1600px) {
     .invoice-wrapper {
         gap: 80px;
         width: 90%;
     }
 }

 /*1536-864*/
 @media(max-width:1536px) {
     .invoice-wrapper {
         gap: 80px;
         width: 95%;
     }
 }

 /* macbook pro/air */
 /* 1440-900 */
 @media(max-width:1440px) {
     .profile-edit .edit-form {
         width: 85%;
     }

     .user-info .dsb-btn {
         width: 160px;

         p {
             font-size: 12px;
         }
     }

     //
     .card-info .card .card-number input,
     .card-info .card .date .date-input input,
     .card-info .card .cvv input,
     .card-info .card .card-number p {
         font-size: 16px;
     }

     .card-info .card .card-header { 
        margin-bottom: 25px;

        .card-img{
            width: 38px; 
        }
    }
 }

 /*1366-638*/
 @media (max-width:1366px) {
     .invoice-wrapper {
         gap: 50px;
         width: 100%;
     }
 }

 /* macbook air */
 /* 1280-800 */
 @media (max-width:1280px) {
     .dsb-router-wrapper .input-wrapper label {
         font-size: 12px;
     }

     .avatar {
         width: 100px;
         height: 100px;
     }

     .profile-edit .edit-form {
         width: 90%;
     }

     .profile-edit .inputs-wrapper {
         gap: 60px;
     }

     .invoice-wrapper {
         gap: 0;
         justify-content: space-between;
     }

     .card-info .card .input-group .label p {
         font-size: 12px;
     }
 }

 /* 1200-1920 */
 @media (max-width:1200px) {
     .profile-edit .edit-form {
         width: 95%;
     }

     .profile-edit .inputs-wrapper {
         gap: 50px;
     }
 }

 /*1134x712*/
 @media(max-width:1134px) {
     .profile-edit .edit-form {
         width: 100%;
     }

     .profile-edit .inputs-wrapper {
         gap: 40px;
     }


     .card-info .card .card-header { 
        margin-bottom: 25px;

        .card-img{
            width: 35px; 
        }
    }
 }

 /* tablets */

 /* micro duo */
 /*1114x705*/
 @media(max-width:1114px) {
     .invoice-wrapper {
         flex-direction: column-reverse;
         gap: 50px;
     }

     .invoice-wrapper .card-personal-info {
         width: 60%;
         margin: 0 auto;

         .dsb-title {
             text-align: center;
         }
     }

     .invoice-wrapper .card-info {
         width: 55%;
         margin: 0 auto;

         .dsb-title,
         .dsb-msg {
             text-align: center;
         }
     }
 }

 /*1024-768*/
 /* ipad pro */
 /*1024-1366*/
 @media (max-width:1024px) {}

 /* ipad pro 2/3 */
 /* 981x1024 */
 @media (max-width:981px) {}

 /* 962x601 */
 @media(max-width: 962px) {}

 /* 900x1600 */
 @media(max-width: 900px) {
     .invoice-wrapper .card-personal-info {
         width: 70%;

     }

     .invoice-wrapper .card-info {
         width: 65%;
     }
 }

 /* galaxy fold */
 /* 884 x 1104 */
 @media(max-width: 884px) {
     .profile-edit .inputs-wrapper {
         gap: 20px;
     }
 }

 /* ipad pro 2022 */
 /*834-1136*/
 @media(max-width: 834px) {
     .user-info {
         flex-direction: column;
         align-items: flex-start;
         justify-content: initial;
         gap: 20px;

         .dsb-btn {
             margin: initial !important;
             margin-left: auto !important;
         }
     }

     .user-details {
         gap: 15px;
     }

     .avatar {
         width: 90px;
         height: 90px;
     }

     .profile-edit .inputs-wrapper {
         flex-direction: column;
         gap: 0px;
     }

     .profile-edit .inputs-column {
         width: 100%;
     }

     .profile-edit .dsb-btn {
         width: 165px;
         margin: 0 auto !important;
     }

     .input-wrapper {
         margin-bottom: 20px;
     } 
 }

 /* ipad air 4 */
 /* 820x1180 */
 @media(max-width: 820px) {
     .invoice-wrapper .card-personal-info {
         width: 75%;
     }

     .invoice-wrapper .card-info {
         width: 70%;
     }
 }

 /* ipad 2020 */
 /* 810x1080 */
 @media(max-width: 810px) {}

 /* galaxy tab 7 */
 /* 800x1280 */
 @media(max-width: 800px) {
     .invoice-wrapper .card-personal-info {
         width: 80%;
     }

     .invoice-wrapper .card-info {
         width: 75%;
     }
 }

 /*778-304*/
 @media(max-width: 778px) {}

 /*775*/
 @media(max-width: 775px) {}

 /* ipad air/mini */
 /* 768x1024 */
 @media(max-width: 768px) {
     .avatar {
         width: 80px;
         height: 80px;
     }

     .invoice-wrapper .card-personal-info {
         width: 85%;
     }

     .invoice-wrapper .card-info {
         width: 80%;
     } 

     .card-info .card .card-header { 
        margin-bottom: 20px;

        .card-img{
            width: 30px; 
        }
    }
 }

 /*712-1138*/
 @media(max-width:712px) {
     .invoice-wrapper .card-personal-info {
         width: 100%;
     }

     .invoice-wrapper .card-info {
         width: 90%;
     }
 }

 /* ipad air 2/3 */
 /* 694-768 */
 @media(max-width:694px) {

     .invoice-wrapper .card-personal-info,
     .invoice-wrapper .card-info {
         width: 70%;
     }
 }

 /* ipad pro 1/2 */
 /* 678-1024 */
 @media(max-width:678px) {}

 /* 600x962 */
 @media(max-width:600px) {

     .invoice-wrapper .card-personal-info,
     .invoice-wrapper .card-info {
         width: 75%;
     }
 }

 /* 577-951 */
 @media(max-width:577px) {}

 /* 540-960 */
 @media(max-width:540px) {
     .avatar {
         width: 70px;
         height: 70px;
     }

     .invoice-wrapper .card-personal-info,
     .invoice-wrapper .card-info {
         width: 85%;
     }
 }

 /* mobiles */

 /* s-note */
 /*480-853*/
 @media (max-width:480px) {

     .invoice-wrapper .card-personal-info,
     .invoice-wrapper .card-info {
         width: 95%;

         .dsb-title,
         .dsb-msg {
             text-align: left;
         }
     }
 }

 /* ipad air 2/3 */
 /*438-1024*/
 @media(max-width:438px) {}

 /* ip14 pro max */
 /* 430x932 */
 @media(max-width:430px) {
     .user-info {
         .dsb-btn {
             width: 140px;

             p {
                 font-size: 10px;
             }
         }
     }

     .invoice-wrapper .card-personal-info,
     .invoice-wrapper .card-info {
         width: 100%;
     }

     .card-info .card {
         padding: 30px;
     }

     .invoice-wrapper {
         gap: 40px;
     }
 }

 /* ip13 pro max */
 /* 428-926 */
 @media (max-width:428px) {}

 /* ip11 pro max */
 /* 414-896/736*/
 @media (max-width:414px) {}

 /* s21 ultra, pix6 */
 /* 412-915*/
 /* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
 @media (max-width:412px) {}

 /* ip14 pro, pix5 */
 /*393 x 852*/
 @media(max-width:393px) {}

 /* ip13 pro */
 /*390 x 844*/
 @media(max-width:390px) {}

 /* s21 plux */
 /*384-854*/
 @media(max-width:384px) {
     .card-info .card {
         padding: 25px;
     }

     .invoice-wrapper {
         gap: 30px;
     }
 }

 /* ip X */
 /*375-812/667*/
 @media(max-width:375px) {}

 /* s22 ultra */
 /*360x772*/
 @media(max-width:360px) {
     .card-info .card {
         padding: 20px 15px;
     }
 }

 /* ip SE */
 /*320x568*/
 @media(max-width:320px) {
     .card-info .card {
         padding: 15px 12px;
     }
 }
</style>
