import axios from "axios";

const axiosInstance = ({ requiresAuth = false } = {}) => {
    const options = {
        // baseURL: 'https://api-sandbox.cleandata.ch/',
        baseURL: 'https://api-sandbox.cleandata.ch/api/',
        headers: {
            "Content-type": "application/json",
            "Accept": "application/json",
        },
    };

    if (requiresAuth) {
        const token = localStorage.getItem("token");
        if (token) {
            options.headers.Authorization = `Bearer ${token}`;
        }
    }

    return axios.create(options);
};

export default axiosInstance;
 