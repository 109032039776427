<template >
    <div class="dsb-router-wrapper">
        <div class="dsb-router-header">
            <div class="dsb-title dsb-title-lg">
                <p>Wo sind Ihre Daten?</p>
            </div> 
        </div>
        <Loader v-if="loading"/>
        <div class="notifications-wrapper" v-else>
            <div class="notifications-list-wrapper">
                <div class="header">
                    <div class="counter">
                        <p>Aktiv 1</p>
                    </div>

                    <div class="collapse-btn">
                        cll
                    </div>
                </div>

                <div class="notifications-list" v-for="noti in notifications" :key="noti.id">
                    <div class="notification notification-active">
                        <div class="company-img">
                            <img :src="require(`@/assets/icons/${noti.img }`)"/>
                        </div>
                        <div class="about">
                            <div class="company">
                                <p>{{ noti.company }}</p>
                            </div>
                            <div class="recieved-date">
                                <p>{{ noti.dateRecieved }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="noticications-details">
                <div class="header">
                    <div class="about">
                        <div class="company dsb-title dsb-title-sm">
                            <p>Freepik</p>
                        </div>
                        <div class="status">
                            <p>Active - Request sent Nov 21,2024</p>
                        </div>
                    </div>

                    <div class="option-btn">
                        ...
                    </div>
                </div>

                <div class="details">
                    <p>In Ihrer E-Mail fordern Sie uns in Ihrer Funktion als Geschäftsführer der Adits GmbH auf, der Adits GmbH „alle Klienten betreffenden“ Personendaten auf Grundlage von Art. 25 DSG zu beauskunften. Wir weisen darauf hin, dass der Auskunftsanspruch nach Art. 25 DSG ein höchstpersönliches, subjektives Recht ist, das ausschliesslich jeder individuell „betroffenen Person“ im Sinne von Art. 5 lit. c DSG zusteht. Einer juristischen Person steht ein entsprechender Anspruch nicht zu. Wer einer Ihrer „Klienten“ sein könnte, entzieht sich ohnehin unserer Kenntnis. Wir sehen daher mangels Rechtsgrundlage keine Veranlassung, der Adits GmbH Auskunft zu erteilen. Zusätzlich erfassen wir keine Daten über Benutzer, mit Ausnahme derjenigen, die sich entscheiden, sich auf unserer Website zu registrieren. Wir haben eine kurze Überprüfung durchgeführt und können keinen registrierten Benutzer unter Ihrem Namen, Email-Adresse, oder Ihrer Unternehmensdomain finden. Ich hoffe, das hilft. Mit freundlichen Grüßen,</p>
                </div>

                <div class="action-btns">
                    <button class="dsb-btn dsb-btn-sm "><p>Do not delete</p></button>
                    <button class="dsb-btn dsb-btn-sm dsb-btn-light"><p>Apply Delete</p></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/components/Loader.vue';
export default {
    components:{
        Loader
    },
    data(){
        return{
            loading: false,
            notifications:[
                {
                    id: 1,
                    img: 'connection_default_icon.svg',
                    company: 'Freepik',
                    dateRecieved: 'Today',
                    status: 'Active ',
                    dateSent: 'Nov 21,2024',
                    details:'In Ihrer E-Mail fordern Sie uns in Ihrer Funktion als Geschäftsführer der Adits GmbH auf, der Adits GmbH „alle Klienten betreffenden“ Personendaten auf Grundlage von Art. 25 DSG zu beauskunften. Wir weisen darauf hin, dass der Auskunftsanspruch nach Art. 25 DSG ein höchstpersönliches, subjektives Recht ist, das ausschliesslich jeder individuell „betroffenen Person“ im Sinne von Art. 5 lit. c DSG zusteht. Einer juristischen Person steht ein entsprechender Anspruch nicht zu. Wer einer Ihrer „Klienten“ sein könnte, entzieht sich ohnehin unserer Kenntnis. Wir sehen daher mangels Rechtsgrundlage keine Veranlassung, der Adits GmbH Auskunft zu erteilen. Zusätzlich erfassen wir keine Daten über Benutzer, mit Ausnahme derjenigen, die sich entscheiden, sich auf unserer Website zu registrieren. Wir haben eine kurze Überprüfung durchgeführt und können keinen registrierten Benutzer unter Ihrem Namen, Email-Adresse, oder Ihrer Unternehmensdomain finden. Ich hoffe, das hilft. Mit freundlichen Grüßen,'
                }, 
            ]
        }
    },
    async mounted(){
        if(!this.isUserVerified){
            this.loading = true;
            await this.checkUserIdentity();
            this.loading = false;
        }else{
            this.loading = false;
            return
        }
    }
}
</script>
<style scoped lang="scss"> 
    @import "../../assets/dashboard.scss";  


    .notifications-wrapper{
        box-shadow: 4px 6px 14px #56597A4D;
        border-radius: 20px;
        display: flex;
        width: 100%;
    }

    .notifications-list-wrapper{
        padding: 36px 20px;
        border: 1px solid transparent;
        border-right-color: #7070704d;

        width: 30%;

        .header{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 23px;

            .counter{
                p{
                    font-size: 18px;
                    color: #000000;
                }
            }
        } 

        
        .notifications-list{
            width: 100%;
            .notification{
                width: 100%;
                border: 1px solid #3d3d3d26;
                border-radius: 20px;
                padding: 12px;
                // margin-bottom: 20px;
                cursor: pointer;
                transition: 0.3s ease;

                display: flex;
                align-items: center;
                gap: 15px;

                &-active,
                &:hover{
                    background-color: #3d3d3d26  ;
                    border: 1px solid transparent;
                }

                .company-img{
                    width: 44px;
                    height: 44px;
                    img{
                        width: 100%;
                    }
                }

                .about{
                    .company{
                        margin-bottom: 3px;
                        p{
                            font-size: 18px;
                            font-weight: 500;
                            color: #000000;
                        }
                    }

                    .recieved-date{
                        p{
                            font-size: 14px;
                            color: #666666;
                        }
                    }
                }
            }
        }

    } 

    .noticications-details{
        width: 70%;
        .header{
            padding: 30px 35px ;
            border: 1px solid transparent;
            border-bottom-color: #7070704d;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .about{
                .company{
                    margin-bottom: 3px;
                }

                .status{
                    p{
                        font-size: 14px;
                        color: #666666;   
                    }
                }
            }
        }

        .details{
            padding:  15px 35px;
            padding-bottom: 80px;
            border: 1px solid transparent;
            border-bottom-color: #7070704d;

            p{
                font-size: 15px;
                color: #666666;
            }
        }
    }

    .action-btns{ 
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        align-items: center;
        padding: 26px 36px;
        padding-top: 80px;

        .dsb-btn{
            margin: initial;

            p{
                font-size: 16px;
            }
        }
    }


    /* imac 24" */
/* 2048 x 1152 */
@media (min-width: 2048px) {
}

/* 2304-1470 */
@media (min-width: 2304px) {
}

/* 2470 - 1328 */
@media (min-width: 2470px) {
    .notifications-list-wrapper {
        .header .counter p {
            font-size: 20px; 
        }
    
        .notifications-list .notification{
            .company-img {
                width: 50px;
                height: 50px;
            }
            .about{
                .company p {
                    font-size: 20px; 
                }
                
                .recieved-date p {
                font-size: 16px; 
                }
            }
        } 
    }

 
    .noticications-details{ 
        .header .about .status p {
            font-size: 16px; 
        }

        .details p {
            font-size: 16px; 
        }
    }


    .action-btns .dsb-btn p {
        font-size: 18px;
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
}

/*2573-1206*/
@media(min-width: 2573px) {
}

/* 2880-1670 */
@media(min-width: 2880px) {
    .notifications-list-wrapper {
        .header .counter p {
            font-size: 22px; 
        }
    
        .notifications-list .notification{
            .company-img {
                width: 55px;
                height: 55px;
            }
            .about{
                .company p {
                    font-size: 22px; 
                }
                
                .recieved-date p {
                    font-size: 18px; 
                }
            }
        } 
    }

 
    .noticications-details{ 
        .header .about .status p {
            font-size: 18px; 
        }

        .details p {
            font-size: 18px; 
        }
    }

    
    .action-btns .dsb-btn p {
        font-size: 20px;
    }
}

/* 3000-1870 */
@media(min-width: 3000px) {
}

/*3200-1800*/
@media(min-width: 3200px) {
    .notifications-list-wrapper {
        .header .counter p {
            font-size: 24px; 
        }
    
        .notifications-list .notification{
            .company-img {
                width: 60px;
                height: 60px;
            }
            .about{
                .company p {
                    font-size: 24px; 
                }
                
                .recieved-date p {
                    font-size: 20px; 
                }
            }
        } 
    }

 
    .noticications-details{ 
        .header .about .status p {
            font-size: 20px; 
        }

        .details p {
            font-size: 20px; 
        }
    }

    
    .action-btns .dsb-btn p {
        font-size: 22px;
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .notifications-list-wrapper {
        .header .counter p {
            font-size: 28px; 
        }
    
        .notifications-list .notification{
            .company-img {
                width: 70px;
                height: 70px;
            }
            .about{
                .company p {
                    font-size: 28px; 
                }
                
                .recieved-date p {
                    font-size: 24px; 
                }
            }
        } 
    }

 
    .noticications-details{ 
        .header .about .status p {
            font-size: 24px; 
        }

        .details p {
            font-size: 24px; 
        }
    }

    
    .action-btns .dsb-btn p {
        font-size: 26px;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
}

/*1688-1080*/
@media(max-width: 1688px) {
}

/*1680-1080*/
@media(max-width: 1680px) {
    .notifications-list-wrapper {
        .header .counter p {
            font-size: 14px; 
        }
    
        .notifications-list .notification{
            .company-img {
                width: 40px;
                height: 40px;
            }
            .about{
                .company p {
                    font-size: 16px; 
                }
                
                .recieved-date p {
                    font-size: 12px; 
                }
            }
        } 
    }

 
    .noticications-details{ 
        .header .about .status p {
            font-size: 14px; 
        }

        .details p {
            font-size: 14px; 
        }
    }


    .action-btns .dsb-btn{
        width: 140px;
        p {
            font-size: 14px;
        }
    }
}

/*1640-1080*/
@media(max-width: 1640px) {
}

/* 1600 - 757 */
@media (max-width: 1600px) {
}

/*1536-864*/
@media(max-width:1536px) {
}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {
    
    .noticications-details{ 
        .header .about .status p {
            font-size: 12px; 
        }

        .details p {
            font-size: 12px; 
        }
    }
 
}

/*1366-638*/
@media (max-width:1366px) {
    .notifications-list-wrapper {
        padding: 30px 15px;
    }

    .noticications-details .header {
        padding: 25px;
    }

    .noticications-details .details {
        padding: 15px 25px;
        padding-bottom: 80px;
    }

    .action-btns { 
        gap: 25px; 
        padding: 25px;
        padding-top: 80px;
    }
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .notifications-list-wrapper { 
        .notifications-list .notification{
            .company-img {
                width: 35px;
                height: 35px;
            }
            .about{
                .company p {
                    font-size: 14px; 
                } 
            }
        } 
    } 

    .action-btns .dsb-btn{
        width: 130px; 
    }
}

/* 1200-1920 */
@media (max-width:1200px) {
    .notifications-list-wrapper {
        padding: 25px 10px;
    }

    .noticications-details .header {
        padding: 15px;
    }

    .noticications-details .details {
        padding: 15px ;
        padding-bottom: 80px;
    }

    .action-btns {  
        padding: 15px;
        padding-top: 80px;
    } 
}

/*1134x712*/
@media(max-width:1134px) {
}


/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {
    .notifications-list-wrapper {
        padding: 25px 10px;
    }

    .noticications-details .header {
        padding: 15px;
    }

    .noticications-details .details {
        padding: 15px ;
        padding-bottom: 60px;
    }

    .action-btns {  
        padding: 15px;
        padding-top: 60px;
    }

    .notifications-list-wrapper .notifications-list .notification {
       padding: 8px;
    }
    

        .notifications-list-wrapper {
        .header .counter p {
            font-size: 12px; 
        }
    
        .notifications-list .notification{
            .company-img {
                width: 32px;
                height: 32px;
            }
            .about{
                .company p {
                    font-size: 12px; 
                }
                
                .recieved-date p {
                    font-size: 10px; 
                }
            }
        } 
    }

 
    .noticications-details{ 
        .header .about .status p {
            font-size: 11px; 
        }

        .details p {
            font-size: 11px; 
        }
    }


    .action-btns .dsb-btn{
        width: 120px;
        p {
            font-size: 12px;
        }
    }
}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
}

/* 962x601 */
@media(max-width: 962px) {
}

/* 900x1600 */
@media(max-width: 900px) {
}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) {
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
    .notifications-wrapper{
        flex-direction: column;
    }

    .notifications-list-wrapper,
    .noticications-details{
        width: 100%;
    }

    .notifications-list-wrapper{
        border-right-color: transparent;
        border-bottom-color: #7070704d;
    }


    .notifications-list-wrapper {
        padding:30px 20px;
    }

    .noticications-details .header {
        padding: 20px;
    }

    .noticications-details .details {
        padding: 15px 20px ;
        padding-bottom: 60px;
    }

    .action-btns {  
        padding: 20px;
        padding-top: 60px;
    }

    .notifications-list-wrapper .notifications-list .notification {
       padding: 10px;
    }
    

    .notifications-list-wrapper {
        .header .counter p {
            font-size: 14px; 
        }
    
        .notifications-list .notification{
            .company-img {
                width: 38px;
                height: 38px;
            }
            .about{
                .company p {
                    font-size: 14px; 
                }
                
                .recieved-date p {
                    font-size: 12px; 
                }
            }
        } 
    }

 
    .noticications-details{ 
        .header .about .status p {
            font-size: 12px; 
        }

        .details p {
            font-size: 11px; 
        }
    }


    .action-btns .dsb-btn{
        width: 120px;
        p {
            font-size: 12px;
        }
    }
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {
}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {
}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {
}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
}

/*712-1138*/
@media(max-width:712px) {
}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) {
}

/* 600x962 */
@media(max-width:600px) {
}

/* 577-951 */
@media(max-width:577px) {    
}

/* 540-960 */
@media(max-width:540px) {
}


/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
    .action-btns{
        padding-top: 40px;
        gap: 20px;
        justify-content: center;
    }

    .noticications-details .details { 
        padding-bottom: 40px;
    }
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px){
}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px){
}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) { 
}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {
}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
}
</style>