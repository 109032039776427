import axiosInstance from "@/axios"; 
 
 

export const state = {
    ticketsData: {},
    ticketsError: null,
}

export const mutations = {
    setTicketsData(state, data) {
        state.ticketsData = data;
    },
    setTicketsError(state, msg){
        state.ticketsError = msg
    },
}

export const getters = { 
    ticketsData: state => state.ticketsData,
    ticketsError: state => state.ticketsError,
}

export const actions = {
 
    async getTicketsData({ commit } ) {    
        try { 
            const result = await axiosInstance({ requiresAuth: true }).get("/customer/tickets" );  
 
            commit('setTicketsData', result); 
            commit("setTicketsError", null);
        } catch (error) {  
            commit("setTicketsError", error.response.data.message);
            throw error; 
        }
    },  
}
 


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  };
